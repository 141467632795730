.jt-walk-box {
  .jt-walk-heading {
    text-align: center;
    padding: 10px 5px;

    h5 {
      line-height: 0.4;
    }
  }

  .jt-walk-list {
    width: 100%;
    clear: both;

    ul {
      padding: 0;
      li {
        list-style: none;
      }
    }

    .jt-walk-list-item {
      width: 100%;
      padding: 5px;
      clear: both;
      display: table;

      .jt-walk-list-image {
        width: 15%;
        float: left;
        display: table-cell;
        vertical-align: middle;
        img {
          width: 100%;
          height: auto;
          max-width: 60px;
        }
      }
      .jt-walk-list-text {
        width: 85%;
        float: left;
        padding: 10px;
        display: table-cell;
        vertical-align: middle;

        .jt-walk-list-heading {
          float: left;
          width: 90%;
          clear: both;
        }
        .jt-walk-list-para {
          width: 100%;
          float: left;
          clear: both;
        }
      }
    }
  }
  .jt-walk-action {
    margin-bottom: 20px;
    text-align: center;
    button {
      height: 32px;
      padding: 0 15px;
      @include font-size(13);
    }
  }
}

//Web theme
.jt-web-walk-box {
  @extend .jt-walk-box;
  padding-top: 64px;
  padding-bottom: 30px;
}