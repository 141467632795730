//Fonts
@import url("https://use.typekit.net/jml4rgr.css");

// Font Variables
$h1-large: "anton",sans-serif;
$regular: "proxima-nova", sans-serif;
//Colour Variables
$bright-pink: #fb447c;
$medium-pink: #ff9fb3;
$light-pink:#ffdfe3;

.jt-web-checkout-container-section {
  .scrollable-content {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .jt-page-section-container {
      //min-height: 95vh;
      min-height: calc(100vh - 129px);
      margin-top: 30px;
    }

    .alert {
      //min-height: 95vh;
    }

    .jt-grouping-list {

      form {
        max-width: 992px;
        margin: 0 auto;
      }

      .jt-grouping-list-inner {
        padding-bottom: 15px;

        .jt-grouping-list-row {
          display: block;
          border-bottom: none !important;
          padding: 10px 15px 0 15px;
          cursor: default;
          //background: red;

          &:last-child {
            padding-bottom: 0;
          }

          .jt-grouping-list-row-texting {
            width: 100%;
            clear: both;
            display: block;
            //float: left;

            h3 {
              @include font-size(17);
              font-family: $regular;
            }
          }

          .jt-grouping-list-inner-half {
            width: auto;
            clear: both;
            display: block;
            margin: 0 -10px;

            .jt-grouping-list-inner-half-width {
              width: 50%;
              display: inline-block;
              padding: 0 10px;
              float: left;

              h3 {
                @include font-size(14);
                margin: 0;
                padding: 4px 0 5px 0;
                font-weight: bold;
                font-family: $regular;
              }
            }
          }

          .jt-grouping-list-row-fields, .jt-grouping-list-inner-half {
            span.error-message {
              width: 100%;
              height: auto;
              color: red;
              line-height: 1;
              //@include text-truncate;
            }
          }
        }
        
        .buttons-row {
          text-align: center;
          width: auto;
          margin: 0 -2.5px;

          .button-wrapper {
            width: 49.5%;
            max-width: 280px;
            margin: 0 0;
            padding: 0 2.5px;
            float: none;
            display: inline-block;
          }
          
          button {
            width: 100%;
          }
        }
        .edit-row {
          border-bottom: 1px solid #dedede!important;
          padding-bottom: 10px;
        }

        .jt-check-order-table {
          width: 100%;
          overflow-x: auto;

          table {
            width: 100%;
            border: 1px solid #e2e2e2;
            //min-width: 500px;
            margin-bottom: 15px;
            font-family: $regular;

            td {
              @include font-size(15);
              font-family: $regular;

            }

            tr {
              font-family: $regular;

              td, th {
                padding: 10px 8px;
                font-family: $regular;

              }

              th {
                background: #f3f3f3;
                @include font-size(16);
                font-family: $regular;
              }

              border-bottom: 1px solid #f3f3f3;

              &:last-child {
                border-bottom: none;
              }
            }

            .jt-order-tb-type {
              width: 55%;
            }
            .jt-order-tb-price {
              width: 15%;

            }
            .jt-order-tb-qty {
              width: 15%;

            }
            .jt-order-tb-subtot {
              width: 15%;

            }
          }
          .jt-gst-condition {
            font-size: smaller;
            font-style: italic;
            color: #808080;
          }
        }
      }
    }
  }
}

.checkout-heading, .jt-page-section-container .jt-page-section-title, .jt-page-section-container .jt-page-section-title  {
  margin-top: 70px;
}

.jt-checkout-page-success-section {
  .success-msg {
    width: 100%;
    padding-top: 130px;
    position: relative;

    .success-icon {
      position: absolute;
      width: 100%;
      height: 42px;
      left: 0;
      top: 30px;
      text-align: center;

      .fa {
        @include font-size(26);
        width: 62px;
        height: 62px;
        background: green;
        border-radius: 100%;
        line-height: 62px;
        color: white;
      }
    }
  }


  h2 {
    width: 100%;
    position: relative;
    text-align: center;
    font-weight: 400;
    //text-transform: uppercase;
    letter-spacing: -1px;
    @include font-size(20);
    line-height: 1.3;
  }
}

.jt-checkout-page-error-section {
  .success-msg {
    width: 100%;
    padding-top: 100px;
    position: relative;

    .success-icon {
      position: absolute;
      width: 100%;
      height: 42px;
      left: 0;
      top: 0;
      text-align: center;

      .fa {
        @include font-size(26);
        width: 62px;
        height: 62px;
        background: #c21f23;
        border-radius: 100%;
        line-height: 62px;
        color: white;
      }
    }
  }


  h2 {
    width: 100%;
    position: relative;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: -1px;
    @include font-size(19);
  }
}

.jt-enter-coupon-bt {
  padding: 0 15px;
  text-align: center;

  button {
    background: none;
    @include css3-prefix('appearance','none');
    @include font-size(15);
    color: $bright-pink;
    text-decoration: underline;
    letter-spacing: -1px;
    min-height: 0;
    line-height: 1;
    padding: 0 15px 5px 15px;
    height: auto;
  }
}

.jt-discount-login {
  width: 100%;
  display: inline-block;
  float: none;
  margin: 15px auto 0 auto;
  padding: 15px 50px;
  text-align: center;
  background: $white;
  //border-radius: 4px;
  border: 1px solid #dadada;

  label {
    width: 100%;
    display: block;
    clear: both;
    @include font-size(15);
    color: #656565;
    text-align: center;
    letter-spacing: -1px;
    font-family: $regular;

    span {
      @include font-size(36);
      font-weight: 400;
      letter-spacing: -3px;
      line-height: 1;
    }
  }

  div {
    width: 100%;
    display: block;
    clear: both;
    text-align: center;

    a, span {
      display: inline-block;
      width: auto;
    }

    a {
      padding: 5px 10px;
      text-decoration: none;
      @include font-size(15);
      font-family: $regular;
      color: $bright-pink;

      //color: #0a0a0a
    }
  }

  //button.jt-discount-bt {
  //  background: $dark-gray;
  //  border: 1px solid darken($dark-gray,5%);
  //  color: $white;
  //  font-weight: bold;
  //  @include font-size(14);
  //}
}

.jt-grouping-list .jt-grouping-list-inner {
  background: white !important;
}

.to-show-hide {
  .for-large {
    display: inline-block !important;
  }
  .for-small {
    display: none !important;
  }
}

.jt-checkout-success-controls {
  padding-top: 25px;

  button {
    margin: 5px 2.5px;
  }
}

.jt-event-session-select-btn {
  padding: 0 10px;
  font-size: 1.2rem;
  min-height: 30px;
}

.jt-session-btn {
  background: #e9e9e9;
  color: #000;
  border: none !important;
}

.jt-session-btn:hover {
  background-color: #d9dfe2;
  color: #000;
}

.jt-session-seats-remain {
  font-size: 1.0rem;
}

.jt-checkout-paypal {
  height: 100px;
  //position: relative;

  .jt-checkout-paypal-btn {
    margin: 0;
    //position: absolute;
    top: 50%;
    left: 50%;
    //transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
    padding: 5%;
  }
}

.jt-menulist-dropdown {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  -ms-progress-appearance: inherit;
}

.jt-number-no-spin-box::-webkit-inner-spin-button, .jt-number-no-spin-box::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.m-0 {
  margin: 0px !important;
}
