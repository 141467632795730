.jt-profile-container {
  position: relative;
  .jt-extended-menu {
    position: absolute;
    z-index: 200;
    right: 13px;
    top: 9px;
  }
  .jt-extended-menu-list {
    position: absolute;
    left: -142px;
    top: 28px;
    z-index:200;
  }
  .btn-group {
    padding: 0 -1px 1px 1px;
  }
  .jt-detail-container {
    position: relative;
    min-height: 110px;
    border-radius: 0;
  }
  .jt-desc {
    padding-left: 80px;
    width: 100%;
    h2 {
      word-break: normal;
      font-size: 22px;
    }
    p {
      margin-bottom: 3px;
      strong {
        width: 140px;
        float: left;
      }
      span {
        color: $gray;
        display: block;
        float: left;
        padding-right: 8px;
        padding-left: 18px;
        position: relative;
        word-break: break-word;
      }
      float: left;
      clear: both;
    }
    .jt-brand-tagline {
      float: left;

    }

    .message-link {
      a{
        color: $black;
      }

      i{
        color: $gray;
      }
    }
  }
  .well {
    margin-bottom: 0;
    padding: 12px 80px 12px 10px;
    border: none;
  }
  .jt-btn-primary {
    border: 1px solid #fff;
  }
  .jt-follow-title {
    font-weight: bold;
    display: block;
    color: $black !important;
  }
  .user-company:before {
    font-family: FontAwesome;
    content: "\f1ad";
    color: $gray;
    padding-right: 4px;
    width: 17px;
    position: absolute;
    height: 10px;
    display: block;
    left: 0;
  }
  .user-job:before {
    font-family: FontAwesome;
    content: "\f0b1 ";
    color: $gray;
    padding-right: 4px;
    width: 17px;
    position: absolute;
    height: 10px;
    display: block;
    left: 0;
  }
  .user-address:before {
    font-family: FontAwesome;
    content: "\f041 ";
    color: $gray;
    padding-right: 4px;
    width: 17px;
    position: absolute;
    height: 10px;
    display: block;
    left: 0;
  }
  .jt-follow-profile {
    position: absolute;
    right: 6px;
    z-index: 100;
    top: 50px;
  }

  .jt-extended-menu {
    top: 9px;
  }

  .jt-post-view-more {
    display: none !important;
  }

  .jt-profile-details {
    padding: 12px;

    .text-shrink {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .jt-profile-details-border {
      border-top: 1px solid $ash;
    }

    .jt-member-dp {
      width: 75px;
      margin: 0 auto;
      vertical-align: middle;
      display: inline-block;
    }

    .flourish {
      display: block;
      overflow: hidden;
      text-align: center;
      position: inherit;
      left: 0;
      margin-top: 10px;
    }

    .flourish:before, .flourish:after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      position: relative;
      width: 50%;
      border-top-style: solid;
      border-top-width: 1px;
      color: $ash;
    }

    .flourish:before {
      right: 0.5em;
      margin-left: -50%;
    }

    .flourish:after {
      left: 0.5em;
      margin-right: -50%;
    }

    .member-details {
      margin-top: 5px;
    }

    .member-about {
      margin: 15px 0;
    }

    .articles-container {
      margin: 10px 0;

      .single-article {
        padding: 3px;

        .article-border {
          border: 1px solid $ash;
          @include border-radius(2px);
          box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.15);

          a {
            color: $black;
          }

          .article-details {
            padding: 5px;

            .article-title {
              font-size: 1.3rem;
              font-weight: bold;
              padding-bottom: 10px;
            }

            .article-info {
              font-size: 1.2rem;
              padding-bottom: 5px;
            }
          }
        }

        .article-border:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }

      .single-friend {
        padding: 3px;
        width: 25%;
        float: left;

        .jt-friend-wrap {
          display: block;
          position: relative;
          padding: 5px;
          text-align: center;
          background-color: $pure-white;
          height: 255px;
          border: 1px solid $ash;
          @include border-radius(2px);
          box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.15);
          overflow: hidden;

          .user-profile-wrap {
            display: block;
            position: relative;
            margin-bottom: 10px;

            img {
              max-width: 80px;
              border-radius: 100%;
            }
          }

          .user-connect-button-wrap {
            .user-inv-button-row {
              display: inline-block;
              bottom: 10px;
              position: absolute;
              left: 0;
              right: 0;
            }
          }
        }

        .jt-friend-wrap:hover {
          border: 1px solid $dark-ash;
        }

        .jt-friend-wrap-auto-height {
          height: auto !important;
        }
      }
    }
  }
}

.connect-accept-btn {
  background-color: $bright-jc-blue;
  color: $gray-lighter;
}

.connect-accept-btn:hover {
  background-color: $secondary-blue;
}

.jt-round-thumb {
  position: absolute;
  left: 6px;
  img {
    border-radius: 150px;
    border: 3px solid $blue-lighter;
    width: 70px;
    height: 70px;
    max-width: inherit;
  }
  p {
    text-align: center;
    margin-top: 5px;
  }

}

.jt-follower {
  text-align: center;
  padding: 5px 0 0 0;
  font-weight: bold;
  font-size: 16px;
  background: $blue-light;
  color: #fff;
  margin: 1px 0;
}

.jt-follower-name {
  background: transparent;
  color: #000;
}

.jt-brands-follow {
  @extend .jt-event-going;
  padding-bottom: 0;
  margin-top: 10px;
}

.jt-follow-add {
  position: absolute;
  right: 6px;
  font-size: 44px;
  top: 38px;
  color: #0086ED;
}

.jt-follow-ok {
  @extend .jt-follow-add;
  color: #76FACA;
}

.jito-custom-password-field-margin {
  margin-bottom: 8px !important;
}

.jt-report-user-modal {
  .jt-album-create-modal {
    padding: 10px;
    button,
    input,
    textarea {
      border-radius: 0 !important;
    }
    button {
      margin-bottom: 3px;
    }
  }
}

.jt-timeline-padding-top {
  //padding-top: 20px;
  background-color: $gray-lighter;
}

.jt-profile-padding-down {
  padding-bottom: 10px;
}

.jt-profile-gray-background {
  width: 100%;
  position: absolute;
  background-color: $gray-lighter;
}

.jt-profile-timeline-view {
  clear: both;
  background-color: $white;
  .jt-time-line-container {
    background-color: $white;
  }

  .jt-post-view-more {
    display: none !important;
  }
}

.jt-profile-post-btn {
  padding-top: 10px;

  button {
    border: 1px solid white;
    height: 44px;
  }
}

.jt-profile-temp {
  font-size: 2.6rem!important;
  color: #787878;
  font-weight: 500!important;
}

.jt-profile-edit-btn{
  background-color: #176ce0 !important;
  max-width: 35px !important;
  min-height: 2px !important;
  height: 20px !important;
}

@media all and (max-width: 600px) {
  .jt-profile-container {
    .jt-profile-details {
      .articles-container {
        .single-friend {
          width: 50%;
        }
      }
    }
  }
}