.jt-alert-notification {
  @include font-size(13);
  border-radius: 0;
  margin: 15px;
  clear: both;
}

.jt-alert-danger-no-margin {
  margin-bottom: 20px !important;
}

.jt-alert-clear-both {
  clear: both;
}

.jt-home-notification-count {
  position: absolute;
  right: 2px;
  top: 3px;

  .jt-home-notification-count-inner {
    display: inline-block;
    font-size: 12px;
    font-weight: bolder;
    line-height: 1.3;
    min-height: 13px;
    padding: 0px 4px;
    border-radius: 3px;
    background: $green; /* Old browsers */
    color: $white;
    text-decoration: none;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .4);
  }

  .jt-home-notification-count-inner-nav {
    display: inline-block;
    padding: 1px 3px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: $following; /* Old browsers */
    color: $white;
    text-decoration: none;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .4);
  }
}

.jt-highlight {
  color: #ff0000;
}