.jt-calendar-box {
  padding: 10px;
  .jt-about-section {
    border: 1px solid $light-gray;
    padding: 12px 0;
    background-color: $white;
    margin-bottom: 12px;
    padding-bottom: 0;
    .jt-about-title {
      width: 100%;
      border-bottom: 1px solid $light-gray;
      padding-left: 12px;
      span {
        font-size: 2.5rem;
      }
    }
    .jt-about-content {
      padding-left: 0 12px;
      color: $gray;
      a {
        text-decoration: none;
        color: $blue;
        font-size: 1.5rem;
        display: block;
        padding-right: 8px;
        padding-left: 35px;
        position: relative;
      }
      a:before {
        font-family: FontAwesome;
        color: $blue;
        padding-right: 4px;
        width: 17px;
        position: absolute;
        height: 10px;
        display: block;
        left: 0;
        padding-left: 13px;
      }
      .telephone:before {
        content: "\f095";
      }
      .email:before {
        content: "\f0e0";
      }
      .website:before {
        content: "\f0ac";
      }
      .address:before {
        content: "\f041";
      }
      i {
        padding-right: 5px;
        width: 20px;
      }
      p {
        padding: 0 12px;
      }
      ul {
        list-style: none;
        padding-left: 0px;
        li {
          .jt-calendar-event {
            width: 100%;
            height: 100%;
            padding: 50px 0;
            margin: 0;
            position: relative;
            cursor: pointer;
            h6 {
              position: absolute;
              color: white;
              left: 10px;
              bottom: 0;
              z-index: 200;
              text-shadow: 1px 1px 1px $black;
              font-size: 1.7rem;
            }
            .jt-calendar-overlay {
              position: absolute;
              top: 0;
              right: 0;
              opacity: 0.5;
              background-color: $black;
              z-index: 100;
              width: 100%;
              height: 100%;
              padding: 50px 0;

            }

            .jt-going {
              background-color: $blue-extra-dark;
            }
          }

          .jt-calendar-status {
            background-color: #0086ED;
            z-index: 150;
            height: 34px;
            padding: 8px 0px;
            color: #FFF;
            text-align: center;
            font-weight: bold;
          }

        }
        li.jt-more-events {
          padding-left: 12px;
          padding-top: 12px;
          padding-bottom: 0;
          color: $dark-gray;
          font-size: 1.5rem;
          font-weight: bold;
        }
      }
      hr {
        margin: 0;
      }
      span {
        font-weight: bold;
      }
    }
  }
}