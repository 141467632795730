.jt-signup-modal {

  .modal-dialog {
    max-width: 400px;

    .jt-modal-inner {
      width: 100%;
      display: inline-block;  
      
      ul {
        padding: 0;
        list-style-type: none;
        clear: both;
        width: 100%;
        position: relative;
        margin: 0 auto;

        li {
          width: auto;
          @include MarPadNone;
          float: left;
          width: 45%;
          max-width: 150px;

          a {
            padding: 12px 15px;
            display: block;
          }

          &:last-child {
            float: right;
          }
        }
      }
    }
  }
}

.jt-signup-modal {
  select::-ms-expand {
    display: none;
  }
}