.jt-interest-view-container {
  position: relative;

  .jt-alert-container {
    position: absolute;
    top: 100px;
    width: 100%;

  }
  .jt-profile-container {
    .jt-interest-detail-container {
      .icon {
        top: 19px;
      }
      .jt-interest-desc {
        margin-top: 11px;
        width: 80%;
      }
    }

  }
  .jt-event-list {
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
    word-wrap: break-word;
    margin-top: 0px;
    padding-top: 115px;
  }
  a {
    text-decoration: none;
  }
  address {
    text-decoration: none;
  }
  .jt-event {
    width: 100%;
    border: 1px solid $light-gray;
    margin-bottom: 14px;
    float: left;
    position: relative;
    .jt-event-loading {
      height: 100%;
      width: 100%;
      background-color: $black;
      position: absolute;
      z-index: 2;
      opacity: 0.8;
      color: $white !important;
    }
    h5 {
      color: $black;
      margin-bottom: 5px;
    }
    p {
      color: $gray;
      margin-bottom: 5px;
    }
    .jt-event-list-image {
      width: 100%;
      height: auto;
      img {
        width: 100%;
        height: auto;
        padding-bottom: 10px;
      }
    }
    .jt-event-list-header {
      padding-bottom: 3px !important;
      line-height: 1;
      p,
      h5 {
        padding: 0px 10px;
      }
      padding-bottom: 50px;
      margin-bottom: -3px;
      .info-para {
        line-height: 1.3;
      }
    }
    .jt-event-list-details {
      p {
        padding: 0px 10px;
        margin-top: -10px;
      }
    }
    .jt-eventlist-attend-selector {
      width: 100%;
      float: left;
      position: relative;
      z-index: 1;
      margin-bottom: -8px;
      button {
        width: 50%;
        float: left;
        background-color: $blue;
        height: 38px;
        border-radius: 0;
        margin-bottom: 8px;
        color: $white;
        padding: 8px 7px !important;
        font-family: $gt-font-QS;
        border: 1px solid $blue;
      }
      button.jt-secondary-btn-color {
        background-color: $blue-extra-dark;
        border: 1px solid $blue-extra-dark;
      }
    }
  }

}




