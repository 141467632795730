input.needsclick {
  border: 1px solid #ccc;
}

.panel-white {

  .panel-heading {
    color: #333;
    background-color: #fff;
    border-color: #ddd;
  }
  .panel-footer {
    background-color: #fff;
    border-color: #ddd;
  }
}

.post {

  .post-heading {
    height: 95px;
    padding: 20px 15px;

    .avatar {
      width: 60px;
      height: 60px;
      display: block;
      margin-right: 15px;
    }
    .meta .title {
      margin-bottom: 0;
    }
    .meta .title a {
      color: black;
    }
    .meta .title a:hover {
      color: #aaaaaa;
    }
    .meta .time {
      margin-top: 8px;
      color: #999;
    }
  }

  .post-image .image {
    width: 100%;
    height: auto;
  }

  .post-description {
    padding: 15px;

    p {
      font-size: 14px;
    }
    .stats {
      margin-top: 20px;
    }
    .stats .stat-item {
      display: inline-block;
      margin-right: 15px;
    }
    .stats .stat-item .icon {
      margin-right: 8px;
    }
  }

  .post-footer {
    padding: 0px;

    .input-group-addon a {
      color: #454545;
    }
    .comments-list {
      padding: 0;
      margin-top: 20px;
      list-style-type: none;
    }
    .comments-list .comment {
      display: block;
      width: 100%;
      margin: 20px 0;
    }
    .comments-list .comment .avatar {
      width: 35px;
      height: 35px;
    }
    .comments-list .comment .comment-heading {
      display: block;
      width: 100%;
    }
    .comments-list .comment .comment-heading .user {
      font-size: 14px;
      font-weight: bold;
      display: inline;
      margin-top: 0;
      margin-right: 10px;
    }
    .comments-list .comment .comment-heading .time {
      font-size: 12px;
      color: #aaa;
      margin-top: 0;
      display: inline;
    }
    .comments-list .comment .comment-body {
      margin-left: 50px;
    }
    .comments-list .comment > .comments-list {
      margin-left: 50px;
    }
  }
}

.panel-shadow {
  border: 0;
  box-shadow: none;
}