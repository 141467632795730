//Fonts
@import url("https://use.typekit.net/jml4rgr.css");

// Font Variables
$h1-large: "anton",sans-serif;
$gt-font-QS: "proxima-nova", sans-serif;

$gt-html-base-font-size: 62.5%;
$default-font: 'sans-serif';

$gt-font-base: 13px;
$gt-font-base: 1.3rem;
$gt-font-height: 1;
$gt-font-weight-light: 300;
$gt-font-weight-regular: 400;
$gt-font-weight-bold: 700;
$gt-font-color: $black;

$gt-heading-font: $gt-font-QS, $default-font;
$gt-content-font: $gt-font-QS, $default-font;

$gt-proxima-soft : proxima-soft;
$gt-proxima-nova : proxima-nova;
$gt-lobster : lobster;
$gt-soleil : soleil;
$gt-cuisine : cuisine;

h1 {
  font-family: $gt-heading-font;
  font-weight: $gt-font-weight-bold;
  font-size: 42px;
  font-size: 4.2rem;
  line-height: $gt-font-height;
}

h2 {
  font-family: $gt-heading-font;
  font-weight: $gt-font-weight-bold;
  font-size: 36px;
  font-size: 3.6rem;
  line-height: $gt-font-height;
}

h3 {
  font-family: $gt-heading-font;
  font-size: 20px;
  font-size: 2rem;
  line-height: $gt-font-height;
  font-weight: $gt-font-weight-bold;
}

h4 {
  font-family: $gt-heading-font;
  font-size: 20px;
  font-size: 2rem;
  line-height: $gt-font-height;
  font-weight: $gt-font-weight-bold;
}

h5 {
  font-family: $gt-heading-font;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: $gt-font-weight-bold;
}

h6 {
  font-family: $gt-heading-font;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: $gt-font-weight-bold;
}

p {
  font-family: $gt-content-font;
  font-size: $gt-font-base;
  font-weight: $gt-font-weight-regular;
}
