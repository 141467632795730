@font-face {
  font-family: "untitled-font-1";
  src: url("../fonts/untitled-font-1.eot");
  src: url("../fonts/untitled-font-1.eot?#iefix") format("embedded-opentype"), url("../fonts/untitled-font-1.woff") format("woff"), url("../fonts/untitled-font-1.ttf") format("truetype"), url("../fonts/untitled-font-1.svg#untitled-font-1") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "untitled-font-1" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "untitled-font-1" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cmerawhite:before {
  content: "\62";
}

.icon-heartwhite:before {
  content: "\65";
}

.icon-homewhite:before {
  content: "\66";
}

.icon-newsfeed-white:before {
  content: "\6a";
}

.icon-writewhite:before {
  content: "\6d";
}

.icon-followingpersonheart1:before {
  content: "\61";
}

.icon-followpersonheart1:before {
  content: "\63";
}

.fa-times-circle-menu:before {
    font-size: 16px;
    margin: 0 auto;
    margin-left: -1px;
}

.fa-search:before {
    font-size: 13px;
}