//Fonts
@import url("https://use.typekit.net/jml4rgr.css");

// Font Variables
$h1-large: "anton",sans-serif;
$regular: "proxima-nova", sans-serif;
//Colour Variables
$bright-pink: #fb447c;
$medium-pink: #ff9fb3;
$light-pink:#ffdfe3;

label, strong, small {
  font-family: $regular !important;
}

.pull-right a { 
  color: $bright-pink !important;
}

.jt-modal {
  z-index: 10000;

  .jt-modal-container-inner {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;

    .close {
      width: 40px;
      height: 40px;
      top: 0px;
      position: absolute;
      right: 0;
      z-index: 999;
    }
  }
  .modal-dialog {

    -webkit-animation-name: ajs-zoomIn;
    animation-name: ajs-zoomIn;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

    max-width: 550px;
    margin: 32px auto !important;
    position: relative;
    z-index: 998;

    .close {
      width: 40px;
      height: 40px;
      margin-top: -15px;
      position: absolute;
      right: 0;
    }

    .modal-header {
      .close {
        width: 48px;
        height: 46px;
        margin-top: -15px;
        position: absolute;
        right: 0;
        color: black;
        opacity: 0.5;
        font-size: 27px;
      }
      h4 {
        text-align: center;
        font-family: $regular;
      }
    }

    .jt-modal-inner-full-search {
      width: 100%;
      position: relative;
      padding: 0 15px;
      margin: 15px 0 10px 0;
    }

    .modal-body {
      width: 100%;

      .jt-modal-inner {
        width: 100%;
        display: inline-block;

        ul {
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}

.alertify {
  .ajs-modal {
    z-index: 10002;
  }
}

.jt-info-popup-content {
  h5 {
    font-size: 1.5rem;
    color: $black;
  }
  p {
    color: $black;
  }

  .jt-content-ul {
    margin-left: 17px !important;  
  }

  ul {
    list-style: disc !important;
  }
  li {
    color: $black;
  }
}

.jt-report-modal {
  //z-index: 2000;
  z-index: 10001;
}

.jt-post-modal {
  //z-index: 1002;
  .scrollable-content {
    padding: 0;
  }
  .scrollable {
    padding: 0;

    .jt-modal-inner {
      padding: 0 !important;
      .jt-post-container {
        border: 0;
      }
    }
  }

  a {
    color: $blue-darker;
  }

  .jt-time-line-container {
    background-color: $white;
  }
}

.jt-popup-job-details {
  p {
    @include font-size(14);
    line-height: 1.4;
    color: $gray;
  }

}

.jt-popup-item-title {
  @include font-size(18);
}

@media (max-width: 480px) {
  .modal-content .scrollable-content {
    padding: 15px !important;
  }
}