//2400px;
@include maxSize {
}

//1420px
@include iotaSize {
}

//1368px
@include thetaSize {
  .jt-single-event-page-header {

    .jt-single-event-banner {
    }

    .jt-single-event-banner-general-info {

      .jt-single-event-banner-general-info-inner {

        .jt-single-event-banner-general-info-inner-wrapper {

          h3 {
            @include font-size(38);
            margin-bottom: 3px;
          }

          span {
            @include font-size(21);
            font-weight: 400;
          }
        }
      }
    }

    .jt-single-event-banner-time-stripe {

      .jt-single-event-banner-time-slots {
        min-height: 69px;

        .jt-single-event-banner-time-slots-inner {

          .jt-single-event-banner-time-slot {
            width: 100px;
            height: 76px;

            &:first-child {
            }

            &:last-child {
            }

            h2 {
              @include font-size(40);
            }

            p {
              @include font-size(17);
            }
          }
        }
      }

      .jt-single-event-banner-time-button-bg {

        .jt-single-event-banner-time-button {
        }

        .jt-single-event-banner-time-button-inner {

          button {
          }
        }
      }
    }
  }

  .jt-single-event-about-desc {
    p {
      @include font-size(19);
    }
  }


  .jt-single-welcome-sevent-agenda-container {

    .jt-single-welcome-sevent-agenda-row {

      .jt-agenda-time {
        width: 245px;
        min-height: 61px;

        span {
          padding: 15px 18px;
          @include font-size(22);
        }
      }
      .jt-agenda-desc {
        min-height: 61px;
        @include font-size(22);
        padding: 17px 18px;
        p {

          @include font-size(22);
        }
      }
    }
  }


  .jt-single-event-speakers-content {

    .jt-single-event-speakers {
      padding: 0 15px;

      .jt-single-event-speaker {

        .jt-single-event-speaker-inner {

          .jt-single-event-speaker-img {
            height: 313px;
          }

          .jt-single-event-speaker-desc {

            h4 {
              @include font-size(23);
            }

            span {
              @include font-size(17);
            }
          }
        }
      }
    }
  }


  .jt-single-event-ticket-options-content {

    .jt-single-event-ticket-options {
      // margin: -15px -15px 0 -15px;

      .jt-single-event-ticket-option {

        .jt-single-event-ticket-option-inner {

          .jt-ticket-option-header {

            span {
              font-size: 28px !important;
              //@include font-size(28);
              // padding: 13px 20px;
            }
          }

          .jt-ticket-option-price {

            span {
              font-size: 42px !important;
              //@include font-size(42);
            }
          }

          .jt-ticket-option-desc {

            ul {

              li {
                font-size: 19px !important;
                //@include font-size(19);

                &:last-child {
                }

                &:before {
                }
              }
            }


            .jt-ticket-option-button-wrapper {

              .jt-btn {
              }
            }
          }
        }
      }
    }
  }


  //.jt-single-event-sponsors-content {
  //
  //  .jt-single-event-main-sponsor-row {
  //  }
  //
  //  .jt-single-event-sub-sponsors-row {
  //  }
  //
  //  .jt-single-event-sponsor {
  //
  //    .jt-single-event-sponsor-img-bg {
  //
  //      .jt-single-event-sponsor-img-wrapper {
  //
  //        .jt-single-event-sponsor-img {
  //
  //          .jt-single-event-sponsor-img-inner {
  //
  //            img {
  //            }
  //          }
  //        }
  //      }
  //    }
  //
  //    .jt-single-event-sponsor-label {
  //
  //
  //      label {
  //        @include font-size(17);
  //      }
  //    }
  //  }
  //
  //  .jt-single-event-main-sponsor-row {
  //
  //    .jt-single-event-sponsor {
  //      height: 258px;
  //
  //      .jt-single-event-sponsor-img-bg {
  //        padding: 0 0 30px 0;
  //
  //        .jt-single-event-sponsor-img-wrapper {
  //
  //          .jt-single-event-sponsor-img {
  //            .jt-single-event-sponsor-img-inner {
  //              img {
  //                max-height: 288px;
  //              }
  //            }
  //          }
  //        }
  //      }
  //
  //      .jt-single-event-sponsor-label {
  //      }
  //    }
  //  }
  //
  //  .jt-single-event-sub-sponsors-row {
  //    .jt-single-event-sponsor {
  //      width: 19%;
  //      height: 160px;
  //
  //      .jt-single-event-sponsor-img-bg {
  //        padding: 10px 10px 30px 10px;
  //
  //        .jt-single-event-sponsor-img-wrapper {
  //
  //          .jt-single-event-sponsor-img {
  //
  //            .jt-single-event-sponsor-img-inner {
  //              img {
  //                max-height: 90px;
  //              }
  //            }
  //          }
  //        }
  //      }
  //    }
  //  }
  //}


}

//1200px;
@include etaSize {
  .jt-single-event-page-header {

    .jt-single-event-banner {
    }

    .jt-single-event-banner-general-info {

      .jt-single-event-banner-general-info-inner {

        .jt-single-event-banner-general-info-inner-wrapper {

          h3 {
            @include font-size(37);
          }

          span {
            @include font-size(20);
            font-weight: 400;
          }
        }
      }
    }

    .jt-single-event-banner-time-stripe {

      .jt-single-event-banner-time-slots {

        .jt-single-event-banner-time-slots-inner {

          .jt-single-event-banner-time-slot {
            width: 100px;
            height: 75px;

            &:first-child {
            }

            &:last-child {
            }

            h2 {
              @include font-size(40);
            }

            p {
              @include font-size(17);
            }
          }
        }
      }

      .jt-single-event-banner-time-button-bg {

        .jt-single-event-banner-time-button {
        }

        .jt-single-event-banner-time-button-inner {

          button {
          }
        }
      }
    }
  }

  .jt-single-event-about-desc {
    p {
      @include font-size(18);
    }
  }

  .jt-single-welcome-sevent-agenda-container {

    .jt-single-welcome-sevent-agenda-row {

      .jt-agenda-time {
        width: 222px;
        min-height: 52px;

        span {
          padding: 12px 18px;
          @include font-size(20);
        }
      }
      .jt-agenda-desc {
        min-height: 52px;
        padding: 14px 18px;
        @include font-size(20);

        p {
          @include font-size(20);
        }
      }
    }
  }


  .jt-single-event-speakers-content {

    .jt-single-event-speakers {
      padding: 0 0px;

      .jt-single-event-speaker {

        .jt-single-event-speaker-inner {

          .jt-single-event-speaker-img {
            height: 280px;
          }

          .jt-single-event-speaker-desc {

            h4 {
              @include font-size(23);
            }

            span {
              @include font-size(17);
            }
          }
        }
      }
    }
  }

  .jt-single-event-ticket-options-content {

    .jt-single-event-ticket-options {

      .jt-single-event-ticket-option {

        .jt-single-event-ticket-option-inner {

          .jt-ticket-option-header {

            span {
              font-size: 26px !important;
              //@include font-size(26);
              // padding: 10px 10px;
            }
          }

          .jt-ticket-option-price {

            span {
              font-size: 38px !important;
              //@include font-size(38);
              // padding: 10px 10px;
            }
          }

          .jt-ticket-option-desc {

            ul {

              li {
                font-size: 18px !important;
                //@include font-size(18);

                &:last-child {
                }

                &:before {
                  top: 20px;
                }
              }
            }


            .jt-ticket-option-button-wrapper {

              .jt-btn {
              }
            }
          }
        }
      }
    }
  }


  //.jt-single-event-sponsors-content {
  //
  //  .jt-single-event-main-sponsor-row {
  //  }
  //
  //  .jt-single-event-sub-sponsors-row {
  //  }
  //
  //  .jt-single-event-sponsor {
  //
  //    .jt-single-event-sponsor-img-bg {
  //
  //      .jt-single-event-sponsor-img-wrapper {
  //
  //        .jt-single-event-sponsor-img {
  //
  //          .jt-single-event-sponsor-img-inner {
  //
  //            img {
  //            }
  //          }
  //        }
  //      }
  //    }
  //
  //    .jt-single-event-sponsor-label {
  //
  //
  //      label {
  //        @include font-size(17);
  //      }
  //    }
  //  }
  //
  //  .jt-single-event-main-sponsor-row {
  //
  //    .jt-single-event-sponsor {
  //      height: 258px;
  //
  //      .jt-single-event-sponsor-img-bg {
  //        padding: 0 0 30px 0;
  //
  //        .jt-single-event-sponsor-img-wrapper {
  //
  //          .jt-single-event-sponsor-img {
  //            .jt-single-event-sponsor-img-inner {
  //              img {
  //                max-height: 288px;
  //              }
  //            }
  //          }
  //        }
  //      }
  //
  //      .jt-single-event-sponsor-label {
  //      }
  //    }
  //  }
  //
  //  .jt-single-event-sub-sponsors-row {
  //    .jt-single-event-sponsor {
  //      width: 24%;
  //      height: 160px;
  //
  //      .jt-single-event-sponsor-img-bg {
  //        padding: 10px 10px 30px 10px;
  //
  //        .jt-single-event-sponsor-img-wrapper {
  //
  //          .jt-single-event-sponsor-img {
  //
  //            .jt-single-event-sponsor-img-inner {
  //              img {
  //                max-height: 90px;
  //              }
  //            }
  //          }
  //        }
  //      }
  //    }
  //  }
  //}


}

//992px
@include zetaSize {

  .jt-single-event-page-header {

    .jt-single-event-banner {
    }

    .jt-single-event-banner-general-info {

      .jt-single-event-banner-general-info-inner {

        .jt-single-event-banner-general-info-inner-wrapper {

          h3 {
            @include font-size(36);
            //max-height: 70px;
          }

          span {
            @include font-size(19);
            font-weight: 400;
          }
        }
      }
    }

    .jt-single-event-banner-time-stripe {

      .jt-single-event-banner-time-slots {

        .jt-single-event-banner-time-slots-inner {

          h3 {
            @include font-size(26);
            line-height: 26px;
            padding: 15px 0;
          }

          .jt-single-event-banner-time-slot {
            width: 100px;
            height: 68px;

            &:first-child {
            }

            &:last-child {
            }

            h2 {
              @include font-size(32);
            }

            p {
              @include font-size(16);
            }
          }
        }
      }

      .jt-single-event-banner-time-button-bg {

        .jt-single-event-banner-time-button {
        }

        .jt-single-event-banner-time-button-inner {

          button {
          }
        }
      }
    }
  }

  .jt-single-event-about-desc {
    p {
      @include font-size(17);
    }
  }

  .jt-single-welcome-sevent-agenda-container {

    .jt-single-welcome-sevent-agenda-row {

      .jt-agenda-time {
        width: 208px;
        min-height: 47px;

        span {
          padding: 10px 15px;
          @include font-size(19);
        }
      }
      .jt-agenda-desc {
        min-height: 47px;
        padding: 12px 15px;
        @include font-size(19);

        p {
          @include font-size(19);
        }
      }
    }
  }

  .jt-single-event-speakers-content {
    .jt-single-event-speakers {
      padding: 0 0px;

      .jt-single-event-speaker {
        width: 33.3333%;

        .jt-single-event-speaker-inner {

          .jt-single-event-speaker-img {
            height: 280px;
          }

          .jt-single-event-speaker-desc {

            h4 {
              @include font-size(21);
            }

            span {
              @include font-size(16);
            }
          }
        }
      }
    }
  }


  .jt-single-event-ticket-options-content {

    .jt-single-event-ticket-options {

      .jt-single-event-ticket-option {
        // padding: 10px 10px;

        .jt-single-event-ticket-option-inner {

          .jt-ticket-option-header {

            span {
              font-size: 24px !important;
              //@include font-size(24);
              // padding: 10px 10px;
            }
          }

          .jt-ticket-option-price {

            span {
              font-size: 32px !important;
              //@include font-size(32);
              // padding: 10px 10px;
            }
          }

          .jt-ticket-option-desc {

            ul {

              li {
                font-size: 17px !important;
                //@include font-size(17);
                padding: 8px 0 8px 0px;
                line-height: 1.1;
                height: 100px;

                &:last-child {
                }

                &:before {
                  background-size: 10px 14px;
                  top: 10px;
                }
              }
            }


            .jt-ticket-option-button-wrapper {

              .jt-btn {
                // padding: 15px 15px;
              }
            }
          }
        }
      }
    }
  }



  //.jt-single-event-sponsors-content {
  //
  //  .jt-single-event-main-sponsor-row {
  //  }
  //
  //  .jt-single-event-sub-sponsors-row {
  //  }
  //
  //  .jt-single-event-sponsor {
  //
  //    .jt-single-event-sponsor-img-bg {
  //
  //      .jt-single-event-sponsor-img-wrapper {
  //
  //        .jt-single-event-sponsor-img {
  //
  //          .jt-single-event-sponsor-img-inner {
  //
  //            img {
  //            }
  //          }
  //        }
  //      }
  //    }
  //
  //    .jt-single-event-sponsor-label {
  //
  //
  //      label {
  //        @include font-size(17);
  //      }
  //    }
  //  }
  //
  //  .jt-single-event-main-sponsor-row {
  //
  //    .jt-single-event-sponsor {
  //      height: 258px;
  //
  //      .jt-single-event-sponsor-img-bg {
  //        padding: 0 0 30px 0;
  //
  //        .jt-single-event-sponsor-img-wrapper {
  //
  //          .jt-single-event-sponsor-img {
  //            .jt-single-event-sponsor-img-inner {
  //              img {
  //                max-height: 288px;
  //              }
  //            }
  //          }
  //        }
  //      }
  //
  //      .jt-single-event-sponsor-label {
  //      }
  //    }
  //  }
  //
  //  .jt-single-event-sub-sponsors-row {
  //    .jt-single-event-sponsor {
  //      width: 27%;
  //      height: 160px;
  //
  //      .jt-single-event-sponsor-img-bg {
  //        padding: 10px 10px 30px 10px;
  //
  //        .jt-single-event-sponsor-img-wrapper {
  //
  //          .jt-single-event-sponsor-img {
  //
  //            .jt-single-event-sponsor-img-inner {
  //              img {
  //                max-height: 90px;
  //              }
  //            }
  //          }
  //        }
  //      }
  //    }
  //  }
  //}



}

//768px
@include epsilonSize {

  .jt-single-event-page-header {

    .jt-single-event-banner {
    }

    .jt-single-event-banner-general-info {

      .jt-single-event-banner-general-info-inner {

        .jt-single-event-banner-general-info-inner-wrapper {

          h3 {
            @include font-size(32);
          }

          span {
            @include font-size(17);
            font-weight: 400;
          }
        }
      }
    }

    .jt-single-event-banner-time-stripe {

      .jt-single-event-banner-time-slots {
        min-height: 55px;

        .jt-single-event-banner-time-slots-inner {
          padding-right: 20px;

          h3 {
            @include font-size(24);
            line-height: 24px;
            padding: 13px 0;
          }

          .jt-single-event-banner-time-slot {
            width: 70px;
            height: 55px;
            margin: 0 4px;

            &:first-child {
            }

            &:last-child {
            }

            h2 {
              @include font-size(26);
            }

            p {
              @include font-size(12);
            }
          }
        }
      }

      .jt-single-event-banner-time-button-bg {

        .jt-single-event-banner-time-button {
        }

        .jt-single-event-banner-time-button-inner {

          button {
          }
        }
      }
    }
  }

  .jt-single-event-about-desc {
    p {
      @include font-size(17);
    }
  }

  .jt-single-welcome-sevent-agenda-container {

    .jt-single-welcome-sevent-agenda-row {

      .jt-agenda-time {
        width: 187px;
        min-height: 44px;

        span {
          padding: 10px 15px;
          @include font-size(17);
        }
      }
      .jt-agenda-desc {
        min-height: 44px;
        padding: 12px 15px;
        @include font-size(17);

        p {

          @include font-size(17);
        }
      }
    }
  }


  .jt-single-event-speakers-content {
    .jt-single-event-speakers {

      .jt-single-event-speaker {

        .jt-single-event-speaker-inner {

          .jt-single-event-speaker-img {
            height: 230px;
          }

          .jt-single-event-speaker-desc {

            h4 {
              @include font-size(20);
            }

            span {
              @include font-size(16);
            }
          }
        }
      }
    }
  }


  .jt-single-event-ticket-options-content {

    .jt-single-event-ticket-options {

      .jt-single-event-ticket-option {
        width: 75%;

        .jt-single-event-ticket-option-inner {

          .jt-ticket-option-header {

            span {
              font-size: 22px !important;
              //@include font-size(22);
            }
          }

          .jt-ticket-option-price {

            span {
              font-size: 32px !important;
              //@include font-size(32);
            }
          }

          .jt-ticket-option-desc {

            ul {

              li {
                font-size: 16px !important;
                //@include font-size(16);
                height: 77px;

                &:last-child {
                }

                &:before {
                  background-size: 9px 12px;
                  top: 10px;
                }
              }
            }


            .jt-ticket-option-button-wrapper {

              .jt-btn {
              }
            }
          }
        }
      }
    }
  }


  //.jt-single-event-sponsors-content {
  //
  //  .jt-single-event-main-sponsor-row {
  //  }
  //
  //  .jt-single-event-sub-sponsors-row {
  //  }
  //
  //  .jt-single-event-sponsor {
  //
  //    .jt-single-event-sponsor-img-bg {
  //
  //      .jt-single-event-sponsor-img-wrapper {
  //
  //        .jt-single-event-sponsor-img {
  //
  //          .jt-single-event-sponsor-img-inner {
  //
  //            img {
  //            }
  //          }
  //        }
  //      }
  //    }
  //
  //    .jt-single-event-sponsor-label {
  //
  //
  //      label {
  //        @include font-size(16);
  //      }
  //    }
  //  }
  //
  //  .jt-single-event-main-sponsor-row {
  //
  //    .jt-single-event-sponsor {
  //      height: 258px;
  //
  //      .jt-single-event-sponsor-img-bg {
  //        padding: 0 0 25px 0;
  //
  //        .jt-single-event-sponsor-img-wrapper {
  //
  //          .jt-single-event-sponsor-img {
  //            .jt-single-event-sponsor-img-inner {
  //              img {
  //                max-height: 288px;
  //              }
  //            }
  //          }
  //        }
  //      }
  //
  //      .jt-single-event-sponsor-label {
  //      }
  //    }
  //  }
  //
  //  .jt-single-event-sub-sponsors-row {
  //    .jt-single-event-sponsor {
  //      width: 31%;
  //      height: 160px;
  //
  //      .jt-single-event-sponsor-img-bg {
  //        padding: 10px 10px 25px 10px;
  //
  //        .jt-single-event-sponsor-img-wrapper {
  //
  //          .jt-single-event-sponsor-img {
  //
  //            .jt-single-event-sponsor-img-inner {
  //              img {
  //                max-height: 90px;
  //              }
  //            }
  //          }
  //        }
  //      }
  //    }
  //  }
  //}


  //CHECKOUT PAGE FORM
  .jt-web-checkout-container-section {
    .scrollable-content {
      .jt-grouping-list {
        .jt-grouping-list-inner {
          .jt-check-order-table {
          }

          .buttons-row {
            .button-wrapper {
              button {
                padding: 12px 15px;
                @include font-size(13);
              }
            }
          }
        }
      }
    }
  }


}

//620px
@include deltaSize {

  .to-show-hide {
    .for-large {
      display: none !important;
    }
    .for-small {
      display: inline-block !important;
    }
  }

  .jt-single-event-page-header {

    .jt-single-event-banner {
    }

    .jt-single-event-banner-general-info {

      .jt-single-event-banner-general-info-inner {

        .jt-single-event-banner-general-info-inner-wrapper {

          h3 {
            @include font-size(28);
          }

          span {
            @include font-size(15);
            font-weight: 400;
          }
        }
      }
    }

    .jt-single-event-banner-time-stripe {

      .jt-single-event-banner-time-slots {
        padding-right: 0;

        .jt-single-event-banner-time-slots-inner {
          padding-right: 0;
          text-align: center;
          padding-bottom: 0px;

          h3 {
            @include font-size(22);
            line-height: 22px;
            padding: 0 15px 2px 15px;
          }

          .jt-single-event-banner-time-slot {
            width: 70px;
            height: 55px;
            margin: 0 4px;

            &:first-child {
            }

            &:last-child {
            }

            h2 {
              @include font-size(26);
            }

            p {
              @include font-size(12);
            }
          }
        }
      }

      .jt-single-event-banner-time-button-bg {
        position: relative;
        width: 100%;

        .jt-single-event-banner-time-button {
        }

        .jt-single-event-banner-time-button-inner {

          button {
          }
        }
      }
    }
  }

  .jt-single-event-about-desc {
    p {
      @include font-size(17);
    }
  }

  .jt-single-welcome-sevent-agenda-container {

    .jt-single-welcome-sevent-agenda-row {
      margin-bottom: 20px;

      .jt-agenda-time {
        width: 100%;
        margin: 0 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        //background: none;

        span {
          padding: 10px 15px;
          @include font-size(17);
          //background: $dark-blue;
          //margin-bottom: 10px;
          text-align: center;
        }
      }
      .jt-agenda-desc {
        width: 100%;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        @include font-size(17);
        padding: 12px 15px;

        p {

          @include font-size(17);
        }
      }
    }
  }


  .jt-single-event-speakers-content {
    .jt-single-event-speakers {

      .jt-single-event-speaker {
        width: 50%;

        .jt-single-event-speaker-inner {

          .jt-single-event-speaker-img {
            height: 250px;
          }

          .jt-single-event-speaker-desc {

            h4 {
            }

            span {
            }
          }
        }
      }
    }
  }

  //.jt-single-event-sponsors-content {
  //
  //  .jt-single-event-main-sponsor-row {
  //  }
  //
  //  .jt-single-event-sub-sponsors-row {
  //  }
  //
  //  .jt-single-event-sponsor {
  //
  //    .jt-single-event-sponsor-img-bg {
  //
  //      .jt-single-event-sponsor-img-wrapper {
  //
  //        .jt-single-event-sponsor-img {
  //
  //          .jt-single-event-sponsor-img-inner {
  //
  //            img {
  //            }
  //          }
  //        }
  //      }
  //    }
  //
  //    .jt-single-event-sponsor-label {
  //
  //
  //      label {
  //        @include font-size(15);
  //      }
  //    }
  //  }
  //
  //  .jt-single-event-main-sponsor-row {
  //
  //    .jt-single-event-sponsor {
  //      height: 258px;
  //
  //      .jt-single-event-sponsor-img-bg {
  //        padding: 0 0 25px 0;
  //
  //        .jt-single-event-sponsor-img-wrapper {
  //
  //          .jt-single-event-sponsor-img {
  //            .jt-single-event-sponsor-img-inner {
  //              img {
  //                max-height: 288px;
  //              }
  //            }
  //          }
  //        }
  //      }
  //
  //      .jt-single-event-sponsor-label {
  //      }
  //    }
  //  }
  //
  //  .jt-single-event-sub-sponsors-row {
  //    .jt-single-event-sponsor {
  //      width: 48%;
  //      height: 160px;
  //
  //      .jt-single-event-sponsor-img-bg {
  //        padding: 10px 10px 25px 10px;
  //
  //        .jt-single-event-sponsor-img-wrapper {
  //
  //          .jt-single-event-sponsor-img {
  //
  //            .jt-single-event-sponsor-img-inner {
  //              img {
  //                max-height: 90px;
  //              }
  //            }
  //          }
  //        }
  //      }
  //    }
  //  }
  //}


  //CHECKOUT PAGE FORM
  .jt-web-checkout-container-section {
    .scrollable-content {
      .jt-grouping-list {
        .jt-grouping-list-inner {
          .jt-check-order-table {
          }

          .buttons-row {
            .button-wrapper {
              width: 100%;
              margin-bottom: 10px;
              max-width: 100%;
            }
              button {
                width: 100%;
                padding: 12px 15px;
                @include font-size(13);
              }
            }
          }
        }
      }
    }

}

//480px
@include gammaSize {

  .jt-single-event-page-header {

    .jt-single-event-banner {
    }

    .jt-single-event-banner-general-info {
      padding: 30px 20px 80px 20px;

      .jt-single-event-banner-general-info-inner {

        .jt-single-event-banner-general-info-inner-wrapper {

          h3 {
            @include font-size(26);
            //max-height: 53px;
            margin-bottom: 5px;
          }

          span {
            @include font-size(14);
          }
        }
      }
    }

    .jt-single-event-banner-time-stripe {
      padding: 20px 20px;

      .jt-single-event-banner-time-slots {

        .jt-single-event-banner-time-slots-inner {

          .jt-single-event-banner-time-slot {
            width: 63px;

            &:first-child {
            }

            &:last-child {
            }

            h2 {
            }

            p {
            }
          }
        }
      }

      .jt-single-event-banner-time-button-bg {

        .jt-single-event-banner-time-button {
        }

        .jt-single-event-banner-time-button-inner {

          button {
          }
        }
      }
    }
  }

  .jt-single-event-about-desc {
    p {
      @include font-size(16);
    }
  }

  .jt-single-welcome-sevent-agenda-container {

    .jt-single-welcome-sevent-agenda-row {

      .jt-agenda-time {

        span {
          padding: 10px 15px;
          @include font-size(16);
        }
      }
      .jt-agenda-desc {
        padding: 12px 15px;
        @include font-size(16);

        p {
          @include font-size(16);
        }
      }
    }
  }

  .jt-single-event-speakers-content {
    .jt-single-event-speakers {
      margin: -10px -10px 0 -10px;

      .jt-single-event-speaker {
        width: 50%;
        padding: 10px;

        .jt-single-event-speaker-inner {

          .jt-single-event-speaker-img {
            height: 220px;
          }

          .jt-single-event-speaker-desc {

            h4 {
            }

            span {
            }
          }
        }
      }
    }
  }



  .jt-single-event-ticket-options-content {

    .jt-single-event-ticket-options {

      .jt-single-event-ticket-option {


        .jt-single-event-ticket-option-inner {

          .jt-ticket-option-header {

            span {
              font-size: 22px !important;
              //@include font-size(22);
            }
          }

          .jt-ticket-option-price {

            span {
              font-size: 30px !important;
              //@include font-size(30);
            }
          }

          .jt-ticket-option-desc {

            ul {

              li {
                font-size: 15px !important;
                //@include font-size(15);
                height: 89px;

                &:last-child {
                }

                &:before {
                  background-size: 10px 15px;
                  top: 12px;
                }
              }
            }


            .jt-ticket-option-button-wrapper {

              .jt-btn {
              }
            }
          }
        }
      }
    }
  }


  //.jt-single-event-sponsors-content {
  //
  //  .jt-single-event-main-sponsor-row {
  //  }
  //
  //  .jt-single-event-sub-sponsors-row {
  //  }
  //
  //  .jt-single-event-sponsor {
  //
  //    .jt-single-event-sponsor-img-bg {
  //
  //      .jt-single-event-sponsor-img-wrapper {
  //
  //        .jt-single-event-sponsor-img {
  //
  //          .jt-single-event-sponsor-img-inner {
  //
  //            img {
  //            }
  //          }
  //        }
  //      }
  //    }
  //
  //    .jt-single-event-sponsor-label {
  //      padding: 0 0;
  //
  //      label {
  //        @include font-size(14);
  //      }
  //    }
  //  }
  //
  //  .jt-single-event-main-sponsor-row {
  //
  //    .jt-single-event-sponsor {
  //      height: 258px;
  //      width: 100%;
  //
  //      .jt-single-event-sponsor-img-bg {
  //        padding: 0 0 25px 0;
  //
  //        .jt-single-event-sponsor-img-wrapper {
  //
  //          .jt-single-event-sponsor-img {
  //            .jt-single-event-sponsor-img-inner {
  //              img {
  //                max-height: 180px;
  //              }
  //            }
  //          }
  //        }
  //      }
  //
  //      .jt-single-event-sponsor-label {
  //
  //      }
  //    }
  //  }
  //
  //  .jt-single-event-sub-sponsors-row {
  //    .jt-single-event-sponsor {
  //      max-width: 100%;
  //      width: 100%;
  //
  //      .jt-single-event-sponsor-img-bg {
  //        padding: 10px 0px 25px 0px;
  //
  //        .jt-single-event-sponsor-img-wrapper {
  //
  //          .jt-single-event-sponsor-img {
  //
  //            .jt-single-event-sponsor-img-inner {
  //              img {
  //                max-height: 90px;
  //              }
  //            }
  //          }
  //        }
  //      }
  //    }
  //  }
  //}

  .jt-grouping-list {
    .jt-grouping-list-inner {
      .jt-grouping-list-row {
        .jt-grouping-submit {
        }

        .jt-cancel-bt {
        }
      }
    }
  }

}

@media all and (max-width: 420px) {
  .jt-buy-tickets-popup {
    .modal-body {
      .for-large, .jt-order-tb-price {
        display: none;
      }
      .for-small {
        display: table-row;
      }
    }
  }

  .jt-buy-tickets-popup {
    .modal-body {
      padding: 15px 0;

      .jt-modal-inner {
        padding: 0 0;
      }
    }
  }
}

//380px
@include betaSize {

  .jt-single-event-page-header {

    .jt-single-event-banner {
    }

    .jt-single-event-banner-general-info {

      .jt-single-event-banner-general-info-inner {

        .jt-single-event-banner-general-info-inner-wrapper {

          h3 {
            @include font-size(22);
          }

          span {
            @include font-size(13);
          }
        }
      }
    }

    .jt-single-event-banner-time-stripe {
      padding: 20px 20px;

      .jt-single-event-banner-time-slots {

        .jt-single-event-banner-time-slots-inner {

          .jt-single-event-banner-time-slot {
            width: 55px;
            height: 49px;

            &:first-child {
            }

            &:last-child {
            }

            h2 {
              @include font-size(22);
            }

            p {
              @include font-size(11);
            }
          }
        }
      }

      .jt-single-event-banner-time-button-bg {

        .jt-single-event-banner-time-button {
        }

        .jt-single-event-banner-time-button-inner {

          button {
          }
        }
      }
    }
  }

  .jt-single-event-speakers-content {
    .jt-single-event-speakers {

      .jt-single-event-speaker {
        width: 100%;
        padding: 10px 30px;

        .jt-single-event-speaker-inner {

          .jt-single-event-speaker-img {
            height: 300px;
          }

          .jt-single-event-speaker-desc {

            h4 {
              @include font-size(19);
            }

            span {
              @include font-size(15);
            }
          }
        }
      }
    }
  }


  //CHECKOUT PAGE FORM
  .jt-web-checkout-container-section {
    .scrollable-content {
      .jt-grouping-list {
        .jt-grouping-list-inner {
          .jt-check-order-table {
            table {
              tr {
                td{
                  padding: 10px 3px;
                  @include font-size(13);
                }

                th {
                  padding: 10px 3px;
                  @include font-size(13);
                }
              }
            }
          }
        }
      }
    }
  }

}

//340px
@include alphaSize {
}















