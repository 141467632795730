.jt-signup-box {
  @include bg-rgba(87, 207, 255, 0.75, 57 CFFF);
  @include abs-pos(43%, 0, auto, 0);

  .jt-signup-box-inner {
    padding: 32px 20px;
    float: none;
    margin: 0 auto;
    max-width: 600px;
    display: table;
    color: $white;

    .signup-form-label {
      width: 35%;
      float: right;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      height: 100%;

      p {
        margin: 0;
        padding: 0;
        padding-left: 5px;
        color: $white;
        @include font-size(32);
        font-weight: $gt-font-weight-bold;
      }

      .jt-profile-photo {

        padding-left: 10px;
        height: 90px;
        overflow: hidden;
        text-align: center;

        img {
          display: block;
          margin: 0 auto;
          max-height: 90px;
          max-width: 90px;
        }
      }

    }

    .signup-form-content {
      float: left;
      width: 65%;

      .signup-form-field-row {
        float: left;
        margin: 0 0 8px 0;
        width: 100%;

        input {
          height: 30px;
          color: $black;
          font-size: 12px;
        }

        &:last-child {
          margin: 0;
        }

        input[type="email"], input[type="password"] {
        }

        input[type="submit"] {
          float: right;
          max-width: 125px;
          vertical-align: middle;
          background: $white;
          font-weight: $gt-font-weight-bold;
          padding-bottom: 29px;
          @include font-size(15);
          margin: 0;
          border: 1px solid $blue-light;
          color: $black;
          background-color: #f4f5f5;
          background-image: -webkit-gradient(linear, left top, left bottom, from(#f4f5f5), to(#dfdddd));
          background-image: -webkit-linear-gradient(top, #f4f5f5, #dfdddd);
          background-image: -moz-linear-gradient(top, #f4f5f5, #dfdddd);
          background-image: -ms-linear-gradient(top, #f4f5f5, #dfdddd);
          background-image: -o-linear-gradient(top, #f4f5f5, #dfdddd);
          background-image: linear-gradient(to bottom, #f4f5f5, #dfdddd);
          filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr='#f4f5f5', endColorstr='#dfdddd');
        }

        span {
          float: right;
          padding: 14px 0 0 0;
          margin-right: 10px;

          a {
            color: $white;
            padding: 6px 8px;
          }
        }
      }
    }
  }

  .jt-sign-box-format {
    padding: 10px 15px;
  }

}

.jt-brand-signup-box {
  @include bg-rgba(87, 207, 255, 0.75, 57 CFFF);
  @include abs-pos(43%, 0, auto, 0);
  top: 33% !important;

  .jt-login-box-inner {
    padding: 32px 20px;
    float: none;
    margin: 0 auto;
    max-width: 600px;
    display: table;
    position: relative;

    .jt-issue-box {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 100%;
      height: auto;
      display: inline-block;

      .jt-issue-box-inner {
        background: orange;
        float: left;
        width: 100%;

        div {
          width: 100%;
          float: left;
        }
      }

      ul {
        width: 100%;
        height: auto;
        float: left;
        display: block;

        li {
          width: 100%;
          float: left;
        }
      }
    }

    .login-form-label {
      width: 35%;
      float: right;
      padding-left: 5px;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      height: 100%;

      p {
        margin: 0;
        padding: 0 0 3px 5px;
        color: $white;
        @include font-size(32);
        font-weight: $gt-font-weight-bold;

      }

      .jt-profile-photo {

        padding-left: 10px;
        height: 96px;
        overflow: hidden;
        text-align: center;

        img {
          display: block;
          margin: 0 auto;
          max-height: 90px;
          max-width: 90px;
        }
      }

    }

    .login-form-content {
      float: left;
      width: 65%;

      .login-form-field-row {
        float: left;
        margin: 0 0 8px 0;
        width: 100%;

        &:last-child {
          margin: 0;
        }

        input[type="email"], input[type="password"] {
        }

        input {
          height: 37px;
          color: $black;
        }

        input[type="submit"] {
          float: right;
          max-width: 125px;
          vertical-align: middle;
          background: $white;
          font-weight: $gt-font-weight-bold;
          padding-bottom: 29px;
          @include font-size(15);
          margin: 0;
          border: 1px solid $blue-light;
          color: $black;
          background-color: #f4f5f5;
          background-image: -webkit-gradient(linear, left top, left bottom, from(#f4f5f5), to(#dfdddd));
          background-image: -webkit-linear-gradient(top, #f4f5f5, #dfdddd);
          background-image: -moz-linear-gradient(top, #f4f5f5, #dfdddd);
          background-image: -ms-linear-gradient(top, #f4f5f5, #dfdddd);
          background-image: -o-linear-gradient(top, #f4f5f5, #dfdddd);
          background-image: linear-gradient(to bottom, #f4f5f5, #dfdddd);
          filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr='#f4f5f5', endColorstr='#dfdddd');
        }

        span {
          float: right;
          padding: 14px 0 0 0;
          margin-right: 10px;

          a {
            color: $white;
            padding: 6px 8px;
          }
        }
      }
    }
  }

  .jt-login-box-format {
    padding: 10px 15px;
  }
}

.jt-change-password-box {
  @include bg-rgba(0, 134, 237, 0.7, 0086 ED);
  @include abs-pos(43%, 0, auto, 0);

  .jt-signup-box-inner {
    padding: 32px 20px;
    float: none;
    margin: 0 auto;
    max-width: 600px;
    display: table;
    color: $white;

    .signup-form-label {
      width: 35%;
      float: right;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      height: 100%;

      p {
        margin: 0;
        padding: 0;
        padding-left: 5px;
        color: $white;
        @include font-size(32);
        font-weight: $gt-font-weight-bold;
      }

      .jt-profile-photo {

        padding-left: 10px;
        height: 90px;
        overflow: hidden;
        text-align: center;

        img {
          display: block;
          margin: 0 auto;
          max-height: 90px;
          max-width: 90px;
        }
      }

    }

    .signup-form-content {
      float: left;
      width: 65%;

      .signup-form-field-row {
        float: left;
        margin: 0 0 8px 0;
        width: 100%;

        input {
          height: 37px;
          color: $black;
        }

        &:last-child {
          margin: 0;
        }

        input[type="email"], input[type="password"] {
        }

        input[type="submit"] {
          float: right;
          max-width: 125px;
          vertical-align: middle;
          background: $white;
          font-weight: $gt-font-weight-bold;
          padding-bottom: 29px;
          @include font-size(15);
          margin: 0;
          border: 1px solid $blue-light;
          color: $black;
          background-color: #f4f5f5;
          background-image: -webkit-gradient(linear, left top, left bottom, from(#f4f5f5), to(#dfdddd));
          background-image: -webkit-linear-gradient(top, #f4f5f5, #dfdddd);
          background-image: -moz-linear-gradient(top, #f4f5f5, #dfdddd);
          background-image: -ms-linear-gradient(top, #f4f5f5, #dfdddd);
          background-image: -o-linear-gradient(top, #f4f5f5, #dfdddd);
          background-image: linear-gradient(to bottom, #f4f5f5, #dfdddd);
          filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr='#f4f5f5', endColorstr='#dfdddd');
        }

        span {
          float: right;
          padding: 14px 0 0 0;
          margin-right: 10px;

          a {
            color: $white;
            padding: 6px 8px;
          }
        }
      }
    }
  }

  .jt-sign-box-format {
    padding: 10px 15px;
  }

}

.jt-login-terms {
  clear: both;
  color: #ffffff;
  font-size: 9px;
  padding-top: 0px;
  padding-bottom: 5px;
  text-align: right;

  a {
    text-decoration: underline;
    color: $white;
  }
}

.jt-login-right {
  text-align: right;

}
