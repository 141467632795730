.jt-news-feed-stream-post-form {
  width: 100%;
  margin: 0 0 8px 0;
  background: $white;

  .jt-news-feed-stream-post-form-inner {
    display: flex;
    padding: 6px 10px;

    .jt-news-feed-stream-post-form-inner-left {
      display: block;
      direction: ltr;
      margin: 0 15px 0 0;

      .jt-stream-post-pro-pic {
        background-clip: border-box;
        //background-color: brown;
        background-origin: padding-box;
        display: inline-block;
        width: 40px;
        height: 40px;
        background-size: 100% 100%;

      }
    }
    .jt-news-feed-stream-post-form-inner-right {
      direction: ltr;
      display: block;
      @include css3-prefix('flex', 1);

      textarea {
        width: 100%;
        border: none;
        margin: 6px 0 0 0;
      }
    }
  }
  .jt-news-feed-stream-post-form-footer {
    padding: 6px 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-box;
    display: box;

    .jt-news-feed-stream-post-form-submit {
      float: right;
      display: block;
    }
  }
}