//Fonts
@import url("https://use.typekit.net/jml4rgr.css");

// Font Variables
$h1-large: "anton",sans-serif;
$regular: "proxima-nova", sans-serif;

//Colour Variables
$bright-pink: #fb447c;
$medium-pink: #ff9fb3;
$light-pink:#ffdfe3;


.jt-welcome-container {
  background: url(#{$gt-images}welcome-bg.jpg) no-repeat center center fixed;
  @include css3-prefix('background-size', cover);
  height: 2000px;

  .jt-welcome-logo {
    width: 100%;

    .jt-welcome-logo-inner {
      padding: 42px 30px;
      text-align: center;
      color: $white;

      h3 {
        width: 100%;
        clear: both;
        font-weight: $gt-font-weight-bold;
        @include font-size(72);
        text-shadow: 1px 1px 2px $gray;
      }

      h6 {
        width: 100%;
        clear: both;
        font-weight: $gt-font-weight-regular;
        @include font-size(22);
        text-transform: uppercase;
      }
    }
  }

  .jt-welcome-nav {
    width: 100%;
    @include abs-pos(43%, 0, auto, 0);

    ul {
      width: 100%;
      float: left;
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        float: left;
        margin: 0;
        padding: 0;
        width: 36%;
        max-width: 215px;
        min-width: 130px;

        a {
          margin: 0;
          padding: 42px 30px;
          display: block;
          @include bg-rgba(0, 134, 237, 0.7, 0086 ED);
          text-align: center;
          color: $white;
          @include font-size(50);
          line-height: 50px;
          font-weight: $gt-font-weight-bold;
        }

        &:last-child {
          float: right;

          a {
            @include bg-rgba(87, 207, 255, 0.75, 57 CFFF);
          }
        }
      }
    }
  }

  .jt-network-text {
    text-align: center;
    color: $white;
    font-weight: $gt-font-weight-regular;
    font-size: 2rem;
    @include abs-pos(auto, 0, 18%, 0);
  }

  .jt-welcome-footer {
    width: 100%;
    @include abs-pos(auto, 0, 0, 0);
    text-align: center;
    padding: 20px 20px;
    color: $white;
    font-weight: $gt-font-weight-regular;
    padding-top: 0;

    p {
      @include font-size(27);
      text-transform: lowercase;
      line-height: 27px;
      @include marNone;
    }

    span {
      @include font-size(11);
      line-height: 11px;
    }

    a {
      color: $white;
      text-decoration: underline;
      padding: 10px 0;
    }
  }
}

.jt-home-slide-item {
  height: 650px;
}

/*Web app styles*/
.jt-featured-event-wrapper {
  background: $light-pink;
}

.jt-web-welcome-container {
  position: relative;
  width: 100%;
  //height: 100%;
  //background: url(#{$gt-images}welcome-bg.jpg) no-repeat center center fixed;
  //@include css3-prefix('background-size', cover);

  .jt-home-event-button {
    width: 100%;
    background: $bright-pink;
    border: none;
    border-radius: 0px;
    color: white;
    font-size: 16pt;
    letter-spacing: 5.575px;
    font-family: $regular;
  }

  .jt-home-event-button:hover {
    background: $medium-pink;
  }
  
  .jt-featured-event {
    max-width: 600px;
    margin: 75px auto 10px !important;
    text-align: left;
    cursor: pointer;
    
    .jt-featured-event-section-header {
      text-align: center;
      font-size: 44pt;
      margin-bottom: 40px;
      background: -moz-linear-gradient(right, #ff9e51, #e84385);
      background: -webkit-linear-gradient(right, #ff9e51, #e84385);
      background: -ms-linear-gradient(right, #ff9e51, #e84385);
      background: -o-linear-gradient(right, #ff9e51, #e84385);
      background: linear-gradient(right, #ff9e51, #e84385);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: "Anton", sans-serif;
      letter-spacing: 5.575px;
      font-family: $h1-large;
      line-height: 1.3;
        
    }

    p {
      font-size: 10pt;
      //white-space: nowrap;
      //text-overflow: ellipsis;
      //overflow: hidden;
    }

    .jt-home-event-img {
      width: 100%;
      height: 500px;
      float: left;
      position: relative;
    }

  }

  .j-web-welcome-cover {
    background-color: rgba(0, 0, 0, .25);
    @include css3-prefix(transition, background .25s ease-in-out);
    position: absolute;
    width: 100%;
    height: 100%;
    .jt-web-welcome-nav {
      .jt-web-welcome-hero {
        width: 100%;
        @include abs-pos(22%, 0, auto, 0);
        color: $white;
        padding: 20px;
        h2 {
          @include font-size(25);
          text-shadow: 0px 1px 5px $black;
        }
        h5 {
          text-shadow: 0px 1px 5px $black;
        }
        .jt-web-welcome-btns {
          clear: both;
          padding-top: 10%;
          .jt-web-basic-link {
            margin-top: 22px;
            a {
              color: $white;
              text-decoration: underline;
            }
          }
        }
        form.jt-web-login-form {
          width: 100%;
          max-width: 340px;
          margin-left: auto;
          margin-right: auto;
          .jt-web-form-control {
          }
          span {
            margin-bottom: 10px;
            width: 100%;
          }
          .jt-web-form-btn {
            width: 100%;
            background: $blue-darker;
            font-weight: 700;
            color: $white;
            border: none;
          }
          .jt-web-form-clear {
            margin-top: 10px;
            margin-left: -8px;
          }
          .jt-web-validation {
            text-align: left;
            margin-bottom: -8px;
            text-shadow: 7px 2px 2px $black;
            a {
              color: $white;
              text-decoration: none;
            }
            a:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .jt-web-welcome-nav {
      .jt-web-welcome-hero.jt-webapp-form {
        width: 100%;
        @include abs-pos(26%, 0, auto, 0);
      }
/*      .jt-web-welcome-hero.jt-webapp-form-2 {
        width: 100%;
        @include abs-pos(20%, 0, auto, 0);
      }*/
    }
  }
  .j-web-welcome-cover:hover {
    background-color: rgba(0, 0, 0, .5);
    @include css3-prefix(transition, background .25s ease-in-out)
  }

  /*** new inline login ***/
  .inline-login {
    background-color: $bright-jc-blue;
    padding: 10px 0;
    text-align: center;
    font-family: $gt-proxima-soft;
    font-weight: normal;

    .inline-login-container{
      .inline-login-inner {
        h3 {
          color: white;
          font-family: $gt-proxima-soft;
          font-weight: normal;
        }

        float: none;
        display: block;
        margin-left: auto;
        margin-right: auto;

        .btn-group {
          margin-bottom: 10px;

          .btn {
            color: $pure-white;
            background-color: transparent;
            font-family: $gt-proxima-nova;
            font-weight: normal;
            &:hover {
              background-color: $blue-darker;
            }
            border-color: $pure-white;
          }

          .active {
            background-color: $blue-darker;
          }
        }

        .jt-web-form-btn {
          border-color: $pure-white !important;
          font-family: $gt-proxima-nova;
          font-weight: normal;
        }

        .forgot-password-link {
          p {
            a {
              color: $pure-white;
              font-family: $gt-proxima-nova;
              font-weight: normal;
            }
          }
        }

      }
    }
  }

  /*** app icons bar ***/
  .app-icons-bar {
    text-align: center;

    .app-icons-bar-container {
      background-color: $pure-white;
      padding: 10px 0;
    }

    .not-a-member-link {
      color: $bright-jc-blue;
      margin-bottom: 10px;
      display: block;
      font-family: $gt-proxima-nova;
      font-weight: normal;
    }

    .app-icon-list {
      a {
        img{
          max-width: 140px;
          margin-right: 20px;
        }
        &:last-child {
          img {
            margin-right: 0;
          }
        }
      }
    }
  }

  .no-padding {
    padding: 0;
  }

  .equal-padding {
    padding: 20px;
  }

  .pink-text {
    color: $jito-pink;
  }

  .blue-button {
    background-color: $bright-jc-blue;
  }

  .pink-button {
    background-color: $jito-pink;
  }

  .flex-row-nowrap {
    display: flex;
    flex-wrap: nowrap;
  }

  .flex-row-nowrap:before, .flex-row-nowrap:after {
    display: none;
  }

  .flex-col {
    display: flex;
    flex-direction: column;
  }

  /*** view-bar ***/
  .view-bar {
    h2 {
      color: $pure-white;
      position: absolute;
      vertical-align: middle;
      padding: 0 10px;
      font-size: 3rem;
      font-family: $gt-proxima-soft;
      font-weight: normal;
      //vertical center safari fix
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
    }

    .view-items-list {
      a {
        .item {
          overflow: hidden;
          .square {
            -moz-transition: all 0.3s;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
          }
        }
      }
      a:hover{
        .item .square {
          -moz-transform: scale(1.1);
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
      }
    }

    .item {
      text-align: center;
      /*display: flex;
      align-items: center;
      justify-content: center;*/

      .square {
         position: relative;
         height: 100%;
         width: 100%;
        padding: 50%;
      }

    }
  }

  .event-bar {
    .making-difference-wrap {
      background-color: $pure-white;
      .title-text {
        //change font
      }

      h3 {
        font-family: $gt-lobster;
        font-weight: normal;
        font-size: 3.5rem;
        color: $black;
      }

      p {
        font-family: $gt-soleil;
        font-weight: lighter;
        font-size: 2rem;

        .bold-text {
          font-weight: bold;
        }
      }

      .button-row {
        text-align: center;
        display: block;
        position: relative;
        margin-top: 20px;

        button {
          color: $pure-white;
          width: 128px;
          font-family: $gt-proxima-soft;
          font-weight: normal;
          font-size: 1.8rem;

          &:first-child {
            margin-right: 20px;
          }
        }
      }
    }

    .events-carousel-wrap {
      position: relative;
      background-color: white;
      #eventCarousel {
        position: absolute;
        height: 100%;
        width: 100%;
        .carousel-inner {
          .item {
            .item-image {
              display: block;
              position: relative;
              height: 100%;
              .jt-home-event-img {
                display: table;
                position: relative;
                height: 100%;
                width: 100%;
                padding: 50px 0;
              }
            }
            .black-overlay {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background-color: black;
              opacity: 0.5;
            }
            .item-content {
              color: #fff;
              display: table-cell;
              vertical-align: middle;
              position: relative;
              z-index: 1;


              .jt-home-event-bt-wrapper {
                margin-top: 15px;
                .jt-home-event-bt-wrapper-inner {
                  button{
                    background-color: transparent;
                    border-color: $pure-white !important;
                    text-transform: uppercase;
                    font-family: $gt-proxima-soft;
                    font-weight: lighter;
                    font-size: 2rem;
                  }
                }
              }

              .title-text {
                font-family: $gt-soleil;
                font-weight: lighter;
                font-size: 3rem;
              }

              .event-title {
                font-family: $gt-soleil;
                font-weight: bolder;
                font-size: 3rem;
              }

              .event-date {
                font-family: $gt-soleil;
                font-weight: lighter;
                font-size: 2rem;
                font-style: italic;
              }
            }
          }
        }
      }
    }
  }

  .inspiring-bar {
    #inspiringCarousel {
      .carousel-inner {
        .item {
          img {
            width:100%;
          }

          .item-content {
            color: #fff;
            font-weight: normal;
            padding: 220px 0;

            h2 {
              font-family: $gt-lobster;
              font-weight: normal;
              font-size: 5rem;
            }

            p {
              font-family: $gt-soleil;
              font-weight: normal;
              font-size: 1.8rem;
              .italic {
                font-style: italic;
              }
            }

            hr {
              margin-top: 0;
              width: 8%;
            }

            .btn-view-more {
              background-color: transparent;
              border: $pure-white 1px solid;
              font-family: $gt-proxima-soft;
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}
.jt-webapp-prof-pic {
  text-align: center;
  img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }
  .jt-file-camera-img {
    position: absolute;
    opacity: 0;
    width: 41px;
    height: 40px;
    margin: 0 auto;
    left: 0;
    right: 0;
    overflow: hidden;
    border-radius: 26px;

  }
  .jt-file-camera-text {
    position: absolute;
    opacity: 0;
    width: 206px;
    height: 15px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
}


//ADDED BY SAJEE 13-09-2016
.jt-web-welcome-container-section {
  .jt-scrollable-none-more-pad {
    width: 100%;
    padding: 0 0 !important;
    margin: 0 0 !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
  .jt-web-welcome-container {
    float: left;
    top: 0;
    .jt-welc-header {
      width: 100%;
      float: left;
      position: relative;

      .jt-welc-hero-img {
        width: 100%;
        height: 584px;
        position: relative;

        img {
          width: 100%;
          height: auto;
        }
      }

      .jt-welc-header-container {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        padding: 0 50px;

        .inner {
          width: 100%;
          max-width: 1200px;
          margin: 0 auto;
          height: auto;
          position: relative;
        }
      }

      .jt-header-comp-info {
        position: absolute;
        width: 50%;
        height: auto;
        max-height: 100%;
        top: 0;
        left: 0;
        //background: #ebcccc;
        padding: 50px 0px;

        .jt-header-comp-info-texting {
          width: 100%;
          float: left;
          padding: 0 0 25px 0;
          color: white;

          h2 {
            width: 100%;
            float: left;
            margin: 0 0 0 0;
            padding: 0 0 20px 0;
            @include font-size(40);
            text-shadow: 1px 1px 2px #000;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 1.1;

            a {
              color: $white;
            }
          }

          a {
            h2 {
              color: $white;
            }
          }

          p {
            width: 100%;
            float: left;
            margin: 0 0 0 0;
            padding: 0 0 0 0;
            @include font-size(18);
            font-weight: 400;
            line-height: 1.2;
          }
        }

        .jt-header-comp-info-actions {
          width: 100%;
          float: left;
          color: white;

          span {
            padding: 0 10px;
          }
        }
      }

      .jt-welc-header-event-info-strip {
        width: 100%;
        //position: absolute;
        left: 0;
        bottom: 0;
        //background: rgba(0,0,0,0.64);
        padding: 20px 50px;
        background-color: $black;

        .jt-welc-header-event-info {
          width: 100%;
          margin: 0 auto;
          max-width: 1200px;
          padding-right: 158px;
          position: relative;

          .jt-welc-header-event-cal-icon {
            width: 46px;
            float: left;
          }

          .jt-welc-header-event-info-general {
            width: auto;
            overflow: hidden;
            color: white;
            padding-right: 50px;

            h3 {
              width: 100%;
              overflow: hidden;
              clear: both;
              float: left;
              max-width: 100%;
              display: block;
              font-family: $gt-font-QS;
              @include font-size(30);
              @include text-truncate;
              font-weight: 400;
              text-transform: uppercase;
              margin: 0 0 0 0;
              padding: 0 0 0 20px;
              max-height: 62px;
            }

            span {
              clear: both;
              float: left;
              max-width: 100%;
              display: block;
              font-family: $gt-font-QS;
              @include font-size(18);
              @include text-truncate;
              font-weight: 300;
              padding: 0 0 0 20px;
            }
          }
        }

        .jt-welc-header-event-info-button-bg {
          position: absolute;
          width: 158px;
          height: 100%;
          top: 0;
          right: 0;

          .jt-welc-header-event-info-button {
            width: 100%;
            height: 100%;
            display: table;
          }

          .jt-welc-header-event-info-but-inner {
            width: 100%;
            display: table-cell;
            vertical-align: middle;
            text-align: center;

            button {
              margin: 0 auto;
              float: none;
            }
          }
        }
      }
    }

    .no-featured-event {
      .jt-welc-hero-img {
        height: 492px;
      }
      .jt-welc-header-container {
        padding: 0px 50px !important;
      }
      .jt-welc-header-event-info-strip {
        display: none;
      }
    }
  }
  .jt-not-logged-footer {
    position: relative;
    bottom: auto;
    float: left;
  }
}

.footer-inside-scrollable {
  .scrollable-content {
    padding: 0 0 !important;

    .jt-not-logged-footer {
      position: relative;
      bottom: auto;
      float: left;
    }
  }
}

.align-center {
  text-align: center;
}

.jt-page-section-container {
  width: 100%;
  float: left;
  //margin-top: 22px;
  padding: 50px 50px;

  .container {
    max-width: 1200px;
    padding: 0 0;

    .search-form-wrapper {
      width: 100%;
      margin-bottom: 10px;
      .search-form {
        display: inline-block;
        padding: 2px 5px;
        margin: 0px;
        background: $light-pink;
        margin-right: 5px;
        a {
          display: inline-block;
          width: 130px;
          padding: 3px 7.5px;
          text-align: left;
          height: 25px;
          vertical-align: bottom;
          background: white;
          color: black;
        }
  
        input {
          display: inline-block;
          width: 160px;
          height: 25px;
          background: transparent;
          border: 0px;
          padding: 0px 0px 0px 5px;
          vertical-align: bottom;
        }
      }
    }


  }

  .jt-page-section-title {
    width: 100%;
    float: left;
    text-align: center;
    padding-bottom: 50px;

    h3 {
      margin: 0 0;
      // padding: 0 115px;
      position: relative;
      color: $blue-extra-dark;
      text-transform: uppercase;
      font-size: 44pt !important;
      font-family: $h1-large;
      color: $bright-pink;
      font-weight: normal;
      display: inline;
      letter-spacing: 5.575px;
      line-height: 1.3;

      &:before {
        // content: '';
        // width: 100px;
        // height: 5px;
        // position: absolute;
        // top: 50%;
        // left: 0;
        // margin-top: -2.5px;
        // background: $blue-extra-dark;
      }

      &:after {
        // content: '';
        // width: 100px;
        // height: 5px;
        // position: absolute;
        // top: 50%;
        // right: 0;
        // margin-top: -2.5px;
        // background: $blue-extra-dark;
      }
    }
  }

  .jt-page-section-title-sm {
    h3 {
      padding: 0 85px;
      @include font-size(30);

      &:before, &:after {
        width: 70px;
        height: 2px;
        margin-top: -1px;
      }

    }
  }

  .jt-page-section-content {
    width: 100%;
    float: left;
  }
}

.white-container {
  background: white;
}

.gray-container {
  background: #e8e7e7;
}

.blue-container {
  background: $blue;

  .jt-page-section-title {

    h3 {
      color: $white;

      &:before, &:after {
        background: $white;
      }
    }
  }

  .jt-page-section-content {
    color: $white;
  }
}

.jt-home-befits-section {
  width: 100%;
  float: left;
  padding: 0 0px;

  .jt-home-befits {
    //width: 100%;
    //float: left;
    margin: -20px -20px 0 -20px;
    padding: 0 0;
    list-style-type: none;

    .jt-home-befit {
      width: 33.3333%;
      float: left;
      list-style-type: none;
      margin: 0 0;
      padding: 20px 20px;

      &:last-child {
        padding-bottom: 0;
      }

      .jt-home-befit-inner {
        width: 100%;
        float: left;
        display: block;
        //background: beige;
        height: 122px;

        .jt-home-befit-icon {
          width: 115px;
          float: left;

          img {
            width: 100%;
          }
        }

        .jt-home-befit-texting {
          width: auto;
          overflow: hidden;
          padding: 0 0 0 24px;

          h5 {
            width: 100%;
            float: left;
            @include font-size(24);
            font-weight: 400;
            color: $blue-extra-dark;
            line-height: 1.1;
          }

          p {
            width: 100%;
            float: left;
            @include font-size(16);
            font-weight: 500;
            line-height: 1.1;
            color: $black;
          }
        }
      }
    }
  }
}

.jt-events-heading-section {
  text-align: center;
  padding: 97px 10% 0px 10%;
  background: white;
  .jt-events-heading-1 {
    font-size: 44pt;
    margin-bottom: 40px;
    background: -moz-linear-gradient(right, #ff9e51, #e84385);
    background: -webkit-linear-gradient(right, #ff9e51, #e84385);
    background: -ms-linear-gradient(right, #ff9e51, #e84385);
    background: -o-linear-gradient(right, #ff9e51, #e84385);
    background: linear-gradient(right, #ff9e51, #e84385);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: $h1-large;
    letter-spacing: 5.575px;
    line-height: 1.3em;
  }

  .jt-events-heading-2 {
    font-size: 19pt;
    font-family: $regular;
    padding: 0px 2%;
    line-height: 1.3em;
    font-weight: 400;
    max-width: 820px;
    float: none;
    margin: auto;
  }
}

.jt-home-events-section {
  width: 100%;
  float: left;
  padding: 0 0px;

  .jt-home-events {
    width: auto;
    float: none;
    margin: -20px -20px 0 -20px;
    padding: 0 0;
    list-style-type: none;

    .jt-home-event {
      width: 50%;
      float: left;
      list-style-type: none;
      margin: 0 0;
      padding: 20px 20px;
      margin: 20px 0px;
      //min-height: 640px;
      .jt-home-event-inner {
        width: 100%;
        float: left;
        display: block;
        position: relative;
        cursor: pointer;
        height: inherit;

        .jt-home-event-img {
          width: 100%;
          //height: 528px;
          height: 360px;
          float: left;
          position: relative;
          margin-bottom: 20px;
        }

        .jt-home-event-description {
          h1 {
            font-size: 14pt;
            font-family: $regular;
            font-weight: bold;
            //white-space: nowrap;
            //text-overflow: ellipsis;
            //overflow: hidden;
            width: 100%;
          }
          p {
            font-size: 10pt;
            font-family: $regular;
            margin-bottom: 2px;
            //white-space: nowrap;
            //text-overflow: ellipsis;
            //overflow: hidden;
            //min-height: 75px;
          }
        }

        .jt-home-event-button {
          width: 100%;
          border:none;
          border-radius: 0px;
          background: $bright-pink;
          color:white;
          position: absolute;
          //top: 580px;
          font-size: 16pt;
          letter-spacing: 5.575px;
          font-family: $regular;
          bottom: -30px;
        }

        .jt-home-event-button:hover {
          background: $medium-pink;
        }

        .jt-home-event-texting-strip {
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0;
          background: rgba(0,0,0,0.64);
          padding: 25px;

          .jt-home-event-texting-content {
            width: 100%;
            float: left;
            display: block;
            padding-right: 165px;
            position: relative;

            h5 {
              width: 100%;
              float: left;
              @include font-size(28);
              @include text-truncate;
              font-weight: 400;
              color: $white;
              line-height: 1.2;
              max-height: 62px;
              margin-bottom: 5px;
            }

            span {
              width: 100%;
              float: left;
              @include font-size(17);
              font-weight: 500;
              line-height: 1.2;
              color: $white;
            }

            .jt-home-event-bt-bg {
              width: 158px;
              height: 100%;
              position: absolute;
              top: 0;
              right: 0;

              .jt-home-event-bt-wrapper {
                width: 100%;
                height: 100%;
                display: table;

                .jt-home-event-bt-wrapper-inner {
                  width: 100%;
                  display: table-cell;
                  text-align: center;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
    }
  }
}
//ADDED BY SAJEE 13-09-2016


.jt-home-single-event-container {
  padding: 100px 0 0 0;

  .jt-home-events-section {

    .jt-home-events {
      margin: 0 0;

      .jt-home-event {
        width: 100%;
        padding: 0 0 0 0;

        .jt-home-event-inner {

          .jt-home-event-img {
            height: 580px;
          }

          .jt-home-event-texting-strip {
            background: rgba(0,0,0,0.85);
            bottom: auto;
            top: 0;

            .jt-home-event-texting-content {
              float: none;
              max-width: 1200px;
              margin: 0 auto;

              h5 {
                @include font-size(30);
                margin-bottom: 3px;
              }

              span {
              }

              .jt-home-event-bt-bg {

                .jt-home-event-bt-wrapper {

                  .jt-home-event-bt-wrapper-inner {
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.image-section-not-logged-in {
  margin-top: 10px !important;
}

/*
@media screen and (max-width: 768px) {
  .jt-web-welcome-container-section {
    .jt-web-welcome-container {
      top: 0;
    }
  }
}*/

.jt-home-jobs-link {
  position: relative;
  float: right;
  top: 200px;
  right: 100px;
}

.jt-home-jobs-link-small {
  display: none;
  margin-top: 15px;
  clear: both;
  width: 100%;
  float: left;
  text-align: center;
}

.jt-job-link-btn {
  height: 120px;
}

.jt-job-link-btn:hover {
  opacity: 0.8;
}

.jt-home-event-cover-img {
  width: 100%;
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .jt-page-section-container .container {
      width: auto !important;
  }
}

@media (max-width: 1200px) {
  .jt-home-events-section {
    .jt-home-events {
      .jt-home-event {
        .jt-home-event-inner {
          .jt-home-event-button {
            //top: 540px;
          }
        }

        .jt-home-event-inner {
          .jt-home-event-img {
            height: 300px;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .jt-page-section-container .container {
    width: auto;
  }
}

@media screen and (max-width: 564px) {
  .jt-home-slide-item {
    height: auto;
  }

  .jt-web-welcome-container .inspiring-bar #inspiringCarousel .carousel-inner .item .item-content {
    padding: 65px 0;
  }

  .jt-web-welcome-container .inspiring-bar #inspiringCarousel .carousel-inner .item .item-content h2 {
    font-size: 3.5rem;
  }

  .jt-web-welcome-container .inspiring-bar #inspiringCarousel .carousel-inner .item .item-content p {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 512px) {
  .jt-web-welcome-container .app-icons-bar .app-icon-list{
    a {
      img{
        max-width: 30%;
        margin-right: 10px;
      }

    }

  }

  .jt-home-events-section {
    .jt-home-events {
      .jt-home-event {
        .jt-home-event-inner {
          .jt-home-event-img {
            height: 280px;
          }
        }
      }
    }
  }
}

@media screen and (max-width:992px){
  .jt-page-section-container .container {
    max-width: 600px;
    padding: 0 0;
  }

  .jt-home-events-section .jt-home-events .jt-home-event {
    min-height: unset;
    float: none;
    margin: 10px auto 25px;
    display: inline-block;
  }

  .jt-home-events-section .jt-home-events .jt-home-event .jt-home-event-inner .jt-home-event-button {
    top: unset;
  }
}

@media screen and (max-width: 991px) {
  .jt-web-welcome-container .view-bar h2 {
    font-size: 2.5rem;
  }

  .jt-web-welcome-container .flex-row-nowrap {
    display: block;
  }

  .jt-web-welcome-container .event-bar .events-carousel-wrap #eventCarousel {
    position: relative;
  }
}

@media screen and (max-width: 768px) {
  .jt-page-section-container {
    margin-top: 0;
  }

  .jt-web-welcome-container .events-carousel-wrap {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .jt-web-welcome-container .jt-featured-event {
    max-width: 500px !important;
  }

  .jt-web-welcome-container .jt-featured-event .jt-home-event-img {
    height: 250px !important;
}
}

@media screen and (max-width:450px) {
  .jt-web-welcome-container .jt-featured-event .jt-home-event-img {
    height: 150px !important;
  }
  .jt-web-welcome-container .jt-featured-event {
    max-width: 300px !important;
  }
}

@media screen and (max-width: 568px) {
  .jt-web-welcome-container .view-bar h2 {
    font-size: 2.5rem;
  }

  .jt-web-welcome-container .inline-login .inline-login-container .inline-login-inner {
    width: 100%;
  }

  .jt-events-heading-section {
    .jt-events-heading-1 {
      font-size: 30pt;
      line-height: 1.1em;
      margin-bottom: 25px;
    }
  }

  .jt-single-event-page-header {
    .jt-selected-event-wrapper {
      .jt-featured-event {
        h1 {
          font-size: 30pt;
          line-height: 1.1em;
          margin-bottom: 25px;
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {

  .jt-web-welcome-container .view-bar h2 {
    font-size: 1.8rem;
  }

  .jt-web-welcome-container .event-bar .making-difference-wrap p {
    text-align: justify;
  }

  .jt-web-welcome-container .event-bar .making-difference-wrap h3 {
    font-size: 3.2rem;
  }

  .jt-web-welcome-container .event-bar .making-difference-wrap p {
    font-size: 1.5rem;
  }

  .jt-web-welcome-container .event-bar .making-difference-wrap .button-row button {
    width: 120px;
    font-size: 1.5rem;
  }

  .jt-web-welcome-container .event-bar .events-carousel-wrap #eventCarousel .carousel-inner .item .item-content {
    .title-text, .event-title {
      font-size: 2.5rem;
    }
    .event-date {
      font-size: 1.8rem;
    }
  }

  .jt-web-welcome-container .event-bar .events-carousel-wrap #eventCarousel .carousel-inner .item .item-content .jt-home-event-bt-wrapper .jt-home-event-bt-wrapper-inner button {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 400px) {
  .jt-home-events-section {
    .jt-home-events {
      .jt-home-event {
        .jt-home-event-inner {
          .jt-home-event-button {
            //top: 515px;
          }

          .jt-home-event-img {
            height: 230px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .jt-web-welcome-container .event-bar .making-difference-wrap .button-row button {
    width: 105px;
    font-size: 1.3rem;
  }
}