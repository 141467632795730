.jt-event-selector-box {
  width: 100%;
  .jt-event-selector {
    button {
      width: 50%;
      float: left;
      background-color: $blue;
      height: 38px;
      border-radius: 0;
      color: $white;
      font-family: $gt-font-QS;
      padding: 8px 7px !important;
    }
    button.jt-secondary-btn-color {
      background-color: $blue-extra-dark;
    }
  }
}

.jt-table-ticket-label {
  margin-left: 10px;
}

.jt-list-connect-msg-btn {
  float: right;
  margin-top: -56px;
  z-index: 9999;

  .jt-modal-connect-btn {
    padding: 4px 10px;
    min-width: 70px;
    min-height: 36px;
  }
}

.jt-event-list {
  width: 100%;
  word-wrap: break-word;
  margin-top: 10px;
  a {
    text-decoration: none;
  }
  address {
    text-decoration: none;
  }
  .jt-event {
    width: 100%;
    border: 1px solid $light-gray;
    margin-bottom: 14px;
    float: left;
    position: relative;
    .jt-event-loading {
      height: 100%;
      width: 100%;
      background-color: $black;
      position: absolute;
      z-index: 2;
      opacity: 0.8;
      color: $white !important;
    }
    h5 {
      color: $black;
      margin-bottom: 5px;
    }
    p {
      color: $gray;
      margin-bottom: 5px;
    }
    .jt-event-list-image {
      width: 100%;
      height: auto;
      img {
        width: 100%;
        height: auto;
        padding-bottom: 10px;
      }
    }
    .jt-event-list-header {
      padding-bottom: 3px !important;
      line-height: 1;
      p,
      h5 {
        padding: 0px 10px;
      }
      padding-bottom: 50px;
      margin-bottom: -3px;
      .info-para {
        line-height: 1.3;
      }
    }
    .jt-event-list-details {
      p {
        padding: 0px 10px;
        margin-top: -10px;
      }
    }
    .jt-eventlist-attend-selector {
      width: 100%;
      float: left;
      position: relative;
      z-index: 1;
      margin-bottom: -8px;
      button {
        width: 50%;
        float: left;
        background-color: $blue;
        height: 38px;
        border-radius: 0;
        margin-bottom: 8px;
        color: $white;
        padding: 8px 7px !important;
        font-family: $gt-font-QS;
        border: 1px solid $blue;

      }
      button.jt-secondary-btn-color {
        background-color: $blue-extra-dark;
        border: 1px solid $blue-extra-dark;
      }

      button.jt-btn-seperator {
        border-right: 1px solid $white;
      }
    }
  }
  .jt-event-list-attendees {
    padding-bottom: 20px;
  }
}

.line-seperator {
  margin-top: 0;
  margin-bottom: 7px;
  width: 100%;
}

.white {
  color: $white;
}

.event-loader {
  @extend .secondary-loader;
  position: relative;
  margin-top: 32%;
}

.jt-event-item {
  border:1px solid $gray-lighter;
  clear: both;
  margin-bottom: 15px;
  @include border-radius(2px);
}

button.jt-btn-seperator {
  border-right: 1px solid $white;
}

.jt-break-btn {
  height:52px;
}

.jt-eventlist-h6 {
  @include font-size(15);
}

.jt-pay-now-button{
  width: 100% !important;
  @include font-size(16);
  font-weight: bold;
}