/*!
 * Start Bootstrap - Simple Sidebar HTML Template (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

/* Toggle Styles */

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 250px;
}

#sidebar-wrapper {
  z-index: 1000;
  z-index: 500;
  //position: fixed;
  //position: absolute;
  //left: 250px;
  width: 0;
  height: 100%;
  //margin-left: -250px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: #000;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

/* Sidebar Styles */

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  text-indent: 20px;
  line-height: 40px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #999999;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #fff;
  background: rgba(255,255,255,0.2);
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
  /*height: 65px;*/
  font-size: 18px;
  /*line-height: 60px;*/
}

.sidebar-nav > .sidebar-brand a {
  color: #999999;
  transition: background-color 3s ease;
}

.sidebar-nav > .sidebar-brand a:hover {
  /*color: #fff;*/
  background: none;
  transition: background-color 3s ease;

}

@media(min-width:768px) {
  #wrapper {
    //padding-left: 250px;
  }

  #wrapper.toggled {
    padding-left: 250px;
  }

  #sidebar-wrapper {
    width: 250px;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 250px;
  }

  #page-content-wrapper {
    position: relative;
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}

@media(max-width:768px) {
  #wrapper {
    padding-left: 0px;
  }

  #sidebar-wrapper {
    width: 0px;
  }

  #wrapper.toggled {
    padding-left: 250px;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 250px;
  }

  .jt-webapp-header {
    left: 264px;
  }
}

@media all and (max-width:768px) and (min-width: 481px) {
  .jt-webapp-header {
    left: 265px !important;
  }
}

@media all and (max-width: 480px) {
  .jt-webapp-mobile-logo {
    width: 63px;
  }

  .jt-rest-logo {
    display: none;
  }

  .jt-top-nav-holder .jt-top-nav .jt-top-nav-nav {
    padding-right: 62px;
  }
}