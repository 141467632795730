input[type="text"], input[type="email"], input[type="tel"], input[type="password"], input[type="url"], input[type="number"], input[type="date"] {
  padding: 8px 15px;
  min-height: 20px;
  width: 100%;
  @include css3-prefix('appearance', none);
  @include font-size(14);
  margin: 0;
  @include border-radius(4px);
  border: 1px solid #ccc;
  @include text-truncate;
}

textarea {
  padding: 8px 15px;
  min-height: 30px;
  border: none;
  width: 100%;
  @include css3-prefix('appearance', none);
  @include font-size(14);
  margin: 0;
  @include border-radius(4px);
  resize: vertical;
}

input[type="submit"], input[type="button"] {
  padding: 10px 15px;
  min-height: 20px;
  border: none;
  width: 100%;
  @include css3-prefix('appearance', none);
  @include font-size(14);
  margin: 0;
  @include border-radius(4px);
}

select {
  padding: 8px 15px;
  min-height: 20px;
  border: none;
  width: 100%;
  @include css3-prefix('appearance', none);
  @include font-size(14);
  margin: 0;
  @include border-radius(4px);
  height: 40px;

  option {
    padding: 6px 15px;
  }
}

.jt-input-addon {
  height: 38px;
}

.jt-on-off-switch {
  float: right;
  padding-bottom: 3px;
  .onoffswitch {
    position: relative;
    width: 90px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  .onoffswitch-checkbox {
    position: absolute;
    z-index: 2000;
    width: 60px;
    padding: 8px 8px;
    height: 60px;
    top: -15px;
    right: 15px;
    min-height: 0;
    min-width: 0;
    opacity: 0
  }
  .onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 0px;
  }
  .onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }
  .onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    line-height: 26px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
    border: 1px solid $gray;
    background-clip: padding-box;
    border-radius: 5px;
    padding-top: 2px;
  }
  .onoffswitch-inner:before {
    content: "User";
    padding-left: 10px;
    background-color: #FFFFFF;
    color: #0086ED;
  }
  .onoffswitch-inner:after {
    content: "Brand";
    padding-right: 10px;
    background-color: #FFFFFF;
    color: #0086ED;
    text-align: right;
  }
  .onoffswitch-switch {
    display: block;
    width: 25px;
    margin: 0px;
    background: #0086ED;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 65px;
    transition: all 0.3s ease-in 0s;
  }
  .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
  }
  .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
  }
}

textarea.jt-extended-text-area {
  height: 150px;
}

.jt-no-pad {
  padding-left: 0;
  padding-right: 0;
}

.jt-no-pad.jt-separator-right {
  padding-right: 3px;
}

.jt-no-pad.jt-separator-left {
  padding-left: 3px;
}

select.jt-welcome-select {
  height: 34px;
  width: 100%;
  option {
    background-color: $white;
    color: $blue-extra-dark;
    font-weight: 500;
  }
  option:hover {
    background-color: $blue-extra-dark;
    color: $white;
  }
}

.jt-error-show {

  select {
    margin-bottom: 0px;
  }

  .jt-error-div {
    position: relative;
    top: -4px;

    p {
      background: #e85858;
      color: white;
      padding: 2px 5px 2px 5px;
      position: relative;
      font-size: 12px;
      border: 1px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }

  }

  .jt-error-div-no-margin {

    p {
      background: #e85858;
      color: white;
      padding: 2px 5px 2px 5px;
      font-size: 12px;
      position: relative;
      border: 1px;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
}

.jt-home-login-field, .jt-home-login-field:focus {
  background: #fff;
}

.jt-home-login-field:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}