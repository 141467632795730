.jt-search-template-box {
  padding: 12px;
  .jt-search-container {
    border: 1px solid $light-gray;
    clear: both;
    float: left;
    width: 100%;
    margin-bottom: 12px;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;
      li {
        padding: 0;
        margin: 0;
        width: 100%;
      }
    }
  }
  .jt-search-events-box {
  }
  .jt-search-people-box {
  }
  .jt-search-brands-box {
  }
  .jt-search-interests-box {
  }
  .list-group-item {
    width: 100%;
    padding: 10px 10px;
    float: left;
    position: relative;
    .connect-list-image {
      width: 70px;
      float: left;
      img {
        width: 70px;
        height: 70px;
        border: 3px solid $blue-light;
        border-radius: 150px;
      }
    }
    .connect-list-name {
      width: 63%;
      float: left;
      padding-left: 16px;
      padding-top: 3px;
      p {
        @include text-truncate();
        font-size: 1.5rem;
      }
    }
    .connect-list-name-secondary {
      width: 77%;
      float: left;
      //padding-left: 16px;
      position: absolute;
      top:50%;
      transform: translate(0%,-50%);
      -ms-transform: translate(0%,-50%);
      //padding-top: 22px;
      p {
        @include text-truncate();
        font-size: 1.5rem;
        margin-bottom: 0;
        margin-left: 80px;
      }
    }
    .jt-connect-job-details {
      p {
        @include font-size(12);
        line-height: 1.2;
        color: $gray;
        margin-bottom: 2px;
        @include text-truncate();
      }
    }
    .connect-list-edge {
      width: 20px;
      float: right;
      padding-top: 25px;
      padding-right: 5px;
    }
    .connect-list-edge-scn {
      width: 20px;
      float: right;
      padding-top: 25px;
      padding-right: 5px;
      text-align: right;
      font-size: 1.7rem;
    }
    .connect-list-time {
      clear: both;
      float: left;
      width: 70%;
      margin-left: 87px;
      margin-top: -40px;
      margin-bottom: 16px;
    }
    .connect-list-place {
      float: left;
      width: 63%;
      margin-left: 87px;
      margin-top: -18px;
    }
  }
}

.search-box-header {
  background-color: $blue;
  color: $white;
  width: 100%;
  height: 30px;
  padding-top: 4px;
  padding-left: 12px;
  clear: both;
  border: 1px solid $blue;
}

.search-more-btn {
  background-color: $white;
  width: 100%;
  height: 40px;
  padding-top: 9px;
  clear: both;
  padding-left: 12px;
  font-size: 1.5rem;
}