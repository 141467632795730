.jt-following-not-add {
  color: $following !important;

}

//Component Box
.jto-component-box {
  background: #fff;
  padding: 25px;
  border: 1px solid;
  border-color: #e5e6e9 #dfe0e4 #d0d1d5;
  //border-radius: 3px;
  @include border-radius(2px);
  margin-bottom: 15px;

  h1 {
    color: $main-blue;
    line-height: 1.2
  }

  h3 {
    margin-top: 0;
    color: #2a78e3;
    font-size: 14px;
    font-weight: bold;
  }

  ul li {
    font-size: 1.3rem;
  }

  label.jto-checkbox {
    font-weight: normal;
    cursor: pointer;
    color: #333;
    display: inline-block;
    margin-bottom: 15px;
    font-size: 13px;
    position: relative;
    padding-left: 20px;
    margin-top: 20px;
  }

  label.jto-checkbox input {
    display: none;/* <-- hide the default checkbox */
  }

  label.jto-checkbox span {/* <-- style the artificial checkbox */
    height: 15px;
    width: 15px;
    border: 1px solid #aaa;
    display: inline-block;
    position: absolute;
    top: 1px;
    left: 0;
  }

  [type=checkbox]:checked + span {
    border: 1px solid #727272;
  }

  [type=checkbox]:checked + span:before {/* <-- style its checked state..with a ticked icon */
    content: "\f00c";
    font: normal normal normal 14px/1 FontAwesome;
    position: absolute;
    top: -1px;
    left: 0;
    color: #727272;
  }

  select{
    width: 100%;
    height: 44px;
    line-height: 36px;
  }

  .m-btm {
    margin-bottom: 15px;
  }

  .m-top {
    margin-top: 15px;
  }
}

//job dashboard
.jto-search-result {
  color: #333;

  h2 {
    margin: 0;
    padding: 0;
    font-weight: 700;
    font-size: 24px;
    padding-bottom: 5px;

    a {
      color: #333;
      line-height: 1.4;
      word-wrap: break-word;

      &:focus {
        text-decoration: none;
      }
    }
  }

  .jto-save-mail-box{
    text-align: right;
    position: relative;
    top: -10px;

    button {
      border: 0;
      background: none;
      padding: 0;
    }

    i {
      font-size: 20px;
      margin-left: 10px;
      color: $main-blue;
    }
  }

  .jto-details {

    .jto-item {
      position: relative;
      padding-left: 20px;
      font-size: 15px;
      line-height: 1.7;
      margin-bottom: 8px;

      i {
        position: absolute;
        left: 0;
        top: 2px;
        color: $main-blue;
        font-size: 15px;
        line-height: 1.7;
      }
    }
  }

  .jto-description {
    font-size: 15px;
    border-left: 1px solid $light-gray;
    line-height: 1.7;

    ul {
      padding-left: 15px;
    }

    ul li {
      font-size: 15px;
      line-height: 1.7;
    }
  }

  .jto-job-footer {
    margin-top: 5px;
    font-weight: 700;
    font-size: 15px;
    line-height: 1.7;
  }

  &.jto-stand-out {
    background: #DFF2FF;
    box-shadow: inset 0px 0px 1px 2px $main-blue;

    .jto-stand-out-lbl {
      background: url("/assets/app/images/Stand-Out_Label-33.svg") no-repeat;
      background-position: right center;
      height: 40px;
      color: #fff;
      font-size: 12px;
      text-align: center;
      right: -24px;
      position: absolute;
      padding: 3px 0;
      background-size: 100px;
      width: 100px;
      top: 0;
      font-weight: 700;
    }

    .jto-btn-search-result {
      margin-top: 40px;
    }

    .jto-save-mail-box {

      .jto-action-btn-contain {
        position: relative;
        top: 40px;
      }
    }

    h2 {
      a {
        color: $main-blue;
      }
    }
  }
}

.jto-search-card-image-container {
  min-height: 40px;
}

.jto-search-result-logo {
  max-width: 100px;
  float: right;
}

.jt-profile-container {
  .jt-detail-container {
    min-height: 110px;
    border-radius: 0;
    .brand-highlight {
      margin-top: -6px;
      font-size: 1.5rem;
      .bold-count {
        font-weight: bold;
      }
      padding: 1px 1px;
      .followers-count {
        float: left;
        text-align: center;
        padding-right: 13px;
      }
      .events-count {
        float: left;
        text-align: center;
      }
    }
  }
  .jt-brand-posts {
    margin: 0 10px;
    padding: 0;
    margin-bottom: 10px;
    .brand-posts-heading {
      text-align: center;
    }
    .jt-time-line-container {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 5px;
      .jt-post-container {
        .jt-post-btn-container {
          button {
            height: 44px;
          }
        }
      }
    }
  }
  .jt-brand-albums {
    margin: 0;
    padding: 0 8px;
    margin-bottom: 10px;
    clear: both;
    ul {
      width: 100%;
      padding: 0 0;
      float: left;
      margin-bottom: 18px;
      margin-top: 0;
    }
    ul li {
      display: inline-block;
      width: 20%;
      padding: 0 3px;
      float: left;
      .gallery-image {
        width: 100%;
        height: 100%;
        padding: 44% 0%;
        background-color: $white;
        background-size: cover;
      }
    }
    ul li img {
      width: 100%;
      border: solid 1px $blue-light;
      background-color: $white;
    }
    .brand-album-heading {
      text-align: center;
      padding-top: 10px;
    }
  }
  .jt-write-post-content {
    padding: 0px;
  }
  .brand-about-description {
    .brand-about-des-content {
      padding: 10px;
      padding-top: 44px;
      padding-bottom: 15px;
      p {
        padding-top: 78px
      }
      .fa {
        padding-right: 9px;
      }
      a {
        text-decoration: none;
        color: $gray;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 1.5rem;
      }
    }
  }
  button {
    height: 44px;
  }
}

.feed-view-bg-gray {
  background-color: $gray-lighter;
}

.jt-brand-profile-container {
  .jt-detail-container {
    padding: 12px 0px 47px 10px;
    margin-bottom: 7px;
    border-radius: 0;
  }

}

.jt-btn-clear-bottom {
  clear: both;
  margin-bottom: 15px;
  font-size: 14px;
}

.jt-brand-tagline {
  clear: both;
  float: left;
}

.jt-brand-tag {
  padding-top: 6px;
}

@media all and (max-width: 767px) {
  .jto-search-result {
    .jto-description {
      border-left: none;
      line-height: 1.4;
      font-size: 14px;

      ul {
        li {
          font-size: 14px;
          line-height: 1.3;
        }
      }
    }

    &.jto-stand-out {
      .jto-save-mail-box {
        margin: 10px 0;
        text-align: left;

        .jto-action-btn-contain {
          top: 5px;
        }
      }

      .jto-btn-search-result {
        margin-top: 15px;
        margin-bottom: 10px;
      }
    }

    .jto-save-mail-box {
      margin: 10px 0;
      text-align: left;
    }

    .jto-btn-search-result {
      margin-top: 15px;
      margin-bottom: 10px;
    }
  }
}

@media all and (max-width: 550px) {
  .jto-job-sectors {
    width: 100% !important;
    text-align: center;
    margin: 3px 0;

    img {
      float: none !important;
      margin: 0 auto;
    }
  }
}