//Fonts
@import url("https://use.typekit.net/jml4rgr.css");

// Font Variables
$h1-large: "anton",sans-serif;
$regular: "proxima-nova", sans-serif;

.blue-background {
  //background-color: $bright-jc-blue;
  background-color: #fff;
}

.jt-header {
  background-color: $blue;
  color: $white;
  height: 108px;
  .jt-header-logo {
    text-align: center;
    padding-top: 23px;
    text-transform: uppercase;
    float: left;
    width: 100%;
    h6 {
      font-weight: 100;
    }
    h2 {
      line-height: 0.5;

    }
  }
  .jt-header-logo-padding {
    padding-top: 14px;
  }
  .jt-header-search-area {

    -moz-box-shadow: 0px 0px 13px 0px $white;
    -webkit-box-shadow: 0px 0px 13px 0px $white;
    box-shadow: 0px 0px 13px 0px $white;

    position: fixed;
    top: 79px;
    width: 100%;
    left: 0;
  }
  .jt-header-search {
    background: url(#{$gt-images}search-icon.png) no-repeat center;
    width: 15px;
    height: 15px;
    float: right;
    margin-top: 5px;
    margin-right: 5px;
    clear: right;
  }
  .jt-header-search-area input {
    border: 0;
    width: 100%;
    height: 30px;
    background-color: $blue;
    clear: left;
    color: $white;
    font-family: FontAwesome, $gt-font-QS;
    border-radius: 0;
    .jt-header-search-area-icon {
      background: url(#{$gt-images}search-icon.png) no-repeat center;
      margin-top: 4px;
      width: 15px;
      height: 15px;
      float: left;
      margin-left: 12px;
    }
    .jt-header-search-area-text {
      margin-left: 5px;
    }
  }
  jt-header-search-area input[placeholder],
  [placeholder],
  *[placeholder] {
    color: $white !important;
  }
  ::-webkit-input-placeholder {
    color: $white;
  }
  :-moz-placeholder {
    color: $white;
    opacity: 1;
  }
  ::-moz-placeholder {
    color: $white;
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: $white;
  }
  :placeholder-shown {
    color: $white;
  }
}

.jto-top-bar {
  padding-left: 50px;
}

.pro-img {
  top: 0;
}

.pro-img-log {
  top: auto;
}

/** Navbar new design **/
#jt-nd-navbar {
  background: -moz-linear-gradient(right, #ff9e51, #ff8cc5);
  background: -webkit-linear-gradient(right, #ff9e51, #ff8cc5);
  background: -ms-linear-gradient(right, #ff9e51, #ff8cc5);
  background: -o-linear-gradient(right, #ff9e51, #ff8cc5);
  background: linear-gradient(right, #ff9e51, #ff8cc5);
  color: #FFFFFF;
  position: fixed;
  width: 100%;
  z-index: 99;
  padding: 38px 0;

  .jt-nd-navbar-container {
    max-width: $max-width;
    margin: 0 auto !important;
    float: none !important;
    padding: 0;
    display: block;

    .jt-nd-left-box {
      margin: 0;
      padding: 0;
      //display: table-cell;
      vertical-align: middle;

      .jt-nd-name-wrapper {
        float: left;
        margin-right: 12px;
        min-width: 100%;
        text-align: center;

        h2 {
          line-height: 0.8;
          img {
            height: 32px;
            cursor: pointer;
          }
        }
      }

      .jt-nd-search-wrapper {
        margin: 0;
        float: left;
        width: 280px;

        #search-input {
          padding-left: 30px;
        }
        #search-icon {
          height: 36px;
          color: gray;
          position: absolute;
          padding: 10px 10px;
        }
      }
    }

    .jt-nd-right-box {
      float: right;
      display: block;
      height: 39px;
      margin-top: -4px;
      .jt-nd-nav-main {
        position: relative;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        background: 0 0;
        width: auto;
        z-index: 100;
        overflow: visible;
        margin: 0 0 0 auto;
        list-style: none;
        display: block;
        //table-layout: fixed;
        padding-left: 0;
        float: left;
        background: white;
        height: 100%;
        .nav-item {
          display: block;
          text-align: center;
          padding: 0 26.5px;
          float: left;
          margin-top: 5px;

          &.active {
            .nav-item-link {
              //color: $slategray;

              .in-active {
                display: none !important;
              }

              .active {
                display: block !important;
              }
            }
          }

          .nav-item-link:hover {
            .in-active {
              display: none !important;
            }

            .active {
              display: block !important;
            }

            /*.nav-item-title {
              color: $slategray;
            }*/
          }

          .nav-item-link {
            color: black;
            touch-action: manipulation;
            text-decoration: none;
            text-align: center;
            height: 100%;
            line-height: 12px;
            position: relative;
            display: block;
            background-color: transparent;
            border: 0;
            margin: 0;
            font-size: 9pt;
            background: 0 0;
            cursor: pointer;

            .nav-item-badge {
              min-width: 18px;
              top: -10px;
              left: 70%;
              height: 18px;
              line-height: 18px;
              background-color: $red;
              border-radius: 14px;
              z-index: 1;
              position: absolute;
              font-size: 12px;
              font-weight: 400;
              padding: 0 4px;
            }

            .jt-navabr-text {
              .active {
                display: none;
              }
            }

            .nav-item-icon {
              color: $white;
              transition-duration: 167ms;
              transition-timing-function: cubic-bezier(.4, 0, .2, 1);
              transition-delay: 0s;
              display: inline-block;
              opacity: 1;
              position: relative;
              left: auto;
              top: auto;
              margin-left: 0;
              -webkit-clip-path: none;
              clip-path: none;
              -webkit-transform: scale(1);
              transform: scale(1);

              .nav-item-icon-img {
                height: 30px;
                width: auto;
                display: block;
              }

              .active {
                display: none;
              }

              .profile-pic {
                border-radius: 100% !important;
                height: 30px;
                display: block;
              }

              .profile-pic-a {
                border-radius: 100% !important;
                height: 30px;
                display: none;
              }

            }



            .nav-item-title {
              line-height: 16px;
              font-weight: 400;
              font-size: 12px;
              display: block;
              transition: color .3s;
              margin-top: 8px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              font-family: $regular;
            }
            .user-name {
              max-width: 86px;
            }

            .dropdown-icon {
              position: absolute;
              top: 40px;
              right: -8px;
            }
          }
        }
      }

      .profile-container {
        height: 53px;
        .profile-wrap:hover {
          .nav-item-icon {
            .profile-pic {
              display: none;
            }
            .profile-pic-a {
              display: block;
            }
          }
        }
      }
    }
    ul {
      list-style-type: none;
    }

    .profile-dropdown-options {
      margin-right: calc(50% - 24px);
      max-height: 480px;
      overflow-y: auto;
      background-color: $pure-white;
      background-clip: padding-box;
      border-radius: 2px;
      transition: box-shadow 83ms;
      margin: 8px 0 0;
      padding: 4px 0 0;
      width: 288px;
      right: 0;
      left: auto;
      text-align: left;
      box-shadow: 0 4px 8px rgba(0, 0, 0, .15);
      position: absolute;
      z-index: 1000;

      .nav-settings-topcard {
        background: #fff;
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        font-size: 100%;
        vertical-align: baseline;
        height: 100px;

        .nav-settings-profile-link {
          display: block;

          .nav-settings-member-photo-container {
            display: block;
            float: left;
            height: 102px;
            margin: 0;
            padding: 16px 12px 16px 24px;
            position: relative;
            width: 106px;

            .nav-settings-member-photo {
              box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
              border: 2px solid #fff;
              border-radius: 100%;
              display: inline;
              height: 70px;
              width: 70px;
              margin: 0;
              padding: 0;
              overflow: hidden;
              outline: 0;
              font-size: 100%;
              vertical-align: baseline;
              background: 0 0;
            }
          }

          .nav-settings-member-info-container {
            display: inline-block;
            margin-top: 32px;
            padding-right: 8px;
            position: relative;
            max-width: 166px;
            margin: 0;
            padding: 0;
            border: 0;
            outline: 0;
            font-size: 100%;
            vertical-align: baseline;
            background: 0 0;

            .nav-settings-member-name {
              font-weight: 600;
              color: $black;
              font-size: 17px;
              line-height: 24px;
              margin-bottom: 0;
              margin-top: 20px;
            }

            .nav-settings-view-profile-link {
              color: $blue-extra-dark;
              font-weight: 600;
              font-size: 13px;
              line-height: 16px;
            }

          }

        }
      }
      .nav-settings-dropdown-options-account {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        font-size: 100%;
        vertical-align: baseline;
        background: 0 0;
      }
    }

    .nav-settings-dropdown {
      ul {
        margin: 0;
        padding: 0;
      }

      .separator {
        margin: 10px 0;
        border-top: 1px solid $dark-ash;
      }
    }
    .nav-dropdown-item {
      padding: 3px 24px;
      position: relative;
      line-height: 20px;
      font-weight: 600;
      font-size: 15px;

      a {
        color: $black;
        text-decoration: none;
        font-weight: 700;
        background-color: transparent;
        border: 0;
        margin: 0;
        font-size: 9pt;
        background: 0 0;
        font-family:$regular;
      }
    }

    li {
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      font-size: 100%;
      vertical-align: baseline;
      background: 0 0;
    }

    .nav-settings-dropdown-title {
      text-transform: uppercase;
      background: $light-gray;
      line-height: 24px;
      padding: 0 12px;
      color: $black;
      font-weight: 600;
      font-size: 15px;
      border-top: 1px solid $dark-ash;
      margin-top: 10px;
    }

    .nav-settings-dropdown-logout {
      margin-bottom: 10px;
    }
  }
}

.not-loggedin-profile-container:hover {
  .nav-item {
    .nav-item-link {
      .nav-item-icon {
        .nav-item-title {
          color: $slategray;
        }
        .profile-pic {
          display: none !important;
        }
        .profile-pic-a {
          display: block !important;
        }
      }
    }
  }
}

.search-button-wrap {
  text-align: right;
  padding-right: 2px;
  display: block;
}

/*Sidenav*/
.sidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  background: -moz-linear-gradient(right, #ff9e51, #ff8cc5);
  background: -webkit-linear-gradient(right, #ff9e51, #ff8cc5);
  background: -ms-linear-gradient(right, #ff9e51, #ff8cc5);
  background: -o-linear-gradient(right, #ff9e51, #ff8cc5);
  background: linear-gradient(right, #ff9e51, #ff8cc5);
  overflow: hidden;
  padding-top: 60px;
  transition: 0.5s;
  box-shadow: 12px 19px 17px -15px #000;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 14px;
    background: #f9f9f9;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b1b1b1;
    outline: 1px solid #708090;
    border-radius: 50px;
    border: 3px solid #f9f9f9;
  }

  .nav-item-icon-img {
    width: 28px;
    margin-right: 20px;
  }

  .profile-pic {
    border-radius: 100% !important;
  }

  hr {
    margin: 10px 0 0 0;
    border-top-color: $light-gray;
  }

  .nofication-icon {
    background: $red;
    border-radius: 100%;
    position: absolute;
    text-align: center;
    margin-left: 10px;
    vertical-align: middle;
    top: 10px;
    left: 32px;
    height: 18px;
    width: 20px;
    z-index: 1;
    font-size: 12px;
    font-weight: 400;
    padding: 0 4px;
  }

  .footer-links {
    width:100%;
    text-align:center;
    display: flex;
    padding: 0 20px;
    margin-top: 10px;

    a {
      display: block;
      font-size: 1rem;
      padding: 0;
    }

    .left {
      float:left;
    }

    .center {
      display: inline-block;
      margin:0 auto;
    }

    .right {
      float:right;
    }
  }
}

.sidenav {
  .active-item {
    /*.item-text {
      color: $slategray;
    }*/

    .active {
      display: inline-block;
    }

    .in-active {
      display: none;
    }
  }

  a {
    padding: 20px 20px 0 20px;
    text-decoration: none;
    font-size: 1.5rem;
    color: $pure-white;
    display: block;
    transition: 0.3s;
    position: relative;
    white-space:nowrap;

    img{
      height:100%;
    }
    .active {
      display: none;
    }

    .in-active {
      display: inline-block;
    }
  }

  a:hover{
    /*.item-text {
      color: $slategray;
    }*/

    .active {
      display: inline-block;
    }

    .in-active {
      display: none;
    }
  }
}

.sidenav a:hover, .offcanvas a:focus {
  color: $white;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 36px;
  margin-left: 50px;
}

#main {
  transition: margin-left .5s;
  padding: 20px;
}

#write-post-box {
  display: block;
  position: relative;
  width: 100%;
  background: white;
  padding-top: 10px;
  //border-radius: 10px;
  @include border-radius(2px);

  .write-box {
    display: block;
    position: relative;
    min-height: 55px;
    font-size: 1.6rem;
    resize: none;
    overflow: hidden;
  }

  /*[contenteditable=true]:empty:before{
    content: attr(placeholder);
    display: block;
    min-height: 55px;
    font-size: 1.6rem;
  }*/

  .article-write-button-bar {
    margin: 0 15px;

    .article-write-button {
      background-color: #0275ce;
      color: white;
      float: left;
    }

    .upload-wrap {
      display: block;
      position: relative;
      float: left;

      .jt-file-camera {
        display: inline-block;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 10;
      }

    }

    #article-post-button, #jt-writePost-btn-post {
      float: right;
    }

    .image-upload-icon {
      height: 38px;
      display: inline-block;
      position: relative;
      margin-left: 15px;
    }
  }
}

.mobile-postbox-wrapper {
  display: none;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

.mobile-profile {
  display: none;
}

@media screen and (max-width: 1200px) {
  #jt-nd-navbar .jt-nd-navbar-container .jt-nd-left-box .jt-nd-search-wrapper {
    width: 192px;
  }

  #jt-nd-navbar .jt-nd-navbar-container .jt-nd-right-box .jt-nd-nav-main .nav-item {
    //width: 84px;
  }

  #jt-nd-navbar .jt-nd-navbar-container .jt-nd-right-box .jt-nd-nav-main .nav-item .nav-item-link .nav-item-title {
    font-size: 11px;
  }
}

@media screen and (max-width: 992px) {

  #jt-nd-navbar .jt-nd-navbar-container {
    width: 100%;
    padding: 0 30px;

    .jt-nd-left-box {
      .jt-nd-name-wrapper {
        h2 {
          img {
            height: 26px;
            cursor: pointer;
          }
        }
      }
    }
  }

  #jt-nd-navbar .jt-nd-navbar-container .jt-nd-left-box .jt-nd-search-wrapper {
    //width: 150px;
  }

  #jt-nd-navbar .jt-nd-navbar-container .jt-nd-right-box .jt-nd-nav-main .nav-item {
    //width: 72px;
    height: 100%;
    padding: 0 12px;
  }

  #jt-nd-navbar .jt-nd-navbar-container .jt-nd-right-box .jt-nd-nav-main .nav-item .nav-item-link .nav-item-title {
    font-size: 9px;
  }

  #jt-nd-navbar .jt-nd-navbar-container .jt-nd-right-box .profile-container .nav-item .nav-item-link .nav-item-title {
    margin: 3px 0 0 0;
  }

  #jt-nd-navbar .jt-nd-navbar-container .jt-nd-right-box .profile-container .nav-item .nav-item-link .user-name {
    max-width: 50px;
  }

  #jt-nd-navbar .jt-nd-navbar-container .jt-nd-right-box .profile-container .nav-item .nav-item-link .dropdown-icon {
    top: 34px;
  }

  #jt-nd-navbar .jt-nd-navbar-container .jt-nd-right-box .profile-container {
    height: 50.4px;
  }

  #jt-nd-navbar .jt-nd-navbar-container .jt-nd-right-box .jt-nd-nav-main .nav-item .nav-item-link .nav-item-icon .nav-item-icon-img {
    height: 25px;
  }
}

@media screen and (max-width: 890px) {
  #jt-nd-navbar .jt-nd-navbar-container .jt-nd-left-box .jt-nd-search-wrapper {
    width: 150px;
  }
}

@media screen and (max-width: 850px) {
  #jt-nd-navbar .jt-nd-navbar-container .jt-nd-right-box .jt-nd-nav-main .nav-item {
    padding: 0 7px;
  }

  #jt-nd-navbar .jt-nd-navbar-container {
    .jt-nd-left-box {
      .jt-nd-name-wrapper {
        h2 {
          img {
            height: 24px;
          }
        }
      }
    }
  }

}

@media screen and (max-width: 782px) {
  #jt-nd-navbar .jt-nd-navbar-container .jt-nd-left-box .jt-nd-search-wrapper {
    width: 136px;
  }
}

@media screen and (max-width: 767px) {
  .jt-nd-nav-main {
    display: none !important;
  }

  .jt-nd-left-box-public {
    display: block;
  }

  #jt-nd-navbar {
    padding: 25px 0px;
  }

  #jt-nd-navbar .jt-nd-navbar-container {
    display: block;
    width: 100%;
    padding: 0 30px;

    .jt-nd-left-box {
      display: flex;
      align-items: center;
      justify-content: left;

      .jt-nd-name-wrapper {
        margin-right: 15px;

        h2 {
          font-size: 32px;
          line-height: 0;
        }
      }

      .vertical-align-center {
        transform: translateY(20%);
      }

      .jt-nd-search-wrapper {
        flex-grow: 3;
        width: auto;
      }
    }



    .jt-nd-right-box {
      display: block;
    }
  }
}

@media screen and (max-width: 768px) {
  
  .mobile-profile {
    display: block !important;
    float: right;
    width: 40px;
    //margin-left: 15px;

    .profile-pic, .profile-pic:hover, .profile-pic:focus, .profile-pic:active {
      border-radius: 100% !important;
    }

    .profile-pic-a, .profile-pic-a:hover, .profile-pic-a:focus, .profile-pic-a:active {
      display: none;
    }
  }

  .profile-wrap:hover {
    .profile-pic {
      display: none;
    }
    .profile-pic-a {
      display: block;
      border-radius: 100% !important;
    }
  }





  #jt-nd-navbar .jt-nd-navbar-container .profile-dropdown-options {
    right: 30px;
  }

  #write-post-box {

    .article-write-button-bar {
      .article-write-button {
        min-height: 36px;
        font-size: 12px;
      }

      .image-upload-icon {
        height: 35px;
      }
    }
  }
}

@media screen and (max-width: 480px) {

  #jt-nd-navbar .jt-nd-navbar-container {
    padding: 0 15px;
  }

  #jt-nd-navbar .jt-nd-navbar-container .profile-dropdown-options {
    right: 15px;
  }

  #jt-nd-navbar .jt-nd-navbar-container .jt-nd-left-box .jt-nd-name-wrapper h2 {
    font-size: 26px;
  }

  #write-post-box {
    display: none;
  }

  .mobile-postbox-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    background: white;
    padding: 20px 10px;
    border-radius: 10px;
    margin-bottom: 5px;

    .write-box-mobile {
      float: left;
      display: block;
      position: relative;
      left: 0;
      margin-right: 24px;
      color: $slategray;
    }

    .mobile-image-upload-icon {
      width: 24px;
      float: right;
      right: 10px;
      position: absolute;
    }
  }
}

@media screen and (max-width: 375px) {
  #jt-nd-navbar .jt-nd-navbar-container .jt-nd-left-box .jt-nd-name-wrapper h2 {
    font-size: 24px;
  }

  #jt-nd-navbar .jt-nd-navbar-container .jt-nd-left-box .jt-nd-search-wrapper {
    max-width: 150px;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #jt-nd-navbar .jt-nd-navbar-container .jt-nd-right-box .jt-nd-nav-main .nav-item .nav-item-link .nav-item-icon .nav-item-icon-img{
    display: inline-block !important;
  }

  #jt-nd-navbar .jt-nd-navbar-container .jt-nd-right-box .jt-nd-nav-main .nav-item .nav-item-link .nav-item-icon .active{
    display: none !important;
  }

  #jt-nd-navbar .jt-nd-navbar-container .jt-nd-right-box .jt-nd-nav-main .nav-item {
    padding: 0 8px;
  }

  .sidenav a {
    .in-active{
      display: block !important;
      float: left;
    }
    .active {
      display: none !important;
    }

    hr {
      display: inline-block;
    }
  }

  a:hover{
    .in-active {
      opacity: 0.5;
    }
  }

  .jt-not-logged-nav-btns {
    display: inline-block !important;
  }
}