.article-button-bar-bottom{
  margin-bottom: 20px;
}

.content-white{
  background-color: $pure-white;
}

.article-header-bar{
  width: 100%;
  height: 55px;
  padding: 5px;
  background-color: #e6e6e6;
}

.article-view-buttons{
  float: right;
  margin-bottom: 10px;

  .jt-btn{
    font-size: 1.1rem !important;
    padding: 5px;
  }
}

.article-view-header-pic {
  max-height: 350px;
  width: 100%;
  border-bottom-color: gray;
  border-radius: 0px;
  padding-bottom: 9px;
  vertical-align: middle;

  .header-view-image-main {
    width: 100%;
    max-height: 350px;
    border-bottom: 1px solid #E1E9EE;
    //border-top: 1px solid #E1E9EE;
    overflow: hidden;
  }
}

.article-view-container {
  display: block !important;
  div {
   // margin-bottom: 20px;
   //  clear: both;
  }
}

.published-date-section{
  padding-left: 15px;
}

.article-header-topic-section{
  padding-left: 15px;
  word-wrap: break-word;
  margin-top: 10px;
}

.article-user-details{
  word-wrap: break-word;
}

.article-user-details-section{
  width: 100%;
  //height: 80px;
  border-bottom: 1px solid rgb(217, 217, 217);
  border-top: 1px solid rgb(217, 217, 217);
  padding: 9px 15px 15px 15px;
  display: flex;
}

.article-user-pic {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  border: 3px solid #8ddbff;
  cursor: pointer;
}

.article-user-details{
  padding: 0 10px 10px 15px;
}

.article-view-content{
  background-color: $pure-white;
  padding: 20px 15px 15px 15px;

  p {
    word-wrap: break-word;
    line-height: 32px;
    font-weight: 400;
    color: rgba(0,0,0,.7);
    font-size: 21px;
    //margin: 3.2rem 0;

    iframe {
      max-width: 100%;
    }
  }
}

.article-wrap{
  background-color: $pure-white;
  max-width: 980px;
  margin: 0 auto;
  @include border-radius(2px);

  .table {
    tbody > tr > td, thead > tr > th {
      padding: 12px;
    }
    .article-name {
      width: 30%;
    }

    .draft-article-buttons-wrap {
      text-align: center;
    }

    .status {
      .draft-article-buttons {
        display: none;
      }
    }
  }
}

.article-padding-view{
  margin-top: 25px;
}

.article-edit-button{
  margin-top: 10px;

  .jt-btn{
    padding:3px 8px;
    min-height: 20px;
    font-size: 1.4rem;
  }
}

.article-options, .article-options-small {
  margin-left: auto;

  .jt-btn{
    padding:0px;
    min-height: 20px;
    font-size: 1.4rem;
  }

  .icon-size{
    font-size: 30px;
  }

}

.article-options {
  display: block;
}

.article-options-small {
  display: none;
  margin-top: 15px;
}

.article-author-name{
  color: #0275ce;
  font-weight: bold;
  max-width: 280px;
  font-size: 15px;
  font-size: 1.5rem;
}

@media (max-width: 476px) {
  .article-wrap{
    background-color: $pure-white;
    max-width: 980px;
    margin: 0 auto;

    .table {
      tbody > tr > td, thead > tr > th {
        padding: 10px;
      }

      .status {
        .draft-article-buttons {
          display: block;
        }
      }

      .draft-article-buttons {
        a {
          font-size: 12px !important;
        }
      }

      .draft-article-buttons-wrap, .draft-article-buttons-th {
        display: none;
      }
    }
  }
}
@media (max-width: 656px) {
  .jt-scrollable-content-eventlist, .article-padding-view, .article-view-container {
    padding: 0 !important;
  }
}
