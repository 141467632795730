.article-share-buttons{
  float: right;
  .jt-btn {
    //padding: 11px;
    padding: 8px 20px;
    font-size: 1.2rem !important;
  }
}

.article-modal{
  h3{
    word-wrap: break-word;
  }
  p{
    word-wrap: break-word;
  }
}

.cover-image-label {
  line-height: 28px;
  font-weight: 400;
  color: rgba(0, 0, 0, .85);
  font-size: 21px;
  transition-duration: 334ms;
  transition-delay: 0s;
  background-position: center 5px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 100%;
  text-indent: -9999px;
  opacity: .5;
  transition-property: opacity;
}

.header-remove-button{
  background-color: rgba(255, 255, 255, 0.18);
  float: right;
  position: absolute;
  right: 0;
  z-index: 10;
  opacity: 0.8;
}

.article-button-bar{
  width: 100%;
  height: 55px;
  padding: 5px;
  //background-color: #e6e6e6;
  border-radius: 10px;
}

.article-button-bar-bottom{
  margin-top: -20px;
}

.jt-article-main-btns {
  width: 100%;
  margin-top: 10px;
  box-shadow: 0 2px 3px rgba(0,0,0,.25);
}

.article-buttons{
  //float: right;
  //margin-bottom: 10px;
  text-align: right;
  display: block !important;
  padding-bottom: 10px !important;

  .jt-btn{
    font-size: 1.2rem !important;
    padding: 11px;
  }
}

.article-main{
  margin-top: 60px;
}

.whole-editing-section{
  background-color: rgb(255, 255, 255);
  width:100%;
}

.header-topic{
  font-size: 36px;
  line-height: 48px;
  font-weight: 600;
  color: rgba(0,0,0,.85);
  margin: 1.0rem 0;
  min-height: 4.8rem;
  overflow-y: hidden!important;
  resize: none;
  box-sizing: content-box;
}

.article-header-pic {
  height: 350px;
  width: 100%;
  border-bottom-color: gray;
  border-radius: 0px;
  padding-bottom: 9px;
  vertical-align: middle;
  position: relative;

  .header-image-main {
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #E1E9EE;
    border-top: 1px solid #E1E9EE;
    overflow: hidden;
    cursor: pointer;
    position: relative;
  }

  .header-image-main:hover {
    opacity: 0.7;
  }

  .jt-file-camera-img {

    opacity: 0;
    left: 0;
    right: 0;
    top: 62px;
    margin-top: -22px;
  }
}

.header-topic-summernote {
  margin: 5px;
  min-height: 300px;

  .note-editor.note-frame {
     border: 0px solid !important;

    .note-toolbar {
      .note-headline {
        .dropdown-menu {
          [data-value="blockquote"], [data-value="pre"], [data-value="h4"], [data-value="h5"], [data-value="h6"] {
            display: none;
          }
        }
      }
    }
  }
  .summernote-editable needsclick{
    min-height: 100%;
  }

  display: block;
  min-height: 300px;

  .modal-open {
    overflow: hidden;
  }
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
  }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    -o-transition:      -o-transform .3s ease-out;
    transition:         transform .3s ease-out;
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    -o-transform: translate(0, -25%);
    transform: translate(0, -25%);
  }
  .modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 10px;
  }
  .modal-content {
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #999;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

    .modal-body {
      .form-group {
        label {
          //.text-muted {
          //  display: none;
          //}
          //&:after {
          //  font-size: 85%;
          //  color: #777777;
          //  content: '(YouTube, Vimeo)';
          //}
        }
      }
    }
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
  }
  .modal-backdrop.fade {
    filter: alpha(opacity=0);
    opacity: 0;
  }
  .modal-backdrop.in {
    filter: alpha(opacity=50);
    opacity: .5;
  }
  .modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
  }
  .modal-header .close {
    margin-top: -2px;
  }
  .modal-title {
    margin: 0;
    line-height: 1.42857143;
  }
  .modal-body {
    position: relative;
    padding: 15px;
  }
  .modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
  }
  .modal-footer .btn + .btn {
    margin-bottom: 0;
    margin-left: 5px;
  }
  .modal-footer .btn-group .btn + .btn {
    margin-left: -1px;
  }
  .modal-footer .btn-block + .btn-block {
    margin-left: 0;
  }
  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }

  dropup,
  .dropdown {
    position: relative;
  }
  .dropdown-toggle:focus {
    outline: 0;
  }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 145px !important;
    padding: 5px !important;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  }

  .dropdown-menu.pull-right {
    right: 0;
    left: auto;
  }
  .dropdown-menu .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
  }
  .dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
  }
  .dropdown-menu > li > a:hover,
  .dropdown-menu > li > a:focus {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
  }
  .dropdown-menu > .active > a,
  .dropdown-menu > .active > a:hover,
  .dropdown-menu > .active > a:focus {
    color: #fff;
    text-decoration: none;
    background-color: #337ab7;
    outline: 0;
  }
  .dropdown-menu > .disabled > a,
  .dropdown-menu > .disabled > a:hover,
  .dropdown-menu > .disabled > a:focus {
    color: #777;
  }
  .dropdown-menu > .disabled > a:hover,
  .dropdown-menu > .disabled > a:focus {
    text-decoration: none;
    cursor: not-allowed;
    background-color: transparent;
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  }
  .open > .dropdown-menu {
    display: block;
  }
  .open > a {
    outline: 0;
  }
  .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .dropdown-menu-left {
    right: auto;
    left: 0;
  }
  .dropdown-header {
    display: block;
    padding: 3px 20px;
    font-size: 12px;
    line-height: 1.42857143;
    color: #777;
    white-space: nowrap;
  }
  .dropdown-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 990;
  }
  .pull-right > .dropdown-menu {
    right: 0;
    left: auto;
  }
  .dropup .caret,
  .navbar-fixed-bottom .dropdown .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
  }
  .dropup .dropdown-menu,
  .navbar-fixed-bottom .dropdown .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px;
  }

  .panel-heading > .btn, .panel-heading > .btn-group > .btn {
    margin-top: 0px;
    margin-right: 0px;
  }
}

.artilce-main{

}
.header-topic-section{
  //max-width: 744px;
  padding-right: 35px;
  margin: 5px;

  textarea {
    border: 2px solid #fff;
  }

  textarea:focus {
    border: 2px solid #5da6dd;
  }
}

.jt-article-cancel {
  color: $white;
  text-transform: uppercase;
  font-weight: bold;
}

.croppie-container {
  .cr-slider-wrap {
    width: 56% !important;

    input[type=range] {
      border: none !important;
      display: inline-block !important;
    }

    .cr-slider-with-height {
      height: 30px;
    }
  }
}

.pr-icon-zoom-minus {
  float: left;
  margin-left: -29px;
  margin-top: -9px;
  color: #757575;
  font-size: 18px;
  display: inline-block;
  padding-top: 16px;
}

.pr-icon-zoom-plus {
  float: right;
  margin-right: -29px;
  margin-top: -9px;
  color: #757575;
  font-size: 18px;
  display: inline-block;
  padding-top: 16px;
}