//CSS3 PREFIX
@mixin css3-prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -khtml-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}

//BORDER RADIUS
@mixin border-radius($radius: 5px) {
  @include css3-prefix('border-radius', $radius);
}

//TEXT TRUNCATE
@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin para-truncate($lineHeight, $maxHeight) {
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: $maxHeight;
  line-height: $lineHeight;
}

//POSITION ABSOLUTE
@mixin abs-pos($top: auto, $right: auto, $bottom: auto, $left: auto) {
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  position: absolute;
}

//FONT SIZES FOR BOTH PX'S AND REM'S
@mixin font-size($sizeValue) {
  font-size: $sizeValue + px; //fallback for old browsers
  font-size: ($sizeValue/10) + rem;
}

//BACKGROUND OPACITY WITH COLOR
@mixin bg-rgba($r, $g, $b, $opacity, $color) {
  background-color: transparent;
  background-color: rgba($r, $g, $b, $opacity);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$color}', endColorstr='#{$color}');
  zoom: 1;
}

//PADDING NONE
@mixin padNone {
  padding: 0;
}

//MARGIN NONE
@mixin marNone {
  margin: 0;
}

//MARGIN AND PADDING NONE
@mixin MarPadNone {
  margin: 0;
  padding: 0;
}

//MEDIA QURIES
@mixin maxSize {
  @media all and (max-width: $maxSize) {
    @content
  }
}

@mixin alphaSize {
  @media all and (max-width: $alpha) {
    @content
  }
}

@mixin betaSize {
  @media all and (max-width: $beta) {
    @content
  }
}

@mixin gammaSize {
  @media all and (max-width: $gamma) {
    @content
  }
}

@mixin deltaSize {
  @media all and (max-width: $delta) {
    @content
  }
}

@mixin epsilonSize {
  @media all and (max-width: $epsilon) {
    @content
  }
}

@mixin zetaSize {
  @media all and (max-width: $zeta) {
    @content
  }
}

@mixin etaSize {
  @media all and (max-width: $eta) {
    @content
  }
}

@mixin thetaSize {
  @media all and (max-width: $theta) {
    @content
  }
}

@mixin iotaSize {
  @media all and (max-width: $iota) {
    @content
  }
}