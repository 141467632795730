.jt-empty-view-box {
  min-width: 300px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 80%;
  margin-top: 25px;
  .jt-empty-view-top {
    clear: both;
    img {
      min-width: 65px;
      width: 22%;
      height: auto;
      clear: both;

    }
  }
  .jt-empty-view-middle {
    clear: both;
    img {
    }
    .jt-empty-view-text {
      padding: 13px;
      text-align: center;
      h4 {
        color: $blue;
        line-height: 1.3;
        word-wrap: break-word;
      }
      h6 {
        color: $black;
        word-wrap: break-word;
      }
      h5 {
        color: $blue;
        word-wrap: break-word;
        span {
          padding-top: 18px;
        }
      }
      p {
        font-size: 1.6rem;
        font-weight: bold;
        span {
          color: $blue;
        }
      }
    }
  }
  .jt-empty-view-bottom {
    clear: both;
    img {
      min-width: 65px;
      width: 22%;
      height: auto;
      clear: both;
    }
  }
}