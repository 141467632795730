.jt-issue-box {
  width: 100%;
  background-color: $red-issue;
  opacity: 0.8;
  z-index: 1002;
  padding-left: 28px;
  position: absolute;
  top: 0;
  padding-top: 10px;
  padding-bottom: 8px;
  ul {
    list-style: none;
    padding-left: 3px;
    padding-right: 3px;
    padding-bottom: 0;
  }
  ul li {
    color: $white;
    padding-top: 5px;
    font-family: $gt-font-QS;
    padding-left: 3px;
    font-size: 14px;
  }
}

.jt-info-box {
  width: 100%;
  background-color: $blue;
  opacity: 0.8;
  z-index: 1002;
  position: absolute;
  top: 0;
  padding-left: 28px;
  padding-top: 10px;
  padding-bottom: 8px;
  ul {
    list-style: none;
    padding-left: 3px;
    padding-right: 3px;
    padding-bottom: 0;
  }
  ul li {
    color: $white;
    padding-top: 5px;
    font-family: $gt-font-QS;
    padding-left: 3px;
    font-size: 14px;
  }
}

.jt-validation-msg {
  color: $red-issue;
  font-weight: 700;
}