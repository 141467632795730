.jt-about-box {
  background-color: $gray-lighter;
  width: 100%;

  .jt-prof-top {
    width: 100%;
    position: relative;
    height: 83px;
    background-color: $gray-lighter;
    .jt-round-thumb {
      width: 80px;
      padding-top: 7px;
    }
    .jt-desc {
      position: absolute;
      float: left;
      top: 22px;
      left: 89px;
      width: 54%;
      word-break: normal;
    }
    .icon {
      position: absolute;
      float: left;
    }
  }

  .jt-about-section {
    border: 1px solid $light-gray;
    padding: 12px 0;
    background-color: $white;
    margin-bottom: 12px;
    .jt-about-title {
      width: 100%;
      border-bottom: 1px solid $light-gray;
      margin-bottom: 12px;
      padding-left: 12px;
    }
    .jt-about-content {
      padding-left: 0 12px;
      color: $gray;
      word-wrap: break-word;

      a {
        text-decoration: none;
        color: $blue;
        font-size: 1.5rem;
        display: block;
        padding-right: 8px;
        padding-left: 35px;
        position: relative;
      }
      a:before {
        font-family: FontAwesome;
        color: $blue;
        padding-right: 4px;
        width: 17px;
        position: absolute;
        height: 10px;
        display: block;
        left: 0;
        padding-left: 13px;
      }
      .telephone:before {
        content: "\f095";
      }
      .email:before {
        content: "\f0e0";
      }
      .website:before {
        content: "\f0ac";
      }
      .address:before {
        content: "\f041";
      }
      i {
        padding-right: 5px;
        width: 20px;
      }
      p {
        padding: 0 12px;
        word-wrap: break-word;
      }
      ul {
        list-style: none;
        padding-left: 0px;
        li {
        }
      }
      hr {
        margin-top: 11px;
        margin-bottom: 11px;
      }
      span {
        font-weight: bold;
      }

      span.text-hyperlink {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.jt-brand-about {
  width: 100%;
  float: left;
  margin-top: 15px;
  background-color: $gray-lighter;
}

.photo-btn {
  padding: 0 0;
}

.prof-edit-btn {
  font-size: 1.8rem;
}