.jt-connect-content {
  padding-top: 5px;
  padding-bottom: 5px;

  .jt-my-connections-block {
    margin: 0 0 10px 0;

    .friend-list-wrap {
      text-align: center;
      display: block;
      position: relative;
      .friend-icon {
        display: inline-block;
        position: relative;
        margin: 0 3px;
        a {
          img {
            border-radius: 100%;
            width: 45px;
          }
        }
      }
    }

    .see-all-row {
      display: block;
      float: none;
      position: relative;
      clear: both;
      padding-top: 1px;
    }
  }

  .page-header {
    padding-left: 12px;
    margin: 1px 20px;
  }
  .list-group-item {
    width: 100%;
    padding: 10px 10px;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    .connect-list-image {
      width: 65px;
      float: left;
      img {
        width: 100%;
        height: auto;
        border-radius: 100%;
      }
    }
    .connect-list-name {
      width: 50%;
      float: left;
      padding-left: 16px;
      padding-top: 22px;

      p {
        @include text-truncate();
        font-size: 1.5rem;
      }
    }

    .connect-list-request-name {
      width: 43%;
      float: left;
      padding-left: 16px;
      //padding-top: 22px;
      p {
        @include text-truncate();
        @include font-size(15);
        margin-bottom: 2px;
      }

      &.jt-connect-friends-name {
        //-ms-transform: translate(0%, -50%);
        //transform    : translate(0%, -50%);
        display: flex;
        align-items: center;
        flex-grow: 3;

        .right-bar-wrap {
          margin-left: auto;
        }

        .jt-connect-job-details {
          p {
            @include font-size(12);
            line-height: 1.2;
            color: $gray;
            margin-bottom: 2px;
            @include text-truncate();
            float: left;
          }
        }
        .jt-clear-line {
          /*display: table-cell;
          vertical-align: middle;
          width: 100%;
          max-width: 54px;
          //float: left;*/

          display: block;
          position: relative;
        }

        .message-button-wrap {
          display: table-cell;
          vertical-align: bottom;
        }

        .dropdown-wrap {
          display: table-cell;
          vertical-align: middle;
          padding-left: 10px;

          a{
            padding: 0;
            line-height: 1;
          }

          .dropdown-menu-wrap {
            display: block;
            position: relative;
            right: 100px;

            .dropdown-menu {
              left: 0;
              position: absolute;
              min-width: 110px;
              li{
                width: 80%;
                text-align: left;
                margin-left: 10px;

                a{
                  &:hover{
                    background: $pure-white;
                  }
                }
              }
            }
          }

          .remove-dropdown-menu {
            position: absolute;
            background: $pure-white;
            padding: 5px 30px 5px 10px;
            z-index: 1;
            right: 0;
            border: $ash 1px solid;
            box-shadow: 0 0 0 1px rgba(0,0,0,.1), 0 6px 9px rgba(0,0,0,.2);

            ul{
              list-style-type: none;
            }
          }
        }

      }

      &.jt-connect-search-friends-name {
        width: 43%;
      }
    }
    .connect-list-edge {
      width: 20px;
      float: right;
      padding-top: 25px;
      padding-right: 12px;
    }
    .connect-list-edge-btn {
      //width: 105px;
      float: right;
      //padding-top: 20px;
      //display: flex;
      //justify-content: flex-end;
      text-align: right;

      .jt-request-btn {
        //padding: 9px 5px;
        padding: 8px 20px;
        color: $white;
        background-color: $blue;
        min-height: 8px !important;
        border-radius: 4px;
        margin-right: 2px;
        margin-bottom: 2px;
        @include font-size(12);
        z-index: 2;

        &:disabled {
          background: $gray !important;
        }
      }
      button{
        min-height: 0;
        width: 102px;
        &:first-child{
          margin-bottom: 10px;
        }
      }

      button.confirm,
      button.reject {
        width: 42px;
        height: 40px;
        padding-top: 3%;
        margin-top: -3px;
        @include font-size(18);
      }
      button.reject {
        background-color: $blue-extra-dark;
      }

      .message-button-wrap, .dropdown-wrap {
        display: inline-block;

        .btn {
          margin-right: 0;
          padding-right: 0;
        }

        .dropdown-menu-wrap {
          display: block;
          position: relative;

          .dropdown-menu {
            right: -7px;
            position: absolute;
            min-width: 110px;
            left: auto;

            li {
              width: 100%;
              a:hover {
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
  ul {
    margin-left: 5px;
    margin-right: 5px;
    li {
      width: 33.33%;
      text-align: center;
      .jt-connect-menu-icon {
        font-size: 1.5rem;
      }
    }
  }
}

.search-friends {
  width: 100%;
  padding: 0 0 0 10px;
  margin: 10px 0;
  text-align: right;

  .friend-search-input {
    width: 70%;
    border: 1px solid $light-gray;
    min-height: 30px !important;
    border-radius: 0;
    margin-top: 0;
    padding-top: 5px;
    padding-bottom: 7px;
    margin-bottom: 3px;
  }
  .friend-search-btn {
    width: 30%;
    border-radius: 0;
    min-height: 30px !important;
    background-color: $blue;
    color: $white;
    float: left;
    margin-top: 0;
    margin-bottom: 3px;
  }
}

/** Connect requests **/
.jt-connect-requests-nav {
  margin: $sidePadding;

  button {
    padding: 8px 7px;
    width: 50%;
    float: left;
    @include font-size(13);
    border-radius: 0;
    font-weight: normal;
    text-transform: none;
  }

  .active {
    background-color: $blue-extra-dark;
  }
}

.list-group-icon-secondary {
}


/*.jito-ppymk-landing {
  .box-header {
    display: table;
    width: 100%;

    .left-box {
      display: table-cell;
      vertical-align: middle;
    }

    .right-box {
      display: table-cell;
      vertical-align: middle;

      .search-friends {
        .friend-search-input {
          border-radius: 4px;
          padding-right: 35px;
          width: 100%;
        }
        #search-icon {
          position: absolute;
          right: 0;
          height: 22px;
          margin-right: 5px;
          margin-top: -30px;
        }
      }
    }
  }
}*/

.jt-connect-content {

  .jito-connect-requests {
    align-items: initial;
    @include border-radius(2px);

    .connect-list-image {
      width: auto;
      float: none;
      min-width: 13%;
      a {
        img {
          max-width: 80px;
          border-radius: 100%;
        }
      }
    }

    .right-box {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
      position: relative;
      flex-grow: 3;
      width: auto;

      .user-details-wrap {
        display: block;
        position: relative;
        line-height: 1.8;
        flex-grow: 3;
        width: auto;
        margin-right: 10px;
      }

      .user-inv-button-wrap {
        display: table;
        height: 100%;
        position: relative;

        .user-inv-button-row {
          display: table-cell;
          vertical-align: middle;
        }
        button {
          width: 94px;
        }
      }

      .close-button {
        position: absolute;
        right: 0;
        top: -4px;
        cursor: pointer;
      }
    }
  }

  .jt-box-border{
    padding: 10px 24px;
    border: $ash 1px solid;
    @include border-radius(2px);
    background-color: white;
    h4{
      margin: 0;
      font-weight: normal;
    }
  }

  .jt-connect-menu-bar {
    margin: 0;
    display: flex;
    display: -webkit-flex;
    //flex-wrap: wrap;

    .jt-connect-menu-item-wrap{
      padding: 0 2.5px;
      margin-bottom: 5px;

      &:first-child,  &:nth-child(4n){
       padding-left: 0;
      }

      &:nth-child(3n){
        padding-right: 0;
      }

      .jt-connect-menu-item {
        text-align: center;
      }
    }
  }

  .jt-row {
    margin: 10px 0;
  }

  .jt-connections-box, .search-friend-wrap {
    .box-header {
      display: table;
      width: 100%;

      .left-box {
        display: table-cell;
        vertical-align: middle;
      }

      .right-box {
        display: table-cell;
        vertical-align: middle;

        .search-friends {
          .friend-search-input {
            border-radius: 4px;
            padding-right: 35px;
            width: 100%;
          }
          #search-icon {
            position: absolute;
            right: 0;
            top: 16px;
            height: 22px;
            margin-right: 6px;
          }
        }
      }
    }
  }

  .jt-connections-box .box-header .right-box .search-friends #search-icon {
    right: 30px;
    top: 42px;
  }

  .jt-pending-inv-box {
    .jt-int-row {
      display: flex;
      justify-content: space-between;
      padding: 10px;

      .user-profile-wrap{
        display: block;
        position: relative;
        margin-right: 10px;

        img{
          max-width: 80px;
          border-radius: 100%;
        }
      }

      .right-box {
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
        position: relative;
        flex-grow: 3;
        width: auto;
      }

      .user-details-wrap {
        display: block;
        position: relative;
        line-height: 1.8;
        flex-grow: 3;
        width: auto;
        margin-right: 10px;
        .dp-name {
          font-weight: bold;
        }
        .mutual-friends-link {
          color: inherit;
        }
      }

      .user-inv-button-wrap {
        display: block;
        position: relative;
        .user-inv-button-row{
          display: block;
          position: relative;
          transform: translateY(50%);

          button{
            width: 94px;
          }
        }
      }
    }

    hr{
      margin: 0;
    }

    .close-button {
      position: absolute;
      right: 36px;
      cursor: pointer;
    }
  }

  .jt-pending-inv-box-wrap {
    .jt-pending-inv-box:last-child {
      .jt-int-row-more {
        padding-bottom: 0;
      }
      hr{
        display: none;
      }
    }
  }

  .see-all-row {
    hr {
      margin-top: 10px;
    }
    .show-more-wrap {
      display: block;
      position: relative;
      text-align: center;
      font-weight: bold;
      cursor: pointer;
    }
  }


  .jt-ppl-ymk-block{
    display: block;
    position: relative;
    margin: 0 0 10px 0;

    .jt-ppl-ymk-wrpa{
      display: block;
      position: relative;
      padding: 10px;
      text-align: center;
      border: $ash 1px solid;
      background-color: white;
      height: 255px;

      .user-profile-wrap {
        display: block;
        position: relative;
        margin-bottom: 10px;
        img{
          max-width: 80px;
          border-radius: 100%;
        }
      }

      .user-details-wrap{
        .dp-name{
          font-weight: bold;
        }
        .mutual-friends-link {
          color: inherit;
        }
      }

      .user-connect-button-wrap {
        .user-inv-button-row{
          display: inline-block;
          bottom: 10px;
          position: absolute;
          left: 0;
          right: 0;
        }
      }
    }

    [class*='col-']{
      padding-left: 0;
      padding-right: 1px;
    }

    .jt-ppl-ymk-list {
      .close-button {
        display: block;
        position: absolute;
        top: 2px;
        right: 5px;
        cursor: pointer;
      }
    }
  }

  .title-text {
    font-weight: normal;
    font-size: 1.3rem;
  }

  .section-title {
    font-size: 1.5rem;
  }

  .active-item {
    font-weight: bold;
  }

  hr{
    margin-bottom: 8px;
  }

  .bold-text {
    font-weight: bold;
  }

  .select-button{
    background-color: $blue;
    color: $white;
  }

  .secondary-button {
    background-color: $blue-extra-dark;
    color: $white;
  }

  .no-side-padding {
    margin-left: 0;
    margin-right: 0;
  }

  .list-group {
    margin-bottom: 0;
  }

  .float-right {
    float: right;
  }

  .black-text-link {
    color: $black;
    cursor: pointer;
    font-weight: bold;
  }
}

.jt-more-width {
  max-width: 780px;
  width: 100%;
}

.background-color{
  background-color: $jc-background;
}

.search-friend-wrap {
  background: $pure-white;
  padding: 10px 24px;
  @include border-radius(2px);

  .right-box {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    position: relative;
    flex-grow: 3;
    width: auto;
  }

  .list-group {
    .list-group-item {
      .connect-list-image {
        img {
          width: 65px;
          height: auto;
          border-radius: 100%;
          max-width: 65px;
        }
      }
    }
  }
}

.list-name-details {
  max-width: 58%;
  word-break: initial;
}

@media screen and (max-width: 1024px) {

  .jt-connect-content .jt-box-border {
    padding: 10px 20px;
  }

  /*.jt-connect-content .jt-pending-inv-box .jt-int-row {
    align-items: center;
  }*/

  /*.jt-connect-content .jt-pending-inv-box .jt-int-row .user-inv-button-wrap .user-inv-button-row{
    transform: none;
    text-align: right;

    button{
      &:first-child{
        margin-bottom: 10px;
        display: block;
      }
    }
  }*/

}

//@media screen and (max-width: 768px) {
@media screen and (max-width: 652px) {
  .list-name-details {
    max-width: 100%;

  }

  .jt-connect-content .jt-connect-menu-bar {
    display: block;
  }

  .jt-connect-content .jt-connect-menu-bar .jt-connect-menu-item-wrap {
    padding: 0;
  }

  .jt-connect-content .jt-ppl-ymk-block .jt-ppl-ymk-wrpa {
    height: 225px;
    margin-bottom: 0;
  }

  .jt-connect-content {
    hr{
      margin: 0;
    }
  }

  .jt-connect-menu-item {
    text-align: left !important;
  }

  .jt-connect-content .jt-pending-inv-box .jt-int-row .user-inv-button-wrap {
    width: 100%;
    margin-top: 10px;
  }

  .jt-connect-content .list-group .jito-connect-requests .right-box .user-inv-button-wrap {
    width: 100%;
    margin-top: 10px;
    padding-left: 16px;

    .user-inv-button-row {
      display: block;
    }
  }

  .jt-connect-content .jt-pending-inv-box .jt-int-row .user-inv-button-wrap .user-inv-button-row {
    transform: translateY(0);
  }

  .jt-connect-content .list-group-item .connect-list-edge-btn {
    float: none;
    text-align: left;
    width: 100%;
    margin-top: 10px;
    padding-left: 16px;
  }

  .jt-connect-content .search-friend-wrap .box-header .right-box {
    width: 50%;
  }
}

@media screen and (max-width: 450px) {
  .jt-connect-content .jt-pending-inv-box .jt-int-row .user-profile-wrap img{
    max-width: 65px;
  }

  .jt-connect-content .list-group-item .connect-list-request-name.jt-connect-friends-name {
    display: block;
  }

  .jt-connect-content .list-group-item .connect-list-request-name.jt-connect-friends-name .message-button-wrap button {
    min-height: 0;
  }

  .list-group {
    margin-bottom: 0;
  }

  .jt-connect-content .list-group-item .connect-list-request-name.jt-connect-friends-name .jt-clear-line {
    margin-bottom: 5px;
  }

  .jt-connect-content .section-title {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 425px) {
  .jt-connections-box .box-header .left-box .title-text {
    font-size: 1.2rem;
  }

}

@media screen and (max-width: 360px) {
  .jt-connect-content .jt-pending-inv-box .jt-int-row .user-inv-button-wrap .user-inv-button-row button:first-child {
    display: block;
    margin-bottom: 10px;
  }
}

/**** people you may know section ****/
@media screen and (min-width: 1200px), screen and (max-width: 991px) and (min-width: 768px) {
  .jt-connect-content .jt-ppl-ymk-block .jt-ppl-ymk-list:nth-child(9) {
    display: none;
  }
}