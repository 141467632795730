/**** New messaging section ****/
.new-messaging-container {
  background: white;
  padding: 10px;
  height: 100%;
}

.jt-msg-scroll-bar::-webkit-scrollbar {
  width: 8px;
}

.jt-msg-scroll-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.jt-msg-scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}

.new-messaging-section {
  display: flex;
  height: 100%;

  .new-messaging-left-bar {
    flex: 0 0 25%;
    padding: 10px;
    width: 25%;
    max-width: 25%;
    border: $ash 1px solid;
    border-radius: 2px 0 0 2px;

    .messaging-sidebar-header {
      height: 88px;

      .new-message-bar {
        text-align: center;
        padding: 10px;
        font-size: 1.5rem;
        border: $ash 1px solid;
        border-radius: 4px;
        cursor: pointer;
        color: $pure-white;
        background-color: $bright-jc-blue;
      }

      .new-message-bar:hover {
        background-color: $blue-darker;
      }

      .search-messages-bar {
        display: block;
        position: relative;
        margin-top: 10px;

        .search-message-input {
          width: 100%;
          min-height: 35px;
          padding: 5px 27px 5px 10px;
          border-radius: 4px;
        }

        .search-icon {
          position: absolute;
          right: 4px;
          top: 6px;
          height: 22px;
        }
      }
    }

    .messaging-sidebar-body {
      height: calc(100% - 98px);
      margin-top: 10px;

      .scrollable-content {
        .message-list {
          list-style: none;
          margin: 0;
          //padding: 0 10px;
          padding: 0;

          .jt-active-msg {
            border-left: 2px solid $blue;
          }

          .jt-unread-msg {
            background-color: $unread-blue;
          }

          .single-message {
            display: flex;
            flex-direction: row;
            padding: 5px 0 5px 3px;
            cursor: pointer;
            border-bottom: 1px solid #EAEAEA;

            .single-message:last-child {
              border-bottom: none;
            }

            .single-message:hover {
              background-color: $white;
            }

            .profile-picture-wrap {
              display: block;
              margin-right: 10px;
              min-width: 45px;

              img {
                height: 45px;
                width: 45px;
                max-width: 45px;
                border-radius: 100%;
              }
            }

            .message-details {
              display: block;
              overflow: hidden;
              width: 100%;

              .conversation-name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                span {
                  font-weight: bold;
                }
              }

              .last-message-wrap {
                display: flex;
                flex-direction: row;
                .last-message {

                }

                //.time {
                //  flex: 0 0 20%;
                //  align-self: flex-end;
                //}
              }

              .time {
                float: right;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }

  .message-body {
    display: flex;
    flex: 3;
    flex-direction: column;
    padding: 0 10px;
    border: $ash 1px solid;
    border-radius: 0 2px 2px 0;

    .message-body-row {
      display: block;
      position: relative;
      padding: 10px;
    }

    .message-title-row {
      text-align: center;
      font-weight: bold;
      font-size: 1.7rem;
      border-bottom: 1px solid $ash;

      .message-title {
        border: none;
        background-color: transparent;
      }

      .thread-setting-dropdown-icon {
        position: absolute;
        right: 0;
        top: 25%;
      }

      .thread-back-icon {
        position: absolute;
        left: 0;
        top: 25%;
        display: none;
      }

      .thread-setting-dropdown-menu {
        right: 0;
        left: auto;
      }

      .jt-thread-info {
        max-width: 70%;
        word-break: break-all;
        display: inline-block;

        .msg-user-work {
          font-weight: normal;
          font-size: 1.0rem;
          white-space: pre-line;
          word-break: initial;
        }
      }
    }

    .add-members-row {
      padding: 10px 0;
      .add-members {
        width: 100%;
        padding: 10px;
        border-radius: 4px;
      }
    }

    .message-content-row {
      flex: 1 1 auto;
      overflow: hidden;
      overflow-y: auto;
      margin-bottom: 10px;
      border-bottom: 1px solid $ash;

      .conversation {
        .author {
          float: right;

          .profile-picture {
            display: none !important;
          }
          .message-wrap {
            margin-left: auto;
            text-align: right;
          }
        }

        .my-msg {
          //border: 1px solid $blue;
          border-radius: 10px;
          //width: 50%;
          float: right;
          position: relative;
          word-break: initial;
          padding: 5px 10px;
          background-color: $bright-jc-blue;
          color: $pure-white;

          a {
            color: $pure-white !important;
            text-decoration: underline;
          }
        }

        .my-msg:after {
          content: '';
          position: absolute;
          right: 0;
          //top: 50%;
          border: 10px solid transparent;
          border-left-color: $blue;
          border-right: 0;
          border-bottom: 0;
          margin-top: 10px;
          margin-right: -9px;
          top: 0%;
        }

        .their-msg {
          //border: 1px solid $gray;
          border-radius: 10px;
          //width: 50%;
          float: left;
          position: relative;
          word-break: initial;
          padding: 5px 10px;
          background-color: $ash;
          color: $black;

          a {
            color: $black !important;
            text-decoration: underline;
          }
        }

        .their-msg:after {
          content: '';
          position: absolute;
          left: 0;
          //top: 50%;
          border: 10px solid transparent;
          border-right-color: $ash;
          border-left: 0;
          border-bottom: 0;
          margin-top: 10px;
          margin-left: -9px;
          top: 0%;
        }

        .single-message {
          display: flex;
          //padding: 10px;
          padding: 5px 10px;
          margin: 5px 0;
          //margin: 5px 10px;
          max-width: 50%;
          word-break: break-all;

          .profile-picture {
            display: block;
            position: relative;
            margin-right: 10px;
            min-width: 35px;

            img {
              width: 35px;
              height: 35px;
              max-width: 35px;
              border-radius: 100%;
            }
          }

          .message-wrap {
            .message {
              p{
                font-size: 14px;
                margin-bottom: 0;
                text-align: left;
              }
            }

            .sent-time {
              font-size: 12px;
              clear: both;
            }
          }
        }
      }
    }

    .write-message-row {
      background: white;
      margin-bottom: 10px;
      border-radius: 4px;
      border: $ash 1px solid;

      .write-message-box {
        .write-message-textarea {
          resize: none;
        }

        .jt-msg-action-approve {
          background-color: $bright-jc-blue;
          color: $pure-white;
          border-radius: 5px;
          padding: 5px 10px;
        }

        .jt-msg-action-approve:hover {
          background-color: $blue-darker;
        }

        .jt-msg-action-delete {
          background-color: $ash;
          border-radius: 5px;
          padding: 5px 10px;
        }

        .jt-msg-action-delete-actions:hover {
          background-color: $light-gray;
        }
      }

      .send-message-bar {
        .send-msg-btn {
          float: right;
          cursor: pointer;
          font-weight: bold;
          color: $bright-jc-blue;
        }

        .send-msg-btn:hover {
          color: $blue-darker;
        }
      }
    }
  }
}
/**** New messaging section ****/

.jt-messenger-list-view {
  position: relative;
  background: yellow;

  .jt-msg-btn-holder {

     position: fixed!important;
     bottom: 55px!important;
     width: 100%;
     max-width: 640px;
     z-index: 500;

    .jt-create-msg-btn {
      width: 50px;
      height: 50px;
      background-color: $blue;
      color: $white;
      border-radius: 150px;
      font-size: 1.5rem;
      text-align: center;
      padding: 14px;
      position: absolute;
      bottom: 28px;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
      z-index: 999;
    }
  }

}

.jt-messenger-list {
  padding: 0;
  position: relative;
  height: 90px;
  a {
    text-decoration: none;
    color: $gray;
  }

  ul {
    list-style: none;
    padding: 0;
    li {
      padding: 0;
    }
  }
  .jt-messenger-list-element {
    border-bottom: 1px solid $light-gray;
    padding: 10px;
    height: 90px;
    background-color: $light-gray;
    position: relative;
    width: 100%;
    display: table;
    
    .jt-messenger-list-image {
      float: left;
      width: 65px;
      //top: 50%;
      //transform: translate(0, -50%);
      //-ms-transform: translate(0, -50%);
      //position: absolute;
      img {
        width: 100%;
        height: auto;
        border: 3px solid $blue-light;
        border-radius: 100%;
        cursor: pointer;
      }
    }

    .jt-messenger-list-name {
      //padding-left: 10px;
      float: left;
      width: 53%;
      margin-left: 10px;
      //margin-top: 12px;
      position: absolute;
      margin-left: 80px;
      cursor: pointer;
      /*top: 50%;
      transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);*/
      //height:75px;

      span {
        @include text-truncate();
        font-weight: bold;
        font-size: 1.5rem;
      }
      p {
        @include text-truncate();
        margin-bottom: 0;
      }
      p.jt-msg-prv {
        font-style: italic;
      }
      p.jt-msg-job-details {
        @include font-size(11);
        color: $gray;
        opacity: 0.8;
      }
    }
    .jt-messenger-list-time {
      float: right;
      //margin-top: 14px;
    }

    .jt-messenger-del-thread {
      position: absolute;
      //bottom: 2px;
      right: 10px;
      font-size: 2rem;
      padding: 10px 20px;
    }

  }

  div.unread {
    background-color: $unread-blue;
    .jt-messenger-list-name {
      span {
        color: $black;
      }
      p {
        color: $black;
      }
    }
    .jt-messenger-list-time {
      p {
        font-weight: bold;
        color: $black;
      }

    }
  }
  .jt-start-conversation {
    .start-con-heading {
      border-radius: 5px 5px 0 0;
      background-color: $blue;
      color: $white;
      padding-bottom: 1px;
      padding-top: 10px;
      margin-bottom: 10px;
    }
    margin-top: 25px;
    clear: both;
    width: 80%;
    border-radius: 8px;
    border: 1px solid $gray-lighter;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    h6 {
      line-height: 1;
      padding-top: 13%;
    }
    i {
      color: $blue;
    }
  }

  .jt-start-conversation-nb {
    width: 90%;
    border: none;
  }

}

.jt-msg-clear-line {
  display: table-cell;
  vertical-align: middle;
}

.jt-msg-btn-holder-new {
    position: absolute!important;
    bottom: 42px!important;
    z-index: 10;
    right : 24px;
}

.people-list {
  list-style: none;
  border: #EAEAEA 1px solid;
  padding: 10px;
  border-radius: 4px;

  li {
    display: flex;
    .profile-picture-wrap {
      width: 55px;
      height: 55px;
      padding-right: 10px;
      min-width: 60px;
      img {
        border-radius: 100%;
      }
    }
    .user-details {
      text-align: left;
    }
  }
}

.jt-msg-user-search {
  position: absolute;
  z-index: 9999;
  background-color: #fff;
  width: 100%;
  padding: 5px !important;
  max-height: 250px;
  overflow-y: scroll;

  li {
    cursor: pointer;
  }

  li:hover {
    background-color: $white;
  }
}

.jt-system-msg {
  width: 100%;
  color: $jito-pink;
  font-size: 1.2rem;
  padding: 10px;
  margin: 5px 0;
}

.jt-msg-user-list {
  width: 100%;
  border: none;

  li {
    //border: 1px solid $black;
    border-radius: 5px;
    color: $pure-white;
    background-color: $bright-jc-blue;
    float: left;
    padding: 7px 5px 2px 5px;
    margin-right: 5px;
    margin-bottom: 5px;

    .profile-picture-wrap {
      min-width: 25px;
      width: 25px;
      height: 25px;
      padding-right: 5px;
      margin-top: -2px;
      margin-bottom: 3px;
    }

    .user-details {
      word-break: break-word;
    }

    .jt-msg-user-remove {
      float: right;
      vertical-align: middle;
      margin-top: -4px;
      margin-bottom: -3px;
    }
  }

  li:hover {
    background-color: $blue-darker;
  }

  .jt-msg-user-remove-btn {
    //border: 1px solid #eaeaea;
    padding: 4px 8px;
    font-size: 1.2rem;
    margin: 0 0 5px 2px;
    border-radius: 20px;
    min-height: 10px;
    background-color: transparent;
  }

  .jt-msg-user-remove-btn:hover {
    //border: 1px solid $black;
  }
}

.jt-msg-members-list {
  width: 100%;
  border: none;

  li {
    float: left;
    margin-right: 15px;
    margin-bottom: 5px;
    background-color: $bright-jc-blue;
    color: $pure-white;
    padding: 4px 8px 1px 8px;
    border-radius: 5px;

    .profile-picture-wrap {
      width: 25px;
      height: 25px;
      padding-right: 5px;
    }
  }

  li:hover {
    background-color: $blue-darker;
  }
}

.profile-picture-wrap-thread {
  width: 25px;
  height: 25px;
  padding-right: 5px;
}

.jt-message-setting-model {
  .modal-dialog {
    .modal-content {
      .modal-header {
        border: none;
        padding-bottom: 0;

        .close {
          width: auto;
          height: auto;
          margin-top: -20px;
          margin-right: -4px;
          position: relative;
        }
      }
      .modal-body {
        text-align: center;
        padding-top: 0;

        .jt-modal-inner {
          .conversation-title-wrap {
            .conversation-name {
              max-width: 350px;
              margin-left: auto;
              margin-right: auto;
            }

            .num-of-people {
              display: inline-block;
              margin: 0 0 10px 0;
              position: relative;
              font-size: 1.5rem;
            }
          }

          .conversation-people-details-wrap {
            display: block;
            position: relative;
            padding: 0;
            max-width: 350px;
            margin: 0 auto;

            .title {
              display: inline-block;
              position: relative;
              font-size: 1.5rem;
              margin-bottom: 5px;
              font-weight: bold;
            }

            //.people-list {
            //  list-style: none;
            //  border: #EAEAEA 1px solid;
            //  padding: 10px;
            //  border-radius: 4px;
            //
            //  li {
            //    display: flex;
            //    .profile-picture-wrap {
            //      width: 65px;
            //      height: 65px;
            //      padding-right: 10px;
            //      img {
            //        border-radius: 100%;
            //      }
            //    }
            //    .user-details {
            //      text-align: left;
            //    }
            //  }
            //}
          }

          .conversation-save-button-wrap {
            display: block;
            position: relative;
            margin-top: 10px;

            button {
              width: 75px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .new-messaging-section .new-messaging-left-bar .messaging-sidebar-body .scrollable-content .message-list .single-message .message-details .last-message-wrap .time {
    //flex: 0 0 25%;
  }
}

@media screen and (max-width: 992px) {
  .new-messaging-container {
    width: 885px;
    .scrollable-content {
      padding: 0;
    }
    .new-messaging-left-bar {
      flex: 0 0 30%;
      width: 30%;
      max-width: 30%;
    }
  }
}

@media screen and (max-width: 890px) {
  .new-messaging-container {
    width: 750px;
  }

  .new-messaging-section .new-messaging-left-bar .messaging-sidebar-body .scrollable-content .message-list .single-message .message-details .last-message-wrap .time {
    //flex: 0 0 30%;
  }
}

@media screen and (max-width: 750px) {
  .new-messaging-container {
    width: 100%;

    .new-messaging-left-bar {
      flex: 0 0 40%;
      width: 40%;
      max-width: 40%;
    }
  }

  .new-messaging-section {
    .message-body {
      .message-content-row {
        .conversation {
          .single-message {
            max-width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .new-messaging-container {
    .new-messaging-left-bar {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }

    .show-msg-small {
      //display: initial !important;
    }

    .hide-msg-small {
      display: none !important;
    }

    .message-body {
      .message-title-row {
        margin-top: 5px;

        .thread-back-icon {
          display: block;
        }
      }
    }
  }
}