.app {
  background: $white;
}

.app-body-add-padding-override {
  padding-top: $header-top !important;
  padding-bottom: $footer-bottom !important;
}

.app-body-remove-padding-override {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.app-body-padding-override {
  padding-top: $top !important;
  padding-bottom: $bottom !important;
}

.app-body-padding-override-two {
  padding-top: $top !important;
  padding-bottom: $bottom !important;
}

.nav-tabs > li a,
.nav-tabs > li a:hover,
.nav-tabs > li a:focus {
  background-color: $white;
  border: 0;
}

body {
  padding: 0 !important;
}

a {
  outline: none !important;
}

label {
  cursor: pointer !important;
}

.disabled-pointer {
  pointer-events: none;
}

//ADDED FOR CHANGES

.container-fluid {
  padding: 0;
}

.scrollable-content {
  max-width: 100% !important;
  background-color: transparent !important;

  .max-width-container {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: relative;
    height: 100%;
    padding: 0;
  }

  .flex-row {
    flex-wrap: wrap;
  }

  .scrollable-content-inner {
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    background: none;
    min-height: 100%;
    position: relative;
  }
}

.jt-profile-timeline-view .jt-time-line-container {
  background-color: transparent;
}

.well {
  background-color: transparent;
}

.jt-profile-container {
  background: white;
}

.jt-profile-timeline-view {
  background-color: transparent;
}

.jt-about-box .jt-prof-top {
  background-color: white;
}

.jt-about-box {
  background-color: #f3f3f3;
}

.jt-album-add-photo {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.jt-album-info {
  background: white;
}

.jt-time-line-container {
  background-color: transparent !important;
}

.jt-event-item {
  background: white;
}

.jt-event-scrollable-content {
  background: white;
}

.jt-messenger-list-bg {
  background: white !important;
}

.jt-white-bg {
  background: white !important;
}

.jt-brand-search-box .jt-brand-search-header {
  padding: 11px 0;
}

.jt-empty-view-box {
  margin-top: 0;
  padding-top: 25px !important;
}

.jt-about-box {
  background-color: $white;
}

.jt-brand-about {
  background-color: $white;
}

.jt-clear-background {
  background-color: $white;
}

.jt-conversation-feed {
  max-width: 640px !important;
  margin-right: auto;
  margin-left: auto;
  background: #e8e8e8 !important;
}

.jt-chat {
  overflow-y: auto !important;
}

.jt-float-wrapper {
  width: 100%;
  float: left;
  background: red;
  min-height: 100vh;
}

.jt-webapp-primary-content .jt-webapp-content-center .jt-webapp-content-center-content {
  max-width: 100%;
}

.jt-webapp-primary-content .jt-webapp-content-center {
  width: 100%;
  margin-left: 0;
}

.jt-webapp-primary-content {
  padding-left: 0;
  padding-right: 0;
}

#page-content-wrapper {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

.jt-webapp-primary-content .jt-webapp-content-right {
  right: 21px;
}

/*.scrollable-content {
  padding-right: 286px;
  padding-left: 16px;
}*/

.modal {
  background: rgba(0,0,0,0.65);
  overflow-y: auto;
}


.modal-content {
  .scrollable-content {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.jt-timeline-base-container {
  position: relative;
}

.jt-timeline-container-bg {
  width: 100%;
  float: left;
  background: whitesmoke;
  border: 1px solid #E7E7E7;
}

.jt-webapp-mobile-logo {
  z-index: 501;
}

.jt-messages-container-bg {
  width: 100%;
  float: left;
}

.jt-messenger-list-view .jt-msg-btn-holder .jt-create-msg-btn {
  right: 20px;
  left: auto;
}

.jt-messenger-list {
  height: auto;
  background: white;
  padding-bottom: 95px;
}

.jt-time-line-container .jt-post-container .jt-post-comment-container {
  padding: 0;
}

.jt-brand-search-container-bg {
  padding: 0 15px 15px 15px;
}

.jt-brand-search-box .jt-brand-search-results {
  float: left;
  background: white;
}

.jt-brand-search-box {
  float: left;
  width: 100%;
  background: white;
  padding-left: 15px;
  padding-right: 15px;
  @include border-radius(2px);
}

.jt-messenger-list-view .jt-msg-btn-holder {
  
  position: fixed;
  bottom: auto;
  width: 100%;
  bottom: 60px;
  width: 100%;
  max-width: 640px;
  z-index: 500;
}

.jt-brand-profile-container {
  padding: 10px;
  overflow: auto;
}

.jt-profile-post-btn {
  padding-top: 0 !important;
}

.jt-profile-edit-container .jt-profile-edit-header {
  margin-bottom: 0;
}

.jt-profile-edit-container .jt-profile-edit-content-slot {
  background: white;
}

.jt-conversation-feed {
  max-width: 100% !important;
}

.jt-write-message {
  max-width: 100%;
  /*padding-right: 301px;
  padding-left: 15px;*/

  .jt-write-message-inner-bg {
    padding: 0 0;

    .jt-write-message-inner {

    }
  }
}

.jt-chat .jt-message {
  padding: 0 10px !important;
  margin: 20px 12px 50px 12px !important;
}

.jt-chat .jt-message div {
  max-width: 66% !important;
}

.jt-scrollable-none-more-pad {
  padding: 0 15px 0 15px !important;
}

.jt-about-content {
  ul {
    margin-bottom: 0;
  }
}

.jt-web-welcome-container .j-web-welcome-cover .jt-web-welcome-nav .jt-web-welcome-hero form.jt-web-login-form .jt-web-form-clear {
  margin-left: 0;
}

.jt-conversation-header {
  background-color: transparent;
  text-align: center;
  width: 100%;
  z-index: 200;
  margin-left: auto;
  margin-right: auto;
  border: none;
  padding-bottom: 10px;
  margin-top: -3px;
  padding-top: 9px;
}

#sidebar-wrapper {
  -webkit-overflow-scrolling: touch;
  //position: relative;

}

.jt-round-profile-img {
  img {
    margin-top: 0px;
    margin-right: 5px;
    width: 60px;
    height: 60px;
    border-radius: 100%;
  }
}

.sidebar-scrollable {
  width: 250px;
  background: black;

  .sidebar-scrollable-content {
    padding: 0 0;
    height: 100%;

    .sidebar-nav {
      padding-bottom: 15px;
    }
  }
}

.jt-linkfy {
  color: #0275ce !important;
}

.jt-post-comment-button-container {
  a {
    color: #0275ce !important;
  }
}

.jt-grouping-list-row {
  width: 100%;
  clear: both;
}

.jt-email-tag-row {
  padding: 0 10px !important;

  .jt-email-tag {
    width: 100%;
    max-width: 100%;
    margin: 0 0 10px 0 !important;

    &:last-child {
      margin: 0 0 4px 0 !important;
    }

    .jt-email-tag-bg {
      width: 100%;
      padding: 7px 10px !important;

      .jt-email-tag-text {
        width: 100%;
        padding-right: 42px;
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        span {
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .jt-email-tag-click {
        height: 35px !important;
      }
    }
  }
}

.sidebar-scrollable .sidebar-scrollable-content {
  overflow-x: hidden;
}

@media all and (max-width: 992px) {
  .scrollable-content {
    padding-right: 15px;
    padding-left: 15px;

    .max-width-container {
      width: 100%;
      padding: 0 30px;
    }
  }

  .jt-write-message {
    padding-right: 0;
    padding-left: 0;

    .jt-write-message-inner-bg {
      padding: 0 15px;

      .jt-write-message-inner {

      }
    }
  }

}

@media all and (max-width: 768px) {
  .jt-write-message {
    bottom: 0;

    .jt-write-message-inner-bg {

      .jt-write-message-inner {

      }
    }
  }
}

@media all and (max-width: 480px) {
  .jt-conversation-feed {
    padding-right: 0px;
    padding-left: 0;
  }
}

//ADDED FOR CHANGES

//Profile image cropper
//=====================
.imgCropper-controls {
  text-align: center;
  padding-top: 20px;
}

.jt-img-cropper-control {
  -moz-appearance: none;
  -webkit-appearance: none;
  font-size: 2rem;
  margin: 5px;
}

.jt-profile-image-cropper {
  //margin-top: 10%;
}

.jt-image-cropper-ok-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  font-size: 2rem;
  margin: 5px;
  background-color: #0275ce;
  color: #ffffff;
}

.jt-profile-picture-modal {
  z-index: 10002;

  a {
    color: $dark-gray;
  }
  .scrollable-height {
    max-height: 65vh;
    overflow-y: auto;
    //min-height: 65vh;
    height: auto;
  }
  ul {
    list-style: none;
  }

  .scrollable {
    position: relative;
  }
}

.jt-album-50width{
  width: 50%;
  height: 42px;
  float: left;
  position: relative;
}

.jt-album-50width .jt-file-camera {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}


//DARK ROOM IMAGE CROPPING

//.jt-profile-picture-cropping-modal {
//
//}
//
//.darkroom-container {
//  position: relative;
//  background: #e3e4e4;
//
//  &:after {
//    position: absolute;
//    width: 100%;
//    height: 52px;
//    background: coral;
//    top: 0;
//    left: 0;
//  }
//
//  .darkroom-toolbar {
//    width: 100%;
//    position: relative;
//    top: 0;
//    left: auto;
//    text-align: center;
//    background: black;
//    height: 52px;
//
//    &:before {
//      display: none;
//      opacity: 0;
//    }
//
//    .darkroom-button-group {
//      .darkroom-button {
//        width: 52px;
//        height: 52px;
//      }
//    }
//  }
//
//  .darkroom-image-container {
//    text-align: center;
//
//    .canvas-container {
//      margin: 0 auto;
//    }
//  }
//}
//
//@media all and (max-width: 610px) {
//
//  .jt-profile-picture-cropping-modal {
//    .scrollable-content {
//      padding: 0 0 !important;
//
//      .jt-profile-image-cropper {
//        padding: 0 0 !important;
//      }
//    }
//  }
//
//  .darkroom-container {
//
//    &:after {
//    }
//
//    .darkroom-toolbar {
//      height: 42px;
//      &:before {
//      }
//
//      .darkroom-button-group {
//        .darkroom-button {
//          width: 42px;
//          height: 42px;
//        }
//      }
//    }
//
//    .darkroom-image-container {
//
//      .canvas-container {
//      }
//    }
//  }
//}
//
//@media all and (max-width: 410px) {
//
//  .jt-profile-picture-cropping-modal {
//    .scrollable-content {
//
//      .jt-profile-image-cropper {
//      }
//    }
//  }
//
//  .darkroom-container {
//
//    &:after {
//    }
//
//    .darkroom-toolbar {
//      &:before {
//      }
//
//      .darkroom-button-group {
//        .darkroom-button {
//          width: auto;
//          height: auto;
//          padding: 7px 3px !important;
//        }
//      }
//    }
//
//    .darkroom-image-container {
//
//      .canvas-container {
//      }
//    }
//  }
//}

//DARK ROOM IMAGE CROPPING


.jt-profile-picture-cropping-model {
  .modal-dialog {
    max-width: 383px !important;
  }
}

.jt-image-cropping-container {
  background: #E8E8E8;
  width: 300px;
  height: 300px;
  text-align: center;
  display: table;
  padding: 20px;
  box-sizing: border-box;
  margin: 0 auto;
  overflow-x: hidden;

  .inner {
    display: table-cell;
    vertical-align: middle;
    overflow-x: hidden;
  }

  * {
    box-sizing: border-box;
  }

  img {
    max-width: 100%;
    height: auto;
    max-height: 400px;
    position: relative;
  }



  .profile-img-portrait {
    //height: 100%;
    //width: auto;
  }
}

.profile-img--landscape {
  img {
    max-width: 100%;
    height: auto;
    max-height: 400px;
  }
}

.profile-img-portrait {
  padding: 10%;
}

.jt-image-cropping-controls {
  width: 100%;
  display: block;
  background: gray;
  padding: 5px;
  text-align: center;
  position: relative;
  z-index: 10;
  overflow: hidden;

  button {
    .fa {
      @include font-size(20);
    }
  }

  .submit-bt {
    background: #0086ed;
    color: white;
    font-size: 14px;
    margin: 0 0;
    position: relative;
    top: -1px;
  }
}


.btn-group>.btn-default.active:hover {
  background-color: $blue;
  border-color: $blue-darker;
}



@media all and (max-width: 610px) {
  .profile-img-portrait {
    padding: 8%;
  }
}

@media all and (max-width: 480px) {
  .profile-img-portrait {
    padding: 5%;
    max-height: 300px;
  }

  .scrollable-content {
    padding: 0 !important;
  }

  .max-width-container {
    padding: 0 !important;
  }

  .mobile-postbox-wrapper {
    border-radius: 0;
  }

  .jt-time-line-container .jt-post-container {
    border-radius: 0 !important;
  }

  .jt-brand-search-container-bg {
    padding: 0;
  }

  .jt-scrollable-content-eventlist {
    .feed-view {
      .scrollable-content-inner {
        .jt-event-selector-box {
          padding-top: 20px;
        }
      }
    }
  }
}

@media all and (max-width: 380px) {
  .jt-profile-picture-cropping-model {
    .modal-dialog {
      max-width: 300px !important;
    }

    .jt-image-cropping-container {
      width: 260px;
      height: 260px;
    }

    .scrollable-content {
      padding: 0 0 !important;
    }

    .jt-profile-image-cropper {
      padding: 0 0 !important;
    }
  }
}

.jt-time-line-container .jt-post-container{
  background: #ffffff;
  //border-radius: 10px;
  @include border-radius(2px);
}


