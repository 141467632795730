.jt-album-photo-area {
  ul {
    width: 100%;
    padding: 0 8px;
    float: left;
    margin-bottom: 6px;
    margin-top: 2px;
  }
  ul li {
    display: inline-block;
    width: 25%;
    height: 100%;
    padding: 3px 3px;
    float: left;
    position: relative;
    .gallery-image {
      width: 100%;
      height: 100%;
      padding: 44% 0%;
      background-color: $white;
      background-size: cover;
    }
    input {
      position: absolute;
      top: 2px;
      right: 5px;
      height: 20px;
      width: 20px;
      display: none;
    }
    .overlay-check {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $black;
      opacity: 0.4;
      display: none;
    }
  }
  ul li img {
    width: 100%;
    height: 100%;
    border: solid 1px $blue-light;
    background-color: $white;
  }
}

.jt-album-info {
  padding: 8px;
  width: 100%;
  float: left;
  position: relative;
  margin: 10px 0 0 0;

  h5 {
    margin-bottom: 3px;
    padding-left: 0;
  }
  p {
    color: $dark-gray;
    margin-bottom: 3px;
    padding-left: 0;
    margin-bottom: 6px;
  }
  button.album-control-btn {
    border-radius: 0;
    background-color: $blue;
    color: $white;
  }
  .jt-album-header {
    width: 65%;
    float: left;
    word-wrap: break-word;
  }
  .jt-album-header-buttons {
    width: 35%;
    float: left;
    display: flex;
    justify-content: flex-end;
    button {
      min-height: 35px;
      padding: 11px 16px;
    }
    button:first-child {
      min-height: 35px;
      padding: 11px 16px;
      margin-right: 2px;
    }
  }
}

.jt-album-add-photo {
  width: 100%;
  float: left;
  padding: 0 8px;
  margin-bottom: 11px;
  margin-top: -9px;
  button {
    border-radius: 0;
    width: 100%;
    float: left;
    background-color: $blue;
    color: $white;
    font-size: 1.25rem;
    margin-bottom: 3px;
  }
  button.secondary {
    background-color: $blue-darker;
  }
  button.tertiary {
    width: 100%;
  }
}

.jt-images-modal {

  .jt-extended-menu {
    top: 2px;
    right: 12px;
    background-color: $white;
    opacity: 0.8;

    .dropdown-menu {
      top: 36px;
      left: -130px;
    }
  }

  .modal-dialog {
    margin: 0 auto !important;
  }
  .swipe-image-area {
    position: relative;
    img {
      width: 100%;
      height: auto;
    }
    .image-nav-buttons {
      img {
        width: 50px;
        height: 50px;
        opacity: 0.5;
      }
      .next-nav {
        position: absolute;
        top: 43%;
        right: 1%;
      }
      .pre-nav {
        position: absolute;
        top: 43%;
        left: 1%;
      }
    }
  }
  .swipe-image-description {
    width: 100%;
    padding: 15px;
  }
  .modal-body {
    height: 100vh;
  }
  .modal-header {
    padding: 3px;
  }

  width: 96%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid $gray-light;
  height: 75%;
  position: absolute;
  top: 10%;
  max-width: 600px;
}

.delete-btn {
  width: 100%;
  padding: 0 12px 12px 12px;
  button.delete-selected {
    background-color: $blue;
    color: $white;
    border-radius: 0;
    width: 100%;
    height: 44px;
  }
}

.jt-album-add-photos-list {
  img {
    float: left;
    margin-right: 10px;
  }
}

.jt-add-photo-btn-group {
  button {
    width: 50%;
  }
}

.album-form-validation-list {
  padding: 10px 0 0 0;
  list-style: none;
  color: $red;
}

.jt-pager-forms {
  width: 100%;
  float: left;
}

.jt-album-extended-menu {
  top: -33px;
  position: absolute;
}

.jt-album-modal {
  .jt-black-cover {
    position: absolute;
    width: 4800px;
    height: 4800px;
    background-color: black;
    opacity: 0.8;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .jt-modal-inner {
    .swipe-image-area {
      position: relative;
      .image-nav-buttons {
        position: absolute;
        width: 100%;
        top: 45%;
        a {
          img {
            width: 40px;
            height: 40px;
          }
        }
        .jt-next-nav {
          right: 5px;
          position: absolute;
        }
        .jt-pre-nav {
          left: 5px;
          position: absolute;
        }

      }
    }
    .swipe-image-description {
      margin-left: 10px;
      margin-bottom: -6px;
      margin-top: 4px;
    }
    .jt-album-extended-menu {
      top: 30px;
      right: 10px;
      .jt-extended-menu-list {
        position: absolute;
        left: -126px;
        top: 32px;
      }
    }

  }
}