.modal {
  .jt-time-line-container .jt-post-container .jt-post-comment-container .jt-post-view-more {
    display: none;
  }
}

.margin-top {
  margin-top: 10px;
}

.jt-post-article-header {
  margin-top: 0 !important;
}

.jt-post-article-header:hover {
  opacity: 0.8;
}

.jt-post-video-preview {
  padding: 10px 0;
}

.jt-post-video {
  max-height: none;
}

.jt-post-video:hover {
  .jt-post-video-play-icon {
    width: 84px !important;
    opacity: 0.8;
  }
}

.jt-profile-complate-warn {
  background-color: $pure-white;
  margin: 5px 0;
  padding: 10px 15px;
  font-size: 1.5rem;
  width: 100%;
  position: relative;
  display: block;
  @include border-radius(2px);

  .jt-profile-edit-icon {
    width: 15%;
    float: left;
    padding-right: 5px;

    img {
      width: 100%;
      max-width: 65px;
    }
  }

  .jt-profile-edit-info {
    width: 85%;
    float: left;
  }
}

.jt-post-loader-widget {
  text-align: center;
  z-index: 1;
  position: fixed;
  width: 100%;
  max-width: 640px;
  .jt-lbl {
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .jt-lbl.jt-lbl-loading {
    transition: visibility 1s ease-out;
    -webkit-transition: visibility 1s ease-out;
    -moz-transition: visibility 1s ease-out;
    -ms-transition: visibility 1s ease-out;
    -o-transition: visibility 1s ease-out;
    background-color: $white;
    color: $blue;
    @include font-size(30);
    border-radius: 100%;
    width: 45px;
    height: 45px;
    margin-bottom: -67px;
    margin-top: 22px;
    box-shadow: 0 1px 5px 0 $gray-light;
  }
  .jt-lbl.jt-lbl-new-posts {
    transition: visibility 1s ease-out;
    -webkit-transition: visibility 1s ease-out;
    -moz-transition: visibility 1s ease-out;
    -ms-transition: visibility 1s ease-out;
    -o-transition: visibility 1s ease-out;
    background-color: $blue;
    color: $white;
    width: 120px;
    margin-bottom: -58px;
    margin-top: 28px;
    box-shadow: 0 1px 10px 1px $gray-light;
    font-weight: bold;
    padding: 5px;
    padding-bottom: 7px;
    border-radius: 5px;

  }
  .jt-lbl.jt-lbl-new-posts:hover {
    background-color: $white;
    color: $blue;
  }

}

.jt-webapp-side-box {
  a{
    color: $black;
  }
}



.jt-time-line-container {
  word-wrap: break-word;
  float: left;
  background-color: $gray-lighter;
  width: 100%;

  .jt-post-container {
    background: $white;
    border: 1px solid $ash;
    margin: 5px 0;
    padding: 10px;
    position: relative;

    .jt-extended-menu {
      position: absolute;
      z-index: 50;
      top: 15px;
      right: 10px;

      ul {
        position: absolute;
        right: 10px;
        top: 31px;
        left: -160px;
      }
    }

    .jt-post-user-pic {
      width: 60px;
      height: 60px;
      border-radius: 60px;
      float: left;
      border: 3px solid $blue-lighter;
    }

    .jt-post-total-likes {
      padding-right: 10px;

    }

    .jt-post-total-posts {
    }

    .jt-post-counts {
      float: left;
    }

    .jt-post-user-details-outer-container {
      height: 60px;
      margin-left: 67px;
      float: none;
      display: table;

      .jt-post-user-details-inner-container {
        display: table-cell;
        vertical-align: middle;
        padding-right: 18px;
        word-wrap: break-word;

        a {
          color: $blue-darker;
          font-weight: bold;
          max-width: 280px;
          @include font-size(15);
        }

        span {
          color: $dark-ash;
          font-size: 1.2rem;
        }

        .post-user-job-company {
          span{
            font-size: 1.2rem;
            color: $dark-ash;
          }
        }
      }
    }

    .jt-post-caption {
      margin-top: 10px;
      @include font-size(16);
    }

    .jt-article-post {
      border: 1px solid $ash;
    }

    .jt-article-post-details {
      background-color: $pure-white;
      color: $black;
      padding: 5px 10px;
      margin-bottom: 10px;
      box-shadow: 0 0 0 1px rgba(0,0,0,.05), 0 2px 4px rgba(0,0,0,.15);

      a, a:hover {
        color: $black;
      }
    }

    .jt-post-article-title {
      font-weight: bold;
      font-size: 1.6rem;
      margin-bottom: 3px;
    }

    .jt-post-content {
      width: 100%;
      float: left;
      padding-bottom: 10px;

      img {
        width: 100%;
        margin-top: 10px;
        float: left;
        //border: 1px solid $ash;
      }

      .jt-shared-caption {
        margin-top: 10px;
        font-size: 16px;
        font-size: 1.6rem;
        float: left;
        display: block;
        word-break: break-word;
      }
    }

    .jt-shared-post-content {
      //padding: 10px 20px 20px;
      //margin-bottom: 5px;

      img {
        border: 1px solid $dark-ash;
      }
    }

    .jt-post-btn-container {
      float: left;
      width: 100%;
      margin-top: 10px;

      hr {
        margin: 0;
        margin-bottom: 10px;
        border-top-color: $gray-lighter2;
      }

      button {
        height: 30px;
        background-color: transparent;
        color: $gray;
        font-size: 15px;
        padding: 0;

        .btn-icon {
          width: 24px;
          margin-right: 5px;
        }

        .btn-text {
          font-size: 14px;
        }

        /*&:last-child {
          border: none;
        }*/
      }

      .timeLine-btn-comment {
        text-align: left;
      }

      .timeLine-btn-like {
        text-align: center;
      }

      .timeLine-btn-share {
        text-align: right;
      }
    }

    .jt-post-comment-container {
      float: left;
      width: 100%;
      background-color: #F6F7F8;
      margin-top: 10px;

      .jt-post-total-likes {
        padding: 0 0 10px;
        border-bottom: 1px solid $ash;

        a {
          color: $blue-darker;
        }
      }

      .view-more-comments-link {
        display: block;
        margin: 10px;
      }

      .jt-post-comment-box-container {
        width: 100%;
        float: left;
        position: relative;
        padding: 10px;

        &:hover .jt-delete-comment-parent {
          display: block;
        }

        .jt-delete-comment {
          position: absolute;
          top: 0;
          right: 0;
          font-weight: bold;
          font-size: 15px;
          color: $dark-ash;
          margin-right: 5px;
        }

        .jt-post-comment-box-container-child {
          .jt-delete-comment-child {
            top: 5px;
          }

          &:hover .jt-delete-comment-child {
            display: block;
          }
        }

        img.jt-post-commenter-pic {
          width: 40px;
          height: 40px;
          margin-top: 2px;
          border-radius: 100px;
          float: left;
          border: 3px solid $blue-lighter;
        }

        div.jt-post-comment-input-container {
          margin-left: 50px;
          border: 1px solid $ash;
          background-color: $white;

          textarea {
            width: 100%;
            max-width: 100%;
            min-height: 40px;
            padding: 10px;
            outline-color: transparent;
            border: none;
            @include font-size(15);
            resize: none;
            padding-right: 55px;

            &:empty:not(:focus):before {
              content: attr(data-text)
            }
          }

          button {
            margin-top: 5px;
            background-color: $blue-darker;
            float: right;
            color: $white;
            border-radius: 0;
            padding: 15px;
            border-radius: 100%;
            position: absolute;
            right: 10px;
            top: 7px;
          }
        }

        div.jt-comment-container {
          margin-left: 50px;
          @include font-size(14);
          word-wrap: break-word;
          margin-right: 20px;

          a {
            &:hover {
              text-decoration: underline;
            }
          }

          a.jt-user-name {
            font-weight: bold;
            margin-right: 5px;
            text-decoration: none;
          }

          .comment-user-company-job {
            font-size: 1.2rem;
            color: $dark-ash;
          }
        }

        .jt-child-comments {
          margin-left: 5px;
          margin-top: 30px;

          .jt-post-comment-box-container {
            border-left: 2px solid $ash;
            padding-left: 10px;
            padding-top: 10px;
            margin-top: 0;
            background: #FBFBFB;
            padding: 10px;
          }

          img.jt-post-commenter-pic {
            width: 30px;
            height: 30px;
          }

          .jt-child-comment-input-container {
            padding-left: 10px;
            margin-top: 10px;
            position: relative;

            textarea {
              height:50px;
            }

            button {
              padding: 15px;
              top: 0;
            }
          }
        }

      }

      .jt-post-view-more {
        margin-top: 10px;
        display: block;
      }

      .jt-post-comment-button-container {
        margin-left: 50px;
        margin-top: 5px;
        a {
          padding: 0 5px 0 0;
          border-right: 1px solid $ash;
          display: inline-block;
          float: left;

          &:last-child {
            border: none;
          }
        }

        span {
          padding: 0 5px;
          color: $dark-ash;
          display: inline-block;
          float: left;
        }
      }
    }

  }
  .jt-timeline-count-seperator {
    border-right: 1px solid $ash;
    padding-right: 10px
  }
}

.jt-post-modal {
  .jt-post-counts {
    a {
      color: $blue;
    }

    a:hover {
      text-decoration: underline;
      color: $blue;
    }
  }

  div.jt-comment-container {
    a {
      color: $blue;
    }

    a:hover {
      text-decoration: underline;
      color: $blue;
    }
  }
}

.feed-view {
  aside {
    display: block;
    position: relative;
    height: 100%;
    width: 20%;
    padding: 0 0 10px;
    text-align: center;

    h3{
      color: $input-gray;
      margin-bottom: 36px;
    }

    .jt-webapp-side-box img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }

  }

  .jt-timeline-ad-card {
    border: 1px solid $gray-lighter2;
    background-color: #ffffff;
    padding: 5px;
    margin-bottom: 2px;
    @include border-radius(2px);

    .jt-webapp-side-box {
      margin: 0;
    }
  }

  .left-bar {
    float: left;

    .jt-timeline-ads-left {
      position: fixed;
      //width: 13%;
      //padding-right: 15px;

      .jt-timeline-ad-card {
        margin-right: 5px;
      }
    }
  }

  .right-bar {
    float: right;

    .jt-timeline-ads-right {
      position: fixed;
      //width: 13%;
      //padding-right: 15px;

      .jt-timeline-ad-card {
        margin-left: 5px;
      }
    }
  }
}

.pr-ng-gal-thumbnail-holder-bg {
  //width: 25%;
  //height: 150px;
  float: left;
  padding: 2px;
  display: inline-block;
  width: 100%;

  img:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.ng-gallery-holder-couple, .ng-gallery-holder-quad {
  width: 50%;
  height: 260px;
}

.ng-gallery {
  .ng-gallery-holder-three {
    width: 50%;
    height: 260px;
  }

  .ng-gallery-holder-three-land {
    width: 100% !important;
  }

  .ng-gallery-holder-three-por {
    height: 520px !important;
  }
}

.ng-gallery-extra {
  text-align: center;
  //top: 30%;
  position: relative;
  font-size: 55px;
  font-weight: bold;
  width: 100%;
  height: 100%;
  display: table;
  color: #fff;
  background-color: rgba(0, 0, 0, .4);

  span {
    vertical-align: middle;
    display: table-cell;
  }
}

.ng-gallery-hide-extra {
  display: none;
}

.pr-ng-gal-thumbnail-holder {
  width: 100%;
  height: 100%;
  display: block;
  -khtml-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
}

.pr-ng-gal-thumbnail-holder:hover {
  cursor: pointer;
  opacity: 0.8;
}

.ng-gallery-content {
  img {
    float: none !important;
    padding-left: 15px;
    padding-right: 15px;
    width: auto !important;
    max-width: 100%;
  }
}

.gallery-open {
  #jt-nd-navbar, .site-footer-menu {
    display: none;
  }

  .jt-webapp-primary-content {
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 1200px) {
  .feed-view {
    aside {
      h3 {
        font-size: 1.5rem;
        margin-bottom: 40px;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .feed-view {
    aside {
      h3 {
        display: block;
        height: 34px;
        margin-bottom: 20px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .left-bar {
    display: none !important;
  }

  .right-bar {
    display: none !important;
  }

  .uil-ring-css {
    top: 0;
  }
}

@media only screen and (max-width: 600px) {
  .ng-gallery-holder-couple, .ng-gallery-holder-quad {
    height: 200px;
  }

  .ng-gallery {
    .ng-gallery-holder-three {
      height: 200px;
    }

    .ng-gallery-holder-three-por {
      height: 400px !important;
    }
  }
}

@media only screen and (max-width: 480px) {
  .jt-time-line-container {
    .jt-post-container {
      .jt-post-btn-container {
        button .btn-icon {
          width: 20px;
        }
        span.btn-text.ng-binding {
          font-size: 11px;
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .ng-gallery-holder-couple, .ng-gallery-holder-quad {
    height: 150px;
  }

  .ng-gallery {
    .ng-gallery-holder-three {
      height: 150px;
    }

    .ng-gallery-holder-three-por {
      height: 300px !important;
    }
  }
}

@media only screen and (max-width: $zeta) {
}

@media only screen and (max-width: $alpha) {
  .jt-post-user-details-outer-container {
    max-width: 180px;
  }
}

@media only screen and (max-width: $delta) {
  .jt-post-user-details-outer-container {
    max-width: 350px;
  }
}

@media only screen and (max-width: $gamma) {
  .jt-post-user-details-outer-container {
    max-width: 325px;
  }
}

@media only screen and (max-width: $beta) {
  .jt-post-user-details-outer-container {
    max-width: 190px;
  }

  /*.jt-time-line-container {
    .jt-post-container {
      .jt-post-btn-container {
        button .btn-icon {
          width: 16px;
        }
        span.btn-text.ng-binding {
          font-size: 9px;
        }
      }
    }
  }*/
}

