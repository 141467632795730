.jt-brand-search-box {
  width: 100%;
  .jt-brand-search-header {
    width: 100%;
    margin: 5px 0;
    width: 100%;
    color: $blue;
    text-align: center;
    margin: 11px 0;
    .brand-search-header {
      width: 100%;
      color: $blue;
      text-align: center;
    }
  }
  .jt-brand-search-area {
    width: 100%;
    margin: 5px 0;
    .jt-brand-search {
      width: 100%;
      .search-friends {
        padding: 0 5px;
      }
    }
  }
  .jt-brand-search-results {
    width: 100%;
    margin: 68px 0px;
    .search-result-list {
      width: 100%;
      clear: both;
      float: left;
      text-align: center;
      .brand-search-result {
        width: 33.33%;
        float: left;
        padding: 5px;
        justify-content: center;
        text-align: center;
        .search-result-image {
          display: flex;
          justify-content: center;
          img {
            width: 95px;
            height: auto;
            border: 3px solid $blue-light;
            border-radius: 100%;
          }
        }
        .search-result-name {
          display: inherit;
          justify-content: center;
          color: $blue;
          font-size: 1.5rem;
          @include text-truncate();
          text-align: center;
          font-weight: bold;
        }
        .search-result-following {
          cursor: hand;
          display: block;
          justify-content: center;
          color: $following;
          width: 100%;
          float: left;

          .search-result-follow-inner {
            width: auto;
            margin: 0 auto;
            display: inline-block;
          }
          .icon {
            font-size: 2.2rem;
            color: $following;
            padding-right: 3px;
            cursor: hand;
            float: left;
            clear: none;
          }
          p {
            padding-top: 7px;
            font-weight: bolder;
            float: left;
            clear: none;
            font-size: 11px;
          }
          a {
            text-decoration: none;
            color: $following;
          }
        }
        .search-result-follow {
          cursor: hand;
          display: block;
          justify-content: center;
          color: $blue;
          width: 100%;
          float: left;

          .search-result-follow-inner {
            width: auto;
            margin: 0 auto;
            display: inline-block;
          }

          .icon {
            font-size: 2.2rem;
            color: $blue;
            padding-right: 3px;
            float: left;
            clear: none;
          }
          p {
            padding-top: 7px;
            font-weight: bolder;
            float: left;
            clear: none;
            font-size: 11px;
          }
          a {
            text-decoration: none;
            color: $blue;
          }
        }
      }
    }
  }
}