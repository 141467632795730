.navbar .logo-box {
    background: $blue;
}

.jt-global-loader {
    border-radius: 10px;
    width: 350px;
    background-color: $blue;
    color: $white;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    top: 20px;
    z-index: 10000;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.18);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.18);
    .jt-global-loader-loading {
        margin-left: 10px;
        margin-top: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        width: 43px;
        height: 43px;
        float: left;
        color: $white;
        @include font-size(30);
    }
    .jt-global-loader-text {
        text-align: center;
        float: left;
        width: 260px;
        margin-top: 22px;
        margin-right: 10px;
        h3 {
            color: $white;
            @include font-size(16);
            margin: 0;
        }
    }
}

////////////////// ANGULAR ANIMATIONS ///////////////////
.jt-show-hide {
  -webkit-transition:all linear 0.5s;
  transition:all linear 0.5s;
}

.jt-show-hide.ng-hide {
  opacity:0;
}

.jto-header{
    border-radius: 0;

    .jto-search-main {
        margin-bottom: 15px;
        position: relative;

        .jto-logo-container {
            position: absolute;
            left: 0;
            cursor: pointer;
            z-index: 10;
        }

        .jto-search-links {
            color: #fff;
            padding: 0 54px 0 160px;

            .jto-link-items {
                margin-bottom: 5px;
            }

            .jto-search-btn {
                padding-left: 0;
                z-index: 1;
            }

            .jto-search-title {
                padding-right: 0;

                input {
                    border-radius: 4px 0 0 4px
                }
            }

            .jto-search-location {
                padding-left: 0;

                input {
                    border-radius: 0 4px 4px 0;
                }
            }

            span {
                // padding: 0 5px;
            }

            a {
                color: #fff;
            }
        }

        .jto-mega-menu {
            position: absolute;
            right: 0;
            text-align: right;
            bottom: 0;
            margin: auto;
            top: 0;
            height: 25px;
            color: #fff;
            font-size: 25px;
        }
    }

    .jto-header-top {
        background: $secondary-blue;
        padding: 8px 0;

        ul {
            padding: 0 12px;
            margin-top: 2px;

            li {
                float: left;
                margin-right: 32px;
                color: #fff;

                a {
                    color: #fff;
                }

                a:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.jto-user-profile {

    .dropdown {
        float: right;

        .jto-name {
            position: relative;
            top: 1px;
            max-width: 170px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            vertical-align: text-bottom;
        }
        .login-panel {
           z-index: 1000; 
           color: #fff;
            a {
                 color: #fff;
            }
        }
    }

    .dropdown-menu {
        right: 0;
        left: auto;
        padding: 2px !important;
        background: #fff;
        border: 1px solid;
        border-color: #e5e6e9 #dfe0e4 #d0d1d5;
        border-radius: 3px;

        li {
            margin-right: 0;
            width: 100%;

            a {
                color: #333 !important;
                padding: 8px 20px;
            }
        }

        .space {
            height: 1px;
            background-color: #eae6e6;
        }
    }

    .btn {
        background: transparent !important;
        height: auto !important;
        color: #fff !important;
        border: 0;
        text-shadow: none !important;
        box-shadow: none !important;
        padding: 0;
    }

    .jto-pro-pic {
        border-radius: 64px;
        height: 25px;
        margin-right: 4px;
    }
}




.jto-navbar {
    position: relative;
    min-height: auto;
    margin-bottom: 0;
    border: none;
}