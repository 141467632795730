.jt-write-post-content {
  padding: 8px;
  ::-webkit-input-placeholder {
    color: $gray;
  }
  :-moz-placeholder {
    color: $gray;
  }
  ::-moz-placeholder {
    color: $gray;
  }
  :-ms-input-placeholder {
    color: $gray;
  }
  .jt-post-area {
    width: 100%;
    padding: 10px;
    height: 160px;
    float: left;
    border: 1px solid $gray-light;
    border-bottom: 0;
    border-radius: 0;
    resize: none;
    textarea {
      border: 1px solid $gray;
    }
  }
  .jt-post-controls {
    float: left;
    width: 100%;
    position: relative;

    a {
      text-decoration: none;
      color: $gray;
    }
    ul {
      width: 100%;
      float: left;
      margin-bottom: 0;
      padding-left: 10px;
      padding-right: 10px;
      background-color: $gray-lighter;
      padding-top: 4px;
      padding-bottom: 4px;
      font-size: 2.5rem;
      border-left: 1px solid $gray-light;
      border-bottom: 1px solid $gray-light;
      border-top: 1px solid $gray-light;
      li {
        display: inline-block;
        padding-right: 15px;
        width: 43px;

        button.jt-camera-btn {
          color: $gray;
          z-index: 500;
          position: absolute;
          left: 2px;
          text-align: center;
          width: 50px;
          padding: -3px;
          min-width: 0px;
          min-height: 1px;
          height: 36px;
          top: 7px;
          background-color: transparent;

        }
        .jt-file-camera {
          position: absolute;
          opacity: 0;
          width: 25px;
        }
      }
    }
    li:last-child {
      padding-right: 0;
    }
  }
  button {
    width: 20%;
    min-width: 82px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    float: left;
    background-color: $blue;
    color: $white;
    border-radius: 0;
    line-height: 15px;
    font-size: 1.5rem;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    padding: 0;
  }
  button.active {
    background-color: $blue;
  }
}

.jt-friends-modal-tag {
  .tag-tick {
    margin-top: 27px;
    margin-right: 6px;
    float: right;
    .tag-check-box {
      height: 15px;
      width: 15px;
    }
  }
  a {
    color: $dark-gray;
  }
  .scrollable-height {
    height: 80vh;
  }
  ul {
    list-style: none;
  }


  .list-tag-item {
    height: 100px;
    border: 0;
  }

  a.list-tag-item {
    background-color: $white;
  }
}

.jt-album-photo-area {
  padding: 0 5px;

  ul.write-post-photos {
    padding: 0;
    li {
      padding: 3px;
      position: relative;
      .overlay-delete {
        position: absolute;
        width: 98%;
        height: 98%;
        background-color: $black;
        opacity: 0.2;
        z-index: 1;
        top: 1px;
        left: 2px;

      }
      .overlay-delete-btn {
        position: absolute;
        width: 98%;
        height: 98%;
        color: #ffffff;
        z-index: 2;
        text-align: right;
        top: 0px;
        margin: 0;
        font-size: 2.5rem;
        padding-right: 10px;
      }

    }
  }
  a {
    color: $white;
    text-decoration: none;
  }
}

.jt-write-post-scrollable {
  .jt-album-photo-area {
    margin-top: 157px;
  }
}

.jt-clear-write-post {
  clear: both;
  margin-bottom: -28px !important;
}

ul.write-post-photos {
}

div.jt-album-photo-area {
  clear: both;
  padding-top: 15px;
}

.jt-photo-block-area {
  display: inline-block;
  width: 100%;
}