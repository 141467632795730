
$blue:              #0086ed;
$blue2 :            #176ce0;
$blue-darker:       #0275ce;
$dark-blue:         #002f53;
$blue-light:        #57cfff;
$blue-lighter:      #8ddbff;
$white:             #f1f1f1;
$black:             #000000;
$light-gray:        #dedede;
$dark-gray:         #2e2e2e;
$gray:              #626262;
$gray-lighter:      #f1f1f1;
$gray-light:        #d8d8d8;
$orange:            orange;
$red:               red;
$blue-extra-dark:   #006bbd;
$following:         #76faca;
$blue-shade:        #8bc5f5;
$ash:               #EAEAEA;
$dark-ash:          #9197a3;
$red-issue:         #DC3847;
$off-white:         #FFF4DE;
$green:             #30cd73;
$secondary-blue:	#1466C9;
$main-blue: 		#176ce0;
$pure-white:        #fff;
$slategray:         #c7d1d8;
$gray-lighter2:     #e5e9ec;
$unread-blue:       #edf2fa;
$bright-jc-blue:    #098bee;
$jc-background:     #f5f5f5; //#e3e2e1;
$input-gray :       #888;
$jito-pink:         #eb004e;

$gt-images: "../../img/";

$primary-color:     $blue;
$secondary-color:   $blue-light;
$gtBodyBg:          $white;

$alpha: 340px;
$beta: 380px;
$gamma: 480px;
$delta: 620px;
$epsilon: 768px;
$zeta: 992px;
$eta: 1200px;
$theta: 1368px;
$iota: 1420px;
$maxSize: 2400px;

$header-top: 108px;
$footer-bottom: 58px;

$top: 110px;
$bottom: 60px;
$top-one: 80px;
$bottom-one: 50px;

$sidePadding: 10px;

$max-width: 1200px;
