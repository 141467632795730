//Classes to toggle when the user is not logged in
.jt-no-show {
  display: none !important; //To nullify the js and media queries
}

#wrapper.jt-web-app-not-in {
  padding-left: 0;
  #page-content-wrapper {
    padding: 0;
    .container-fluid {
      margin-right: 0;
      margin-left: 0;
      padding-left: 0;
      padding-right: 0;
    }
    .jt-webapp-content-center-content {
      width: 100%;
      max-width: 100%;
    }
    .jt-webapp-content-center {
      width: 100%;
      margin-left: 0;
    }
  }
}

//END of classes to be toggled when the user is not logged in


.jt-not-logged-nav {
  width: 100%;
  height: 88px;
  margin-bottom: -74px;
  background: $blue2;
  position: absolute;
  z-index: 1;
  .jt-not-logged-logo {
    width: auto;
    color: $white;
    height: 100%;
    padding-top: 7px;
    float: left;
    cursor: pointer;
    padding-left: 50px;

    a {
      display: block;
      color: $white;
      text-decoration: none;
    }
  }
  .jt-not-logged-nav-btns {
    height: 100%;
    float: right;
    padding-right: 50px;

    a {
      height: 100%;
      background-color: $blue2;
      @include css3-prefix(transition, background .25s ease-in-out);
      text-align: center;
      color: $white;
      font-weight: 700;
      float: left;
      @include font-size(15);
      position: relative;
      padding: 18px 5px;

      &:last-child {
        padding-right: 0;
      }

      span {

        img {
          max-width: 170px;
        }
        .fa {
          @include font-size(18);
        }
      }
    }
    a.jt-this-is-web {
      background-color: $blue-darker;
    }
    a:hover {
      //background-color: $blue-darker;
      //@include css3-prefix(transition, background .25s ease-in-out);
    }
    button.jt-web-nav-sign-btn {
      background-color: $blue-darker;
      @include css3-prefix(transition, background .25s ease-in-out);
      @include font-size(15);
      color: $white;
      font-weight: 700;
      float: left;
      margin-top: 19px;
      margin-left: 15px;
      margin-right: 20px;
    }
    button.jt-web-nav-sign-btn:hover {
      background-color: $blue-extra-dark;
      @include css3-prefix(transition, background .25s ease-in-out);
    }
  }
}

button {
  padding-top: 15px;
  padding-bottom: 15px;
}

.jt-not-logged-footer {
  width: 100%;
  background: -moz-linear-gradient(right, #ff9e51, #ff8cc5);
  background: -webkit-linear-gradient(right, #ff9e51, #ff8cc5);
  background: -ms-linear-gradient(right, #ff9e51, #ff8cc5);
  background: -o-linear-gradient(right, #ff9e51, #ff8cc5);
  background: linear-gradient(right, #ff9e51, #ff8cc5);
  position: fixed;
  bottom: 0;
  z-index: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  ul {
    list-style: none;
    margin-top: 6px;
    li {
      display: inline;
      a {
        color: $white;
        font-weight: 700;
        padding-right: 15px;
        text-align: left;
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }

  .jt-webapp-social-bar {
    padding-right: 50px;
  }
  .jt-webapp-social-icon {
    width: 35px;
    height: 35px;
    float: right;
    margin-right: 0;
    margin-left: 15px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
    opacity: 1;
    @include css3-prefix(transition, opacity 0.25s ease-in-out);
  }
  .jt-webapp-social-icon:hover {
    opacity: 0.7;
    @include css3-prefix(transition, opacity 0.25s ease-in-out);
  }
}


.jt-web-app-bg {
  background-color: $gray-lighter;
}
.jt-sidebar-brand {
  margin-top: -20px;
  text-indent: -28px !important;
  background-color: $blue;
  padding-top: 6px;
  padding-bottom: 0.2px;
  a {
    color: $white !important;
  }
}

.jt-sidebar-brand:hover {
  background-color: $blue-light;
}

.jt-side-nav-item {
  text-indent: 42px !important;
  margin-top: 0;
  a {
    padding-top: 15px;
    padding-bottom: 15px;
    img {
      width: 60px;
      height: 60px;
      opacity: 1;
      margin-right: 5px;
    }
  }
  a:hover {
    img {
      opacity: 1;
    }
  }
}

.jt-top-nav-holder {
  width: calc(100% - 180px);
  margin-left: -40px;
  .jt-top-nav {
    max-width: 630px;
    margin-left: auto;
    margin-right: auto;
    .jt-top-nav-search {
      width: 50%;
      float: left;
      margin-top: 14px;
      padding-right: 10px;
    }
    .jt-top-nav-nav {
      width: 50%;
      float: left;
      .jt-webapp-mobile-search-btn {
        display: none;
      }
    }
  }
}

.jt-webapp-header {
  height: 74px;
  background-color: $blue-darker;
  position: fixed;
  top: 0;
  width: calc(100% - 235px);
  z-index: 1000;
  left: 264px;
}

.jt-input-addon-left {
  background-color: $white;
  border-radius: 20px;
}

.jt-input-round-btn {
  border-radius: 20px;
  height: 43px;
}

.jt-search-input {
  height: 43px;
}

ul.jt-web-nav-btn-holder {
  list-style: none;
  margin-top: 19px;
  margin-right: -50px;
  li {
    display: inline;
    .jt-web-nav-round-btns {
      background-color: $white;
      cursor: pointer;
      padding: 13px;
      padding-left: 15px;
      padding-right: 15px;
      color: #006dc0;
      border-radius: 100%;
      opacity: 1;
      position: relative;
      @include css3-prefix(transition, opacity .25s ease-in-out);

      .fa {
        width: 13px;
        display: inline-block;
      }

      .fa-search {
        position: relative;
        top: -1px !important;
      }

      .icon {
        width: 13px;
        display: inline-block;
      }

    }
    .jt-web-nav-round-btns:hover {
      opacity: 0.8;
      @include css3-prefix(transition, opacity .25s ease-in-out);
    }


  }
}

.jt-nav-exit-section {
  height: 74px;
  width: 180px;
  position: fixed;
  right: 0;
  // top: 0;
  padding-left: 18px;
  .jt-mobile-logout {
    display: none;
  }
  img {
    margin-top: 6px;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    border: 3px solid $white;
  }
  float: left;
  .dropdown {
    margin-top: -48px;
    margin-left: 57px;
    .btn, .btn:active, .btn:hover, .btn:focus {
      background-color: transparent;
      border: transparent;
      outline: none;
      box-shadow: none;
    }
    .btn:active, .btn:hover, .btn:focus {
      color: $white;
    }
    ul.dropdown-menu-right {
      top: 34px;
      right: 15px;
    }
  }
  h5 {
    float: right;
    margin-right: 30px;
    margin-top: -28px;
    font-size: 13px;
    color: $white;
  }
  .jt-webapp-exit-menu {
    position: absolute;
    left: -136px;
  }
}

.jt-webapp-primary-content {
  .jt-webapp-content-center {
    width: calc(100% - 250px);
    margin-left: -10px;
    .jt-webapp-content-center-content {
      max-width: 640px;
      height: 800px;
      background-color: $jc-background;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .jt-webapp-content-right {
    background-color: $gray-lighter;
    height: auto;
    width: 250px;
    position: absolute;
    top:0;
    right: 0;
    //overflow: scroll;
  }
}
.jt-webapp-logo {
  height: 74px;
  width: 250px;
  background-color: $blue;
  color: $white;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  //z-index: 1001;
  z-index: 550;

  a {
    display: block;
    color: $white;
    text-decoration: none;
  }
  h2 {
    margin-top: 6px;
  }
  h5 {
    @include font-size(15);
  }
}

.jt-webapp-mobile-logo {
  display: none;
  height: 74px;
  width: 250px;
  z-index: 500;
  background-color: $blue;
  color: white;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  .jt-hamburger {
    background: $blue-darker;
    padding-left: 15px;
    padding-right: 17px;
    padding-bottom: 6px;
    padding-top: 21px;
    float: left;
    padding-left: 15px;
    color: $white;
    opacity: 0.5;
    @include css3-prefix(transition, opacity .25s ease-in-out);
  }
  .jt-hamburger:hover {
    opacity: 1;
    @include css3-prefix(transition, opacity .25s ease-in-out);

  }
  .jt-rest-logo {
    float: left;
    margin-top: -70px;
    margin-left: 83px;
    h6 {
      @include font-size(15);
    }
  }
}

.jt-mobile-search-bar {
  /*height: 35px;*/
  width: 106%;
  background: $blue-extra-dark;
  border-top: 1px solid $blue ;
  position: fixed;
  top: 74px;
  left: 0px;
  input {
    width: 100%;
    margin-left: 7px;
    margin-top: 6px;
    background: $blue-extra-dark;
    color: white;
    border: none;
  }
}

.jt-mobile-search-bar.jt-hide-bar {
  opacity: 0;
  height: 0;
  z-index: -1;
  display: none;
  @include css3-prefix(transition, opacity 0.5s ease-in-out);
}

.jt-mobile-search-bar.jt-show-bar {
  opacity: 1;
  height: 35px;
  @include css3-prefix(transition, opacity 0.5s ease-in-out);
}

.jt-webapp-side-box {
  cursor: pointer;
  width: 100%;
  background: transparent;
  //border: 1px solid $ash;
  margin-bottom: 10px;
  h5 {
    word-break: break-word;
  }
  p {
    word-break: break-word;
  }
  img {
    height: auto;
    width: auto;
    max-width: 228px;
    max-height: 200px;
  }
}

.jt-webapp-static-text {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 2;
  margin-top: 0;
  padding-bottom: 100px;
  padding-left: 10%;
  padding-right: 10%;
  background-color: $gray-lighter;
  p {
    font-weight: 700;
  }
  ul {
    font-weight: 700;
  }
  h5 {
    @include font-size(25);
  }
}

.jt-header-top-btns {
  display: inline-block;
  background: #FFF;
  border: 0;
  border-radius: 100%;
  width: 37px;
  height: 37px;
  margin-right: 2px;
  cursor: pointer;
}

.jt-header-top-btns:hover {
  opacity: 0.8;
  @include css3-prefix(transition, opacity .25s ease-in-out);
}

.jt-header-top-btn-img {
  width: 15px;
  height: 15px;
  margin-top: 10px;
}

.jt-connect-btn-img {
  width: 22px;
  height: 22px;
  margin-top: -5px;
}


@media screen and (max-width: 992px) {
  .jt-webapp-primary-content {
    // margin-top: 84px;

    .inline-login {
      padding-top: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .jt-webapp-primary-content {
    // margin-top: 76px;
  }
}

@media screen and (max-width: 480px) {
  .jt-webapp-primary-content {
    // margin-top: 67px;
    .inline-login {
      padding-top: 26px;
    }

    .jt-write-post-content {
      margin-top: 3px;
    }
  }

  .jt-not-logged-footer {
    ul {
      float: none !important;
      padding-left: 0;

      li, li a {
        font-size: 12px !important;
      }
    }
  }
}