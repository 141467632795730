.right-margin-5 {
  margin-right: 5px;
}

.left-margin-5{
  margin-left: 5px;
}

.icon-size{
  font-size: 18px !important;
}