.jt-profile-edit-container {
  background: #f8f8f8;
  .jt-profile-edit-header {
    background: $gray;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #c4c4c4 1%, #969696 11%, #626262 50%, #969696 87%, #c4c4c4 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #c4c4c4 1%, #969696 11%, #626262 50%, #969696 87%, #c4c4c4 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #c4c4c4 1%, #969696 11%, #626262 50%, #969696 87%, #c4c4c4 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c4c4c4', endColorstr='#c4c4c4', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    width: 100%;
    float: left;
    margin-bottom: 10px;
    .jt-profile-edit-header-uname {
      width: 100%;
      float: left;
      padding: 25px 15px;
      text-align: center;
      h3 {
        @include font-size(18);
        color: $white;
        font-weight: $gt-font-weight-light;
      }
    }
    .jt-profile-eidt-header-img {
      width: 100%;
      float: left;
      text-align: center;
      padding: 18px 15px 0 15px;
      img {
        width: auto;
        height: auto;
        max-width: 120px;
        float: none;
        margin: 0 auto;
        @include border-radius('50%');
        border: 5px solid $blue-lighter;
      }
      .jt-file-camera-img {
        position: absolute;
        opacity: 0;
        width: 120px;
        height: 120px;
        margin: 0 auto;
        left: -16px;
        right: 0;
        overflow: hidden;
        border-radius: 50%;
      }
    }
    .jt-profile-edit-header-label {
      width: 100%;
      float: left;
      text-align: center;
      padding: 15px 15px;
      p {
        color: $white;
        padding: 0;
        margin: 0;
        @include font-size(14);
        color: $white;
        font-weight: $gt-font-weight-light;
      }
      .jt-file-camera-text {
        position: absolute;
        opacity: 0;
        width: 121px;
        height: 15px;
        margin: 0 auto;
        left: 0;
        right: 0;
      }
    }
  }
  .jt-profile-edit-content-slot {
    padding: 8px;
    width: 100%;
    float: left;
    .jt-profile-edit-content-slot-inner {
      .jt-profile-edit-row {
      }
    }
  }
}

.animate-show {
  line-height: 20px;
  opacity: 1;
  padding: 10px;
  border: 1px solid black;
  background: white;
}

.animate-show.ng-hide-add,
.animate-show.ng-hide-remove {
  transition: all linear 0.5s;
}

.animate-show.ng-hide {
  line-height: 0;
  opacity: 0;
  padding: 0 10px;
}

.edit-row {
  border-bottom: 1px solid $light-gray !important;
}

.prof-edit-sub-btn {
  margin-left: 9px;
}

.change-password-section {
  hr {
    width: 106%;
    margin-top: 8px;
    margin-bottom: 3px;
  }
}

.jt-skip-row {
  text-align: center;
  a.jt-skip-text {
    color: $gray;
    display: block;
    padding: 1px;
  }
  a.jt-skip-text:hover {
    color: $gray;
    display: block;
    padding: 1px;
  }
}