.jt-email-tag-row {
  clear: both;
  .jt-email-tag {
    position: relative;
    display: inline-block;
    float: left;
    margin: 0 0 7px 5px;
    .jt-email-tag-bg {
      background-color: $blue;
      border-radius: 5px;
      padding: 3px 10px;
      display: inline-block;
      .jt-email-tag-text {
        color: $white;
        @include font-size(15);
        span {
          a {
            text-decoration: none;
            color: $white;
          }
        }
      }
      .jt-email-tag-click {
        background-color: black;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        display: inline-block;
        height: 26px;
        z-index: 1000;
        opacity: 0;
      }
    }
  }
}
