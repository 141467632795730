//Fonts
@import url("https://use.typekit.net/jml4rgr.css");

// Font Variables
$h1-large: "anton",sans-serif;
$regular: "proxima-nova", sans-serif;
//Colour Variables
$bright-pink: #fb447c;
$medium-pink: #ff9fb3;
$light-pink:#ffdfe3;

.jt-table-attendee-box .btn {
  //background: -webkit-linear-gradient(right, #ff9e51, #ff8cc5) !important;
  border: none !important;
}

.jt-table-attendee-box .jt-cancel-bt {
  background: #9197a3 !important;
  border: 1px solid #838a97 !important;
}

.jt-btn {
  background: -moz-linear-gradient(right, #ff9e51, #ff8cc5);
  background: -webkit-linear-gradient(right, #ff9e51, #ff8cc5);
  background: -ms-linear-gradient(right, #ff9e51, #ff8cc5);
  background: -o-linear-gradient(right, #ff9e51, #ff8cc5);
  background: linear-gradient(right, #ff9e51, #ff8cc5);
  padding: 15px 25px;
  color: $white;
  text-align: center;
  font-weight: $gt-font-weight-bold;
  text-transform: uppercase;
  @include font-size(15);
  @include border-radius(4px);
  border: none;
  font-family: $regular;

  &:hover {
    opacity: 0.9;
  }
}

.jt-cancel-bt {
  background: $dark-ash;
  border: 1px solid darken($dark-ash,5%) !important;

  &:hover {
    background: darken($dark-ash,5%);
  }
}

.jt-btn.jt-web-btn {
  text-transform: none;
}

.jt-btn-secondary {
  background: $blue-light;
  border: 1px solid $blue-light;
  margin-top: 10px;
}

.jt-text-link {
  color: $blue-darker;
  padding: 7px 15px;
  font-weight: bold;
}

.jt-text-link-secondary {
  color: $blue-darker;
  padding: 7px 15px;
  font-weight: bold;
  padding-left: 10px;
}

.jt-btn-primary {
  background: $blue-shade;
  border-radius: 0;
  color: $white;
  font-weight: bold;

  &:hover,
  &:focus,
  &:active:hover {
    @extend .jt-btn-primary;
  }
}

button {
  @include css3-prefix('appearance', none);
  border: none;
  padding: 8px 20px;
  min-height: 42px;
  border: none;
  margin: 0;
  @include border-radius(4px);
}

.jt-btn-primary-dark {
  @extend .jt-btn-primary;
  background: $blue-darker;

  &:hover, &:focus, &:active:hover {
    @extend .jt-btn-primary-dark;
  }
}

.btn-blue {
  background: $blue-darker;

  &:hover, &:focus, &:active:hover {
    @extend .jt-btn-primary-dark;
  }
}

.jt-extended-btn {
  width: 100%;
}

.jt-sm-bt {
  min-width: 160px;
  padding: 10px 20px;
  @include font-size(13);
}

.ajs-ok {
  background: -moz-linear-gradient(right, #ff9e51, #ff8cc5);
  background: -webkit-linear-gradient(right, #ff9e51, #ff8cc5);
  background: -ms-linear-gradient(right, #ff9e51, #ff8cc5);
  background: -o-linear-gradient(right, #ff9e51, #ff8cc5);
  background: linear-gradient(right, #ff9e51, #ff8cc5);
}

.jt-gradient-btn {
  background: -moz-linear-gradient(right, #ff9e51, #ff8cc5) !important;
  background: -webkit-linear-gradient(right, #ff9e51, #ff8cc5) !important;
  background: -ms-linear-gradient(right, #ff9e51, #ff8cc5) !important;
  background: -o-linear-gradient(right, #ff9e51, #ff8cc5) !important;
  background: linear-gradient(right, #ff9e51, #ff8cc5) !important;

  &:hover {
    opacity: 0.9;
  }
}

.jt-pink-btn {
  background: #fb447c !important;

  &:hover {
    opacity: 0.9;
  }
}

.jt-light-pink-btn {
  background: #ff9fb3 !important;

  &:hover {
    opacity: 0.9;
  }
}

.jt-black-btn {
  background: #151515 !important;

  &:hover {
    opacity: 0.9;
  }
}

.jt-gray-btn {
  background: #9197a3 !important;

  &:hover {
    opacity: 0.9;
  }
}

@media all and (max-width: 480px) {
  .jt-btn {
    @include font-size(14);
    padding: 12px 20px;
  }
}

@media all and (max-width: 380px) {
  .jt-btn {
    @include font-size(13);
    // padding: 12px 17px !important;
  }
}