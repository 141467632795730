.jt-grouping-list {

  .jt-grouping-list-inner {
    background: $white;
    //@include border-radius(4px);
    @include border-radius(2px);
    border: 1px solid;
    border-color: rgba(0, 0, 0, .07) rgba(0, 0, 0, .11) rgba(0, 0, 0, .18);

    .jt-grouping-list-row {
      width: 100%;
      border-bottom: 1px solid;
      border-color: rgba(0, 0, 0, .07) rgba(0, 0, 0, .11) rgba(0, 0, 0, .18);
      padding: 8px 10px;
      display: -webkit-flex; /* Safari */
      -webkit-flex-direction: row-reverse; /* Safari 6.1+ */
      display: flex;
      position: relative;
      cursor: pointer;

      .jt-grouping-submit {
        width: auto;
      }

      &:last-child {
        border: none;
      }

      .jt-grouping-list-row-texting {
        -webkit-flex: 1; /* Safari 6.1+ */
        -ms-flex: 1; /* IE 10 */
        flex: 1;
        padding-right: 30px;

        h3 {
          @include font-size(17);
          margin: 0;
          padding: 4px 0 5px 0;
          font-weight: bold;
        }

        span {
          color: $gray;
          @include font-size(15);
        }

      }

      .jt-grouping-para {
        p {
          font-weight: 500;
          padding-top: 3px;
          @include font-size(12);
        }
      }

      .jt-grouping-list-row-fields {
        width: 100%;
        clear: both;

        input[type="text"], input[type="email"], input[type="date"], textarea, select {
          width: 100%;
          border: 1px solid #ccc;
        }

      }

      .jt-grouping-list-row-fields-multiple {
        input[type="text"], input[type="email"], input[type="date"], textarea, select {
          border-top: 1px solid #ccc;
          border-left: 1px solid #ccc;
          border-right: 1px solid #ccc;
          border-bottom: none;
          border-radius: 0;

          &:first-child {
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
          }

          &:last-child {
            border-bottom: 1px solid #ccc;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
          }
        }
      }

      .jt-grouping-list-row-icon {
        padding-left: 8px;
        width: auto;
        height: 100%;
        @include abs-pos(15px, 5px, auto, auto);
        display: table;

        .jt-grouping-list-row-icon-inner-bg {
          display: table-cell;
          width: 10px;
          height: 10px;
          vertical-align: middle;
        }

        .jt-grouping-list-row-icon-inner {
          height: 12px;
          margin-left: -3px;
          margin-right: -1px;
          width: 12px;
          line-height: .5;

          &:after {
            content: "\f105";
            font-family: 'FontAwesome';
            @include font-size(25);
            color: black;
            line-height: .5;
            color: $gray;
          }
        }
      }

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .jt-grouping-list-row-icon {
          top: 0px;
        }
      }

      .jt-grouping-list-row-fields-custom {

        .jt-grouping-list-row-fields-custom-row {
          width: 100%;
          width: -webkit-calc(100% + 8px);
          width: -moz-calc(100% + 8px);
          width: calc(100% + 8px);
          position: relative;
          padding-right: 32px;
          display: inline-block;
          margin-bottom: 2px;
          margin-left: -4px;

          input[type="text"], input[type="email"], input[type="date"], textarea, select {
            border: 1px solid #ccc;
            border-radius: 4px;

            &:first-child {
            }

            &:last-child {
            }

            input:last-child {
            }
          }
          .jt-custom-field1 {
            width: 10%;
            float: left;
            display: inline;
          }
          .jt-custom-field2 {
            width: 20%;
            float: left;
            display: inline;
          }
          .jt-custom-field3 {
            width: 30%;
            float: left;
            display: inline;
          }
          .jt-custom-field4 {
            width: 40%;
            float: left;
            display: inline;
          }
          .jt-custom-field5 {
            width: 50%;
            float: left;
            display: inline;
          }
          .jt-custom-field6 {
            width: 60%;
            float: left;
            display: inline;
          }
          .jt-custom-field7 {
            width: 70%;
            float: left;
            display: inline;
          }
          .jt-custom-field8 {
            width: 80%;
            float: left;
            display: inline;
          }
          .jt-custom-field9 {
            width: 90%;
            float: left;
            display: inline;
          }
          .jt-custom-field10 {
            width: 100%;
            float: left;
            display: inline;
            margin-right: 0;
          }

          .jt-custom-field {
            float: left;
            padding: 0 4px;

            &:last-child {
            }
          }

          .jt-custom-field-remove {
            @include abs-pos(0, -10px, auto, auto);
            @include font-size(20);
            padding: 5px 15px;
            background: none;
            min-height: auto;

            &:hover {
            }

            .fa {
              line-height: 1;
              color: $red-issue;
            }
          }

          .jt-custom-field-add {
            float: right;
            margin-right: 4px;
          }
        }

        .jt-grouping-list-row-fields-pad-none {
          padding: 0;
        }
      }
    }

    .jt-row-with-padding {
      padding-left: 20px;
      padding-right: 20px;
    }

    .jt-row-with-xtra-padding {
      padding-left: 40px;
    }
  }

  .jt-grouping-list-border-none {
      border:none
  }
}

.jt-grouping-list-with-fields {
  .jt-grouping-list-inner {
    .jt-grouping-list-row {
      display: block;
      border-bottom: none;
      cursor: default;

      .jt-grouping-list-row-texting {
        width: 100%;
      }
    }
  }
}

.jt-grouping-list-field-error {
  border: 1px solid $red-issue !important;
}

.privacy-text {
  h3 {
    width: 144%;
  }
}

.privacy-switch {
  ui-switch {
    float: right;
  }
}

