.jt-home-container {
  background: url(#{$gt-images}home-bg.jpeg) no-repeat center center fixed;
  @include css3-prefix('background-size', cover);
  height: 100%;
  overflow: auto;
  padding-top: 8px;

  .jt-home-menu {
    width: 100%;
    height: 100%;

    .jt-home-title {
      color: $off-white;
      font-weight: bold;
      font-size: 1.9rem !important;
    }

    td {
      text-align: center;
      vertical-align: middle;
    }

    .jt-home-icon {
      text-align: center;

      a {
        position: relative;
        display: inline-block;

      }

      img {
        padding-top: 4px;
        width: 120px;
      }
    }
  }

}

.notifications-title-black {
  color: black;
}

.jt-message-count-bubble {
  background-color: $red;
  color: $white;
  @include css3-prefix(border-radius, 4px);
  @include font-size(12);
  min-width: 20px;
  text-align: center;
  padding: 2px 5px;
}

.jt-mobile-sidebar {
   display: none;
   position: fixed;
   top: 74px;
   line-height: 42px;
   left: 0;
   z-index: 999;
   width: 100%;
   box-shadow: 12px 19px 17px -15px #000;
   height: 100%;
   overflow-y: auto;
   -webkit-overflow-scrolling: touch;
   color: white;
   -webkit-transition: all cubic-bezier(.25,.46,.45,.94) .2s;
   -moz-transition: all cubic-bezier(.25,.46,.45,.94) .2s;
   -o-transition: all cubic-bezier(.25,.46,.45,.94) .2s;
   transition: all cubic-bezier(.25,.46,.45,.94) .2s;
   background: black;

   .sidebar-nav {
      width : 100% !important;

     li {
        text-indent: 20px;
        line-height: 40px;
        float: left;
        width: 33%;
        text-align: center;
        font-weight: bold;
        color: white;

        .text {
          font-size: 11px;
          line-height: 26px;
        }

        a:hover {
          text-decoration: none; 
          color: #fff;
          background: rgb(0, 0, 0) !important;
        }
     }
   }

   .jt-side-nav-item {
      
      .jt-custom-img {
         display: block !important;
         margin: 0 auto !important;
         width: 50%;
         height: 50%;
      } 
   }

   .jt-menu-cus-width {
        li {
          width: 25%;
        }

        img {
          width: 20%!important;
          height: 20%!important; 
        }
   }
}

.jt-close-button {
    line-height: 0!important;
    border: 0;
    text-align: right;
    font-size: 30px;
    position: fixed;
    float: left;
    left: 142px;
    top: 82px;
}



.jt-notification-small-icon {
    position: absolute;
    top: 7px;
    margin-left: 8px;
    padding: 1px 3px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #76faca;
    color: #fafafa;
    text-decoration: none;
    text-shadow: 0 -1px 0 rgba(0,0,0,.4);
    z-index: 1000;
}

.jt-not-found-tips {
  padding-top: 10px;
}