//POST FORM OF THE JITO NEWS FEED STREAM CONTAINER
.jt-news-feed-stream-container {
  background: $gray;
  .jt-news-feed-stream-post-form {
  }
  .jt-news-feed-stream-list-view {
    .jt-news-feed-stream-initial-section {
      .jt-stream-story-section {
        .jt-stream-story {
        }
      }
    }
  }
}

.has-navbar-bottom .app-body-login,
.has-navbar-top .app-body-login {
  padding-top: 0;
  padding-bottom: 0;
}

.jt-main-container {
  /*padding-top: 57px;*/
}

.jt-loader {
  width: 100%;
  padding: 50vh 0px;
  top: 0;
  background-color: #000000;
  opacity: 0.8;
  color: #ffffff;
  font-size: 4.2rem;
  z-index: 3000;
  position: fixed;
  display: flex;
  justify-content: center;
  text-align: center;
  i {
    top: -7vh;
    position: relative;
  }
}

.jt-back-button {
  font-size: 4.2rem;
  z-index: 1001;
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 15px;
  top: 15px;
  line-height: 0;
}

.jt-back-button-front {
  z-index: 1003;
}

.jt-back-button-cloak {
  opacity: 0.4 !important;
}

.secondary-loader {
  color: $blue;
  text-align: center;
  padding: 12px 0;
  font-size: 1.5rem;
}

.jt-clear-background {
  background-color: $gray-lighter;
}

.jt-static-text-new {
   margin-top: 80px;

   .caption {
      padding-top: 10px;  
      color: #176ce0;
   }
}