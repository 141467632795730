.jt-bottom-nav {
  background-color: $blue;
  color: $white;
  bottom: 0;

  .jt-bottom-nav-inner {
    display: flex;
    justify-content: center;
    color: $white;
    -moz-box-shadow: inset 0 0 5px $white;
    -webkit-box-shadow: inset 0 0 5px $white;
    box-shadow: inset 0 0 5px $white;

    .jt-nav-icons {

      a {
        line-height: 2;
        padding-bottom: 3px;
      }
    }

    .icon {
      width: 40px;
      height: auto;
      display: inline-block;
      position: relative;
      line-height: 1 !important;

      &:before {
        line-height: 1.5;
      }

      .jt-home-notification-count {
        line-height: .5 !important;
        font-size: 10px;
        top: 0px;
        right: auto;
        left: 101%;

      }

      .jt-bot-nav-notification-bg {
      }
    }
    ul {
      margin-left: auto;
      margin-right: auto;
      width: 300px;
      display: inline;
    }
    ul li {
      display: inline;
      text-align: center;
    }
    ul li img.seperator {
      width: 1px;
      height: auto;
    }
    .jt-nav-icons {
      padding-top: 5px;
      padding-bottom: 5px;
      a {
        border-right: 1px solid $white !important;
        &:last-child {
          border: none !important;
        }
      }
      .icon {
        color: $white;
        font-size: 33px;
        margin: 0 auto;
        height: 48px;
      }
      .border-right {
      }
    }
  }

  .jt-tooltip-space {
    position: relative;
  }

  .jt-notification {
    position: absolute;
    width: 157px;
    height: 58px;
    right: 22%;
    bottom: 50px;
    z-index: 200;
    .jt-notify-popup {
      background-color: transparent;
      width: 190px;
      height: auto;
      position: relative;
      font-size: 1.3rem;
      bottom: 32px;
      img {
        width: 100%;
        height: auto;
      }
      color: $white;
      .jt-popup-count-1, .jt-popup-count-2, .jt-popup-count-3 {
        position: absolute;
        top: 12px;
        padding: 15px;
        text-align: right;
        padding-left: 42px;
      }
      .jt-popup-count-1 {
        right: 119px;
      }
      .jt-popup-count-2 {
        right: 58px;
      }
      .jt-popup-count-3 {
        right: -1px;
      }
    }
  }

}

.site-footer-menu {
  background-color: $pure-white;
  min-height: 65px;
  position: fixed;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  display: none;

  .site-footer-menus {
    list-style: none;

    li {
      float: left;
      margin-top: 10px;
    }

    .short-menu {
      width: 16%;
    }

    .long-menu {
      width: 23%;
    }
  }

  .jt-logged-out {
    list-style: none;

    li {
      float: left;
      width: 25%;
      margin-top: 10px;
    }

    li:first-child {
      margin-left: -20px;
    }
  }

  .footer-nav-item-badge {
    min-width: 18px;
    top: 12px;
    //left: 16%;
    margin-left: 20px;
    height: 18px;
    line-height: 18px;
    background-color: red;
    border-radius: 14px;
    z-index: 1;
    position: absolute;
    font-size: 12px;
    font-weight: 400;
    padding: 0 4px;
    color: #fff;
  }

  .footer-nav-item-icon-img {
    width: auto;
    display: block;
    margin: 0 auto;
    height: 30px;
  }

  .footer-con {
    margin-left: 28px;
  }

  .footer-nav-item-title, .footer-nav-item-title-lo {
    color: #9C9B9A;
    font-weight: bold;
  }

  .footer-in-active {
    display: block;
  }

  .footer-active {
    display: none;
  }

  .footer-parent-active {
    .footer-in-active {
      display: none !important;
    }

    .footer-active {
      display: block !important;
    }

    .footer-nav-item-title, .footer-nav-item-title-lo {
      color: #098bee !important;
    }
  }

  .jt-footer-jobs-link {
    display: block;
  }
}

@media screen and (min-width: 767px) {
  .jt-footer-show-mobile-p
  {
    display: none !important;
  }

  .jt-footer-show-mobile li a, .jt-footer-show-mobile li{
    font-size: 15px !important;
  }

}

@media screen and (max-width: 767px) {
  .jt-footer-hide-mobile {
    display: none !important;
  }

  .jt-footer-show-mobile{
    display: flex !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    gap: 10px;
    justify-content: space-evenly !important;
  }

  .jt-footer-show-mobile ul {
    display: flex !important;
  }

  .jt-footer-show-mobile li a{
    padding-right: 0 !important;
    text-align: center !important;
  }

  .jt-footer-show-mobile ul li, .jt-footer-show-mobile p {
    font-size: 11px !important;
  }

  .jt-footer-show-mobile li:not(:last-of-type) {
    flex: 0 1 auto !important;
    display: flex !important;
  }

  .jt-footer-show-mobile p {
    text-align: center !important;
    display: block !important;
  }
}