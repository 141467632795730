.jt-album-list-element {
  width: 100%;
  a {
    text-decoration: none;
    color: $dark-gray;
  }

  clear: both;
  float: left;
  margin-bottom: 0;
  margin-top: 0;
  padding: 8px;
  border-bottom: 1px solid $light-gray;
  p {
    font-size: 1.6rem;
  }
  .album-img {
    width: 60px;
    float: left;
    img {
      width: 100%;
      height: auto;
    }
    .img-main {
      padding: 30px 30px;
      background-color: $light-gray;
    }
  }
  .album-title {
    width: 70%;
    float: left;
    padding-left: 20px;
    padding-right: 5px;
    padding-top: 7px;
    h6 {
      @include text-truncate();
    }
    p {
      @include text-truncate();
      font-size: 1.4rem;
    }
  }
  .album-count {
    font-size: 1.8rem;
    width: 10%;
    float: left;
    padding-left: 20px;
    padding-right: 5px;
    padding-top: 4px;
    display: inline-block;
  }
  height: 80px;
}

.jt-album-list-element:last-child {
  margin-bottom: 3%;
}

.album-seperator {
  height: 1px;
  color: $gray;
  clear: both;
}

.jt-album-list {
  margin-top: -1%;
}

.jt-album-create-modal {
  padding: 10px;
  button,
  input,
  textarea {
    border-radius: 0 !important;
  }
  button {
    margin-bottom: 3px;
  }
}

.album-error-msg {
  margin-top: 75px;
}

.album-error-msg-secondary {
  margin-top: 0;
}