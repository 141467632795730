.jt-interest-search-box {
  .jt-interest-search {
    .jt-interest-result-list {
      padding: 0 10px;
      .jt-interest-result {
        cursor: pointer;
        .jt-interest-result-image {
          img {
            width: 65px;
            height: 65px;
          }
        }
        .jt-interest-result-name {
          font-size: 1.4rem;
        }
        .jt-interest-result-following {
          cursor: pointer;
          display: flex;
          font-size: 2.5rem;
          .icon {
            padding-right: 0;
          }
          p {
            font-size: 1.2rem;
          }
        }
        .jt-interest-result-follow {
          cursor: pointer;
          display: flex;
          font-size: 2.5rem;
          .icon {
            padding-right: 0;
          }
          p {
            font-size: 1.2rem;
          }
        }

      }
    }
  }
}

.jt-interest-alert-container {
  margin-top: 20px;
  margin-bottom: 5px;
}