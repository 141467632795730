// 2400PX - MAX WIDTH
@include maxSize {

  // WEB APP THEME

  .jt-not-logged-nav-btns {
    display: block;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    
    a{
      display: inline-block;
      img{
        width: 100px;
      }

      &:last-child{
        margin-bottom: 10px;
      }
    }
    .jt-webapp-nav-a-1 {
      display: block;
    }
    .jt-webapp-nav-a-2 {
      display: none;
    }
    .jt-webapp-nav-a-3 {
      display: none;
    }
  }
  .jt-web-welcome-hero.jt-webapp-form-2 {
    width: 100%;
    @include abs-pos(20%, 0, auto, 0);
    top: 16% !important;
  }

  i.caret {
    @include font-size(50);
    color: $blue-darker;
    position: absolute;
    top: 65px;
    left: 30px;
  }

  // HOME
  .jt-home-container {
    .jt-home-menu {
      .jt-home-icon {
        img {
          width: 134px;
        }

      }
    }

  }

  // STRUCTURE

  .jt-bottom-nav {
    .jt-notification {
      right: 22%;
    }
  }

  // LOGIN
  .jt-login-box {
    top: 41%;
  }

  // WALKTHROUGH
  .jt-walk-list-image {
    img {
      margin-top: 10px;
      margin-left: 15px;
    }
  }

  .jt-brand-signup-box {
    .login-form-field-row {
      input[type="submit"], input[type="button"] {
        max-width: 177px !important;
      }
    }
  }

  // STRUCTURE
  .feed-view {
    max-width: 640px;
    background-color: $white;
    height: 98%;
    margin-left: auto;
    margin-right: auto;
  }
  .feed-view-bg-none {
  }
  body {
    background-color: $gray-lighter;
  }
  // EVENT
  .jt-event {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }
  .jt-event-scrollable-content {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }
  .jt-event-datetime-section {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .jt-event-going {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .jt-break-btn {
    height:42px;
  }

  // ALBUM VIEW
  .jt-album-info {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 18px;
  }
  .jt-album-add-photo {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 8px;
    padding-top: 16px;
  }
  .jt-album-photo-area {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }
  // ALBUM LIST
  .jt-album-list {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }
  // CONNECT
  .connect-list-request-name {

  }
  .connect-list-edge-btn {

  }

  // BRAND PROFILE

  .feed-view-bg-transparent {
    background-color: transparent !important;
  }
}

// 1420PX - MAX WIDTH
@include iotaSize {
  // EVENT
  .jt-event-going {
    ul {
      width: 100%;
      padding: 0 5px;
      float: left;
      .hidden {
        display: inline;
      }
      .more-button-10 {
        display: inline;
      }
      .more-button-5 {
        display: none;
      }
    }
    ul li {
      display: inline;
      width: 12.5%;
      padding: 0 6px;
      float: left;
    }
    ul li img {
      width: 100%;
    }
    width: 96%;
    padding-left: 2%;
    padding-right: 2%;
  }
  .jt-event-banner {
    img {
      max-height: 350px;
    }
  }
  .hidden {
    display: inline !important;
  }
  .jt-event {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }
  // ALBUM VIEW
  .jt-album-info {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    padding: 8px;
  }
  .jt-album-add-photo {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 8px;
    padding-top: 16px;

  }
  .jt-album-photo-area {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }
}

// 1368PX - MAX WIDTH
@include thetaSize {
  //ARTICLE HEADER IMAGE
  .article-header-pic {
    height: 350px;
  }

  //HOME PAGE HEADER
  .jt-web-welcome-container-section {
    .jt-web-welcome-container {
      .jt-welc-header {
        .jt-welc-hero-img {
          height: 540px;
        }
      }
    }
  }

  //HOME PAGE EVENTS LISTS
  .jt-home-events-section {

    .jt-home-events {

      .jt-home-event {

        .jt-home-event-inner {
          .jt-home-event-img {
          }

          .jt-home-event-texting-strip {

            .jt-home-event-texting-content {

              h5 {
              }

              span {
              }

              .jt-home-event-bt-bg {

                .jt-home-event-bt-wrapper {

                  .jt-home-event-bt-wrapper-inner {
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  //MAIN HOME PAGE TITLE
  .jt-page-section-container {
    padding: 50px 50px;

    .jt-page-section-title {
      padding-bottom: 50px;

      h3 {
        @include font-size(38);

        &:before, &:after {
          height: 4px;
          width: 80px;
          margin-top: -2px;
        }
      }
    }

    .jt-page-section-title-sm {
      h3 {
        padding: 0 80px;
        @include font-size(28);

        &:before, &:after {
          width: 65px;
          height: 2px;
          margin-top: -1px;
        }

      }
    }
  }


  .jt-web-welcome-container-section {
    .jt-web-welcome-container {
      .jt-welc-header {
        .jt-header-comp-info {
          .jt-header-comp-info-texting {
            h2 {
              @include font-size(30);
            }
            p {
              @include font-size(20);
            }
          }
        }
      }
    }
  }

  //home benifits
  .jt-home-befits-section {

    .jt-home-befits {

      .jt-home-befit {
        width: 50%;

        .jt-home-befit-inner {

          .jt-home-befit-icon {

            img {
            }
          }

          .jt-home-befit-texting {

            h5 {
            }

            p {
            }
          }
        }
      }
    }
  }

  //HOME PAGE SINGLE EVENT VIEW
  .jt-home-single-event-container {
    padding: 80px 0 0 0;

    .jt-home-events-section {

      .jt-home-events {

        .jt-home-event {

          .jt-home-event-inner {

            .jt-home-event-img {
              height: 540px;
            }

            .jt-home-event-texting-strip {

              .jt-home-event-texting-content {

                h5 {
                }

                span {
                }

                .jt-home-event-bt-bg {

                  .jt-home-event-bt-wrapper {

                    .jt-home-event-bt-wrapper-inner {
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// 1200PX - MAX WIDTH
@include etaSize {

  //HOME PAGE EVENTS LISTS
  .jt-home-events-section {

    .jt-home-events {

      .jt-home-event {

        .jt-home-event-inner {
          .jt-home-event-img {
          }

          .jt-home-event-texting-strip {

            .jt-home-event-texting-content {
              padding: 0 0 10px 0;
              text-align: center;
              h5 {
              }

              span {
                margin: 0 0 20px 0;
              }

              .jt-home-event-bt-bg {
                position: relative;
                width: 100%;
                display: block;
                clear: both;

                .jt-home-event-bt-wrapper {

                  .jt-home-event-bt-wrapper-inner {
                  }
                }
              }
            }
          }
        }
      }
    }
  }


  // WEB APP THEME

  .jt-top-nav-holder {
    .jt-top-nav {
      margin-left: 90px;
      .jt-top-nav-search {
      }
      .jt-top-nav-nav {
      }
    }
  }

  .jt-web-welcome-container-section {
    .jt-web-welcome-container {
      .jt-welc-header {
        .jt-header-comp-info {
          width: 70%;
          .jt-header-comp-info-texting {
            h2 {
            }
            p {
            }
          }
        }
        .jt-welc-header-event-info-strip {
          .jt-welc-header-event-info {

            .jt-welc-header-event-cal-icon {
              width: 50px;
              padding-top: 4px;
            }

            .jt-welc-header-event-info-general {
              h3 {
                @include font-size(28);
                padding: 0 0 0 20px;
                max-height: 42px;
              }

              span {
                @include font-size(18);
              }
            }

            .jt-welc-header-event-info-button-bg {
            }
          }
        }
      }
    }
  }


  //HOME BENIFITS
  .jt-home-befits-section {

    .jt-home-befits {

      .jt-home-befit {

        .jt-home-befit-inner {

          .jt-home-befit-icon {
            width: 100px;
            img {
            }
          }

          .jt-home-befit-texting {

            h5 {
            }

            p {
            }
          }
        }
      }
    }
  }

  .jt-home-jobs-link {
    top: 175px;
  }
}

// 992PX - MAX WIDTH
@include zetaSize {

  .article-header-pic {
    height: 290px;
  }

  //HOME PAGE HEADER
  .jt-web-welcome-container-section {
    .jt-web-welcome-container {
      .jt-welc-header {
        .jt-welc-hero-img {
          height: 520px;
        }
      }
    }
  }

  //HOME PAGE EVENTS LISTS
  .jt-home-events-section {

    .jt-home-events {

      .jt-home-event {
        width: 100%;

        &:last-child {
          padding-bottom: 0;
        }

        .jt-home-event-inner {
          .jt-home-event-img {
          }

          .jt-home-event-texting-strip {

            .jt-home-event-texting-content {
              padding-right: 165px;
              text-align: left;
              h5 {
                @include font-size(24);
                max-height: 60px;
              }

              span {
                @include font-size(15);
                margin-bottom: 0;
              }

              .jt-home-event-bt-bg {
                width: 158px;
                position: absolute;

                .jt-home-event-bt-wrapper {

                  .jt-home-event-bt-wrapper-inner {
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .jt-not-logged-footer {
    .jt-webapp-link-bar {
      ul {
        padding: 0 0;
      }
    }
    .jt-webapp-social-bar {
      padding: 0 15px;
    }
  }

  // EVENT PAGE
  .jt-event-banner {
    img {
      width: 100%;
      height: auto;
    }
  }

  // WEB APP THEME

  .jt-webapp-primary-content {
    .jt-webapp-content-center {
      width: 100%;
      margin-left: 0;
      .jt-webapp-content-center-content {
        //max-width: 640px;
        height: 800px;
        //background-color: #0086ed;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .jt-webapp-content-right {
      display: none;
    }
  }

  ul.jt-web-nav-btn-holder {
    margin-right: -104px;
    li {
      .jt-web-nav-round-btns {
        padding: 8px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  .jt-top-nav-holder {
    .jt-top-nav {
      margin-left: 90px;
      .jt-top-nav-search {
        width: 55%;
      }
      .jt-top-nav-nav {
        width: 45%;
      }
    }
  }


  .jt-nav-exit-section {
    width: 144px;
    h5 {
      display: none;
    }
    .jt-mobile-logout {
      display: block;
    }
  }

  .jt-not-logged-nav-btns {
    a {
      //width: 65px !important;
    }
   /* .jt-webapp-nav-a-1 {
      display: none;
    }
    .jt-webapp-nav-a-2 {
      display: block;
    }
    .jt-webapp-nav-a-3 {
      display: none;
    }*/
  }

  i.caret {
    @include font-size(50);
    color: $blue-darker;
    position: absolute;
    top: 65px;
    left: 11px;
  }

  .jt-webapp-link-bar {
    text-align: center;
  }

  .jt-webapp-social-icons {
    margin-left: auto;
    margin-right: auto;
    width: 165px;
    .jt-webapp-social-icon {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .jt-not-logged-nav {
    .jt-not-logged-nav-btns {
      a {
        padding: 33px 15px;

        span {
          .fa {
            @include font-size(22);
          }
        }
      }
    }
  }

  .jt-web-welcome-container-section {
    .jt-web-welcome-container {
      .jt-welc-header {
        .jt-header-comp-info {
          .jt-header-comp-info-texting {
            h2 {
              @include font-size(28);
            }
            p {
              @include font-size(17);
            }
          }
        }
        .jt-welc-header-event-info-strip {
          .jt-welc-header-event-info {

            .jt-welc-header-event-cal-icon {
              width: 42px;
              padding-top: 0;
            }

            .jt-welc-header-event-info-general {
              h3 {
                @include font-size(26);
                padding: 0 0 0 20px;
                max-height: 42px;
              }

              span {
                @include font-size(16);
              }
            }

            .jt-welc-header-event-info-button-bg {
            }
          }
        }
      }
    }
  }

  //HOME BENIFITS
  .jt-home-befits-section {
    padding: 0 0;
    .jt-home-befits {

      .jt-home-befit {

        .jt-home-befit-inner {

          .jt-home-befit-icon {

            img {
            }
          }

          .jt-home-befit-texting {
            padding: 0 0 0 20px;
            h5 {
            }

            p {
            }
          }
        }
      }
    }
  }

  //HOME PAGE SINGLE EVENT VIEW
  .jt-home-single-event-container {

    .jt-home-events-section {

      .jt-home-events {

        .jt-home-event {

          .jt-home-event-inner {

            .jt-home-event-img {
              height: 520px;
            }

            .jt-home-event-texting-strip {

              .jt-home-event-texting-content {

                h5 {
                }

                span {
                }

                .jt-home-event-bt-bg {

                  .jt-home-event-bt-wrapper {

                    .jt-home-event-bt-wrapper-inner {
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .jt-page-section-title-lengthy {
    display: inherit !important;
    white-space: inherit !important;
  }

  .jt-home-jobs-link {
    right: 25px;
  }
}

@media (max-width: 768px) {
  .jt-page-section-container .jt-page-section-title h3 {
    padding: 0 0px !important;
  }
}

// 768PX - MAX WIDTH
@include epsilonSize {

  .header-topic {
    font-size: 28px;
    line-height: 38px;
  }

  .article-padding-view{
    margin-top: 0px;
  }
  .article-header-pic {
    height: 225px;
  }
   /*.jt-search-error {
      margin-top: 55px;
   }*/
  //HOME PAGE HEADER
  .jt-web-welcome-container-section {
    .jt-web-welcome-container {
      .jt-welc-header {
        .jt-welc-hero-img {
          height: 604px;
        }
      }
    }
  }

  //HOME PAGE EVENTS LISTS
  .jt-home-events-section {

    .jt-home-events {

      .jt-home-event {

        .jt-home-event-inner {
          .jt-home-event-img {
          }

          .jt-home-event-texting-strip {

            .jt-home-event-texting-content {

              h5 {
                @include font-size(22);
                line-height: 1.2;
                margin-bottom: 5px;
              }

              span {
              }

              .jt-home-event-bt-bg {

                .jt-home-event-bt-wrapper {

                  .jt-home-event-bt-wrapper-inner {
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  //MAIN HOME PAGE TITLE
  .jt-page-section-container {
    .jt-page-section-title {

      h3 {
        padding: 0 95px;
        @include font-size(36);

        &:before, &:after {
          height: 3px;
          width: 80px;
          margin-top: -1.5px;
        }
      }
    }

    .jt-page-section-title-sm {
      h3 {
        padding: 0 75px;
        @include font-size(26);

        &:before, &:after {
          width: 60px;
          height: 2px;
          margin-top: -1px;
        }

      }
    }
  }

  .jt-hide-for-large {
    display: block;
  }

  .jt-hide-for-small {
    display: none;
  }

  .jt-home-container {
    .jt-home-menu {

      .jt-home-title {
        color: $off-white;
        font-weight: bold;
        font-size: 1.4rem !important;
      }

      .jt-home-icon {
        img {
          padding: 4px;
          width: 125px;
        }
      }
    }
  }

  // WEB APP THEME



  .jt-webapp-header {
    left: 250px;
  }

  .jt-webapp-primary-content {
    .jt-webapp-content-center {
      margin-left: 0;
      .jt-webapp-content-center-content {
      }
    }
    .jt-webapp-content-right {
    }
  }

  .jt-nav-exit-section {
    display: none;
  }

  .jt-nav-not-exit-section {
    display: block;
  }

  .jt-top-nav-holder {
    width: 349px;
    margin-left: -104px;
    .jt-top-nav {
      .jt-top-nav-search {
        display: none;
      }
      .jt-top-nav-nav {
        width: 105%;
        position: absolute;
        .jt-web-nav-btn-holder {
          margin-right: 0;
          font-size: 12px;
          padding-left: 0;
        }
        .jt-webapp-mobile-search-btn {
          display: inline;
        }
      }
    }
  }


  .jt-webapp-logo {
    display: none;
  }

  .jt-webapp-mobile-logo {
    display: block;
  }

  .jt-mobile-sidebar {
    display: block;
  }


  #page-content-wrapper {
    padding: 0;
  }

  .jt-web-welcome-container-section {
    .jt-web-welcome-container {
      .jt-welc-header {
        .jt-header-comp-info {
          width: 100%;
          .jt-header-comp-info-texting {
            text-align: center;
          }
          .jt-header-comp-info-actions {
            text-align: center;
          }
        }

        .jt-welc-header-event-info-strip {
          padding: 20px 20px 160px 20px;

          .jt-welc-header-event-info {
            padding-top: 0;
            padding-right: 0;

            .jt-welc-header-event-cal-icon {
              width: 100%;
              text-align: center;

              img {
                width: 36px;
              }
            }

            .jt-welc-header-event-info-general {
              width: 100%;
              float: left;
              display: block;
              clear: both;
              padding: 0 10px 10px 15px;
              text-align: center;

              h3 {
                width: 100%;
                float: left;
                //white-space: normal;
                //overflow: visible;
                padding: 15px 0 10px 0;
                @include font-size(22);
                max-height: 65px;
              }

              span {
                width: 100%;
                float: left;
                white-space: normal;
                overflow: visible;
                line-height: 1.2;
                padding: 0 0;
                @include font-size(16);
              }
            }

            .jt-welc-header-event-info-button-bg {
              width: 100%;
              float: left;
              position: relative;
              padding-top: 10px;
            }
          }
        }
      }
      .no-featured-event {
        .jt-welc-hero-img {
        }
        .jt-welc-header-container {
          padding: 50px 50px;
        }
        .jt-welc-header-event-info-strip {
        }
      }
    }
  }

  //HOME BENIFITS
  .jt-home-befits-section {
    .jt-home-befits {
      width: auto;
      float: none;
      margin: -20px -20px 0 -20px;

      .jt-home-befit {

        .jt-home-befit-inner {
          height: 90px;

          .jt-home-befit-icon {
            width: 70px;
            img {
            }
          }

          .jt-home-befit-texting {
            h5 {
              @include font-size(20);
              margin-bottom: 5px;
            }

            p {
              @include font-size(14);
            }
          }
        }
      }
    }
  }


  //HOME PAGE SINGLE EVENT VIEW
  .jt-home-single-event-container {

    .jt-home-events-section {

      .jt-home-events {

        .jt-home-event {

          .jt-home-event-inner {

            .jt-home-event-img {
              height: 440px;
            }

            .jt-home-event-texting-strip {

              .jt-home-event-texting-content {

                h5 {
                  margin-bottom: 5px;
                  max-height: 32px;
                  @include font-size(24);
                }

                span {
                  @include font-size(15);
                }

                .jt-home-event-bt-bg {

                  .jt-home-event-bt-wrapper {

                    .jt-home-event-bt-wrapper-inner {
                      .jt-btn {
                        padding: 12px 20px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .jt-top-nav-bar {
    text-indent: -40px;
    width:100px;
  }

  .jt-delete-comment-parent {
    font-size: 17px !important;
  }

  .jt-home-jobs-link {
    display: none;
  }

  .jt-home-jobs-link-small {
    display: block;
  }

  .jt-welc-header-event-cal-icon {
    display: none;
  }

  .jt-session-selection-table-big {
    display: none;
  }

  .jt-session-selection-table-small {
    display: block;

    table > tbody > tr > td {
      border: 1px solid $black;
    }
  }

  .site-footer-menu {
    display: block;
  }
}

// 620PX - MAX WIDTH
@include deltaSize {

  .header-topic {
    font-size: 24px;
    line-height: 32px
  }

  .article-header-pic {
    height: 182px;
  }

  .jt-not-logged-footer ul li {
    &:first-child {
      // width: 100%;
      // display: block;
      // clear: both;
    }
  }

  //HOME PAGE HEADER
  .jt-web-welcome-container-section {
    .jt-web-welcome-container {
      .jt-welc-header {
        .jt-welc-hero-img {
          height: 540px;
        }
      }
      .jt-welc-header-event-info-strip {
        .jt-welc-header-event-info {
          .jt-welc-header-event-cal-icon {
            display: none;
          }
        }
      }
    }
  }

  //HOME PAGE EVENTS LISTS
  .jt-home-events-section {

    .jt-home-events {

      .jt-home-event {

        .jt-home-event-inner {
          .jt-home-event-img {
          }

          .jt-home-event-texting-strip {

            .jt-home-event-texting-content {
              padding: 0 0 10px 0;
              text-align: center;
              h5 {
                margin-bottom: 10px;
              }

              span {
                margin: 0 0 25px 0;
                @include font-size(15);
              }

              .jt-home-event-bt-bg {
                position: relative;
                width: 100%;
                display: block;
                clear: both;

                .jt-home-event-bt-wrapper {

                  .jt-home-event-bt-wrapper-inner {
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  //MAIN HOME PAGE TITLE
  .jt-page-section-container {
    .jt-page-section-title {

      h3 {
        @include font-size(34);

        &:before, &:after {
          height: 2px;
          width: 60px;
          margin-top: -1px;
        }
      }
    }

    .jt-page-section-title-sm {
      h3 {
        @include font-size(24);

        &:before, &:after {
          width: 55px;
          height: 2px;
          margin-top: -1px;
        }

      }
    }
  }


  // STRUCTURE
  .feed-view {
    width: 100%;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
  }

  .feed-view-bg-transparent {
    background-color: transparent !important;
  }

  .feed-view-bg-none {
  }
  body {
    background-color: $white;
  }
  // WELCOME PAGE
  .jt-welcome-container {
    .jt-welcome-nav {
      ul {
        li {
          a {
            @include font-size(42);
            line-height: 42px;
          }
          &:last-child {
          }
        }
      }
    }
  }
  // EVENT
  .jt-event {
    width: 100%;
  }
  .jt-event-scrollable-content {
    width: 100%;
  }
  .jt-event-datetime-section {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  // ALBUM VIEW
  .jt-album-info {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }
  .jt-album-add-photo {
    width: 100%;
    padding: 0 8px;
    padding-top: 16px;

  }
  .jt-album-photo-area {
    width: 100%;
  }
  // ALBUM LIST
  .jt-album-list {
    width: 100%;
  }

  .jt-brand-about {
    padding: 0 15px;
  }

  // HOME
  .jt-home-container {
    .jt-home-menu {
      .jt-home-icon {
        img {
          width: 100px;
        }

      }
    }

  }


  .jt-web-welcome-container {
    //position: relative;
    //width: 100%;
    ////height: 286%;
    //background: url(../../img/welcome-bg.jpg) no-repeat center center;
  }



  // WEB APP THEME

  .jt-not-logged-nav-btns {
    a {
      //width: 35px !important;
    }
    /*.jt-webapp-nav-a-1 {
      display: none;
    }
    .jt-webapp-nav-a-2 {
      display: none;
    }
    .jt-webapp-nav-a-3 {
      display: block;
    }*/
  }

  i.caret {
    @include font-size(50);
    color: $blue-darker;
    position: absolute;
    top: 65px;
    left: -3px;
  }

  .jt-not-logged-nav {
    height: 84px;

    .jt-not-logged-logo {
      padding-left: 20px;
    }
    .jt-not-logged-nav-btns {
      padding-right: 20px;

      a {
        padding: 26px 5px;

        span {
          width: 32px;

          .fa {
            @include font-size(28);
          }
        }
      }
    }
  }

  .jt-web-welcome-container-section {
    .jt-web-welcome-container {
      .jt-welc-header {
        .jt-header-comp-info {
          padding: 40px 20px;
          .jt-header-comp-info-texting {
            p {
              display: none;
            }
          }
          .jt-header-comp-info-actions {
          }
        }

        .jt-welc-header-event-info-strip {
          padding: 20px 20px 160px 20px;

          .jt-welc-header-event-info {
            padding-top: 0px;

            .jt-welc-header-event-cal-icon {
              width: 100%;
              text-align: center;

              img {
                width: 36px;
              }
            }

            .jt-welc-header-event-info-general {
              width: 100%;
              float: left;
              display: block;
              clear: both;
              padding: 0 15px;
              text-align: center;

              h3 {
                //white-space: normal;
                //overflow: visible;
                padding: 10px 0 7px 0;
                @include font-size(21);
                max-height: 62px;
              }

              span {
                white-space: normal;
                overflow: visible;
                line-height: 1.2;
                padding: 0 0;
                @include font-size(16);
              }
            }

            .jt-welc-header-event-info-button-bg {
              padding-top: 20px;
            }
          }
        }
      }
    }
  }

  //HOME BENIFITS
  .jt-home-befits-section {
    .jt-home-befits {

      .jt-home-befit {
        width: 100%;
        .jt-home-befit-inner {
          height: auto;

          .jt-home-befit-icon {
            width: 75px;

            img {
            }
          }

          .jt-home-befit-texting {
            h5 {
              @include font-size(22);
              margin-bottom: 5px;
            }

            p {
              @include font-size(16);
            }
          }
        }
      }
    }
  }

  //HOME PAGE SINGLE EVENT VIEW
  .jt-home-single-event-container {

    .jt-home-events-section {

      .jt-home-events {

        .jt-home-event {

          .jt-home-event-inner {

            .jt-home-event-img {
            }

            .jt-home-event-texting-strip {

              .jt-home-event-texting-content {

                h5 {
                  max-height: 55px;
                }

                span {
                }

                .jt-home-event-bt-bg {

                  .jt-home-event-bt-wrapper {

                    .jt-home-event-bt-wrapper-inner {
                      .jt-btn {
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

}

@media all and (max-width: 551px) {
  .site-footer-menu {
    .site-footer-menus {
      .short-menu {
        width: 20% !important;
      }

      li:first-child {
        margin-left: -20px;
      }
    }

    .jt-footer-jobs-link {
      display: none !important;
    }
  }

  .jt-list-connect-msg-btn {
    float: none;
    margin-top: 0;
    margin-left: 85px;
  }

  .jt-post-video {
    max-height: 200px;
  }
}

// 480PX - MAX WIDTH
@include gammaSize {

  .header-topic {
    font-size: 18px;
    line-height: 22px;
  }

  //HOME HEADER
  .jt-web-welcome-container-section {
    .jt-web-welcome-container {
      .jt-welc-header {
        .jt-welc-hero-img {
          height: 480px;
        }
        .jt-header-comp-info {
          .jt-header-comp-info-texting {
            h2 {
              @include font-size(24);
            }
          }
        }
      }
    }
  }

  //MAIN HOME PAGE TITLE
  .jt-page-section-container {
    padding: 40px 20px;

    .jt-page-section-title {
      padding-bottom: 40px;

      h3 {
        font-size: 28px !important;

        &:before, &:after {
          height: 2px;
          width: 55px;
          margin-top: -1px;
        }
      }
    }

    .jt-page-section-title-sm {
      h3 {
        @include font-size(22);

        &:before, &:after {
          width: 45px;
          height: 2px;
          margin-top: -1px;
        }

      }
    }

  }

  .jt-events-container-bg {
    padding-left: 15px;
    padding-right: 15px;
  }

  //HOME PAGE EVENTS LISTS
  .jt-home-events-section {

    .jt-home-events {

      .jt-home-event {
        padding: 10px 20px;

        .jt-home-event-inner {

          .jt-home-event-img {
            //height: 420px;
          }

          .jt-home-event-texting-strip {

            .jt-home-event-texting-content {
              h5 {
              }

              span {
              }

              .jt-home-event-bt-bg {

                .jt-home-event-bt-wrapper {

                  .jt-home-event-bt-wrapper-inner {
                  }
                }
              }
            }
          }
        }
      }
    }
  }
    
  // HOME
  .jt-home-container {
    .jt-home-menu {
      .jt-home-icon {
        img {
          width: 100px;
        }

      }
    }

  }

  .jt-web-welcome-container {
    //position: relative;
    //width: 100%;
    //height: 130%;
    //background: url(../../img/welcome-bg.jpg) no-repeat center center;
  }

  // WALKTHROUGH
  .jt-walk-list-image {
    img {
      margin-top: 10px;
      margin-left: 6px;
    }
  }

  // WELCOME PAGE
  .jt-welcome-container {
    .jt-welcome-nav {
      ul {
        li {
          a {
            @include font-size(34);
            line-height: 34px;
          }
          &:last-child {
          }
        }
      }
    }
  }
  // LOGIN PAGE
  .jt-login-box {
    @include abs-pos(41%, 0, auto, 0);
    .jt-login-box-inner {
      padding: 20px 15px;
      .login-form-label {
        p {
          @include font-size(26);
        }
      }
      .login-form-content {
        .login-form-field-row {
          input[type="email"],
          input[type="password"] {
          }
          input[type="submit"], input[type="button"] {
            max-width: 84px;
          }
          span {
            a {
            }
          }
        }
      }
    }
  }
  // SIGNUP
  .jt-signup-box {
    @include abs-pos(41%, 0, auto, 0);
    .jt-signup-box-inner {
      padding: 20px 15px;
      .login-form-label {
        p {
          @include font-size(26);
        }
      }
      .signup-form-content {
        .signup-form-field-row {
          input[type="email"],
          input[type="password"] {
          }
          input[type="submit"], input[type="button"] {
          }
          span {
            a {
            }
          }
        }
      }
    }
  }

  // JITO MODAL
  .jt-modal {
    .modal-dialog {
      max-width: 100%;
      margin: 10px;
      .modal-header {
        .close {
        }
        h4 {
        }
      }
      .modal-body {
        .jt-modal-inner {
        }
      }
    }
  }
  // EVENT
  .jt-event-going {
    ul {
      width: 100%;
      padding: 0 5px;
      float: left;
      .hidden {
        display: none;
      }
      .more-button-10 {
        display: none;
      }
      .more-button-5 {
        display: inline;
      }
    }
    ul li {
      display: inline;
      width: 20%;
      padding: 0 4px;
      float: left;
    }
    ul li img {
      width: 100%;
    }
  }
  .hidden {
    display: none !important;
  }
  // CONNECT
  .connect-list-request-name {

  }
  .connect-list-edge-btn {

  }

  //BRANDS
  .jt-brand-search-box {
    .jt-brand-search-results {
      .search-result-list {
        padding: 0 15px;
      }
    }
  }

  .jt-bottom-nav {
    .jt-notification {
      right: 51%;
    }
  }

  // WEB APP THEME

  .jt-sidebar-wrapper {
    left: 228px !important;
  }

  .jt-webapp-mobile-logo {
    width: 126px;
    h6 {
      display: none;
    }
    .jt-rest-logo {
      margin-left: 69px;
      margin-top: -58px;
    }
  }

  .jt-webapp-header {
    left: 78px;
    width: 100%;
  }

  .jt-top-nav-nav {
    margin-left: -11px;
  }

  #page-content-wrapper {
    .container-fluid {
      padding-left: 0;
      padding-right: 0;
    }
  }

  ul.jt-web-nav-btn-holder {
    margin-right: -104px;
    li {
      .jt-web-nav-round-btns {
        padding: 11px;
        padding-left: 12px;
        padding-right: 12px;

        .icon, .fa {
          position: relative;
          top: 2px;
        }
      }
    }
  }


  .jt-top-nav-holder .jt-top-nav .jt-top-nav-nav {
    //background: brown;
    width: 100%;
    margin-left: 0;
    left: 0;
  }

  ul.jt-web-nav-btn-holder {
    margin-left: 0;
    margin-right: 15px;
  }

  .jt-side-nav-item {
    a {
      img {
        width: 40px;
      }
    }
  }

  .jt-not-logged-logo {
    width: 120px !important;
    h6 {
      @include font-size(10);
    }
  }

  .jt-webapp-link-bar {
    //display: none;
  }

  .jt-webapp-social-bar {
    //display: none;
  }

  .jt-web-welcome-hero.jt-webapp-form-2 {
    width: 100%;
    @include abs-pos(1%, 0, auto, 0);
    top: 1% !important;
  }

  .jt-side-nav-item a img {
    width: 40px;
    height: 40px;
  }

  .jt-web-welcome-container-section {
    .jt-web-welcome-container {
      .jt-welc-header {

        .jt-welc-header-container {
          padding: 0 20px;
        }
        .jt-welc-header-event-info-strip {
          .jt-welc-header-event-info {

            .jt-welc-header-event-cal-icon {
              padding-top: 0px;

              img {
                width: 36px;
              }
            }

            .jt-welc-header-event-info-general {

              h3 {
                @include font-size(21);
                max-height: 62px;
              }

              span {
                @include font-size(16);
              }
            }

            .jt-welc-header-event-info-button-bg {
              padding-top: 25px;
            }
          }
        }
      }
      .no-featured-event {
        .jt-welc-hero-img {
          height: 420px;
        }
        .jt-welc-header-container {
          padding: 50px 20px;
        }
        .jt-welc-header-event-info-strip {
        }
      }
    }
  }

  //HOME BENIFITS
  .jt-home-befits-section {
    .jt-home-befits {
      width: 100%;
      float: left;
      padding: 0 10px;
      margin: 0 0;

      .jt-home-befit {
        padding: 0 0 20px 0;

        .jt-home-befit-inner {

          .jt-home-befit-icon {
            width: 65px;
            img {
            }
          }

          .jt-home-befit-texting {
            padding: 0 0 0 15px;

            h5 {
              @include font-size(22);
              margin-bottom: 5px;
            }

            p {
              @include font-size(14);
            }
          }
        }
      }
    }
  }

  //HOME PAGE SINGLE EVENT VIEW
  .jt-home-single-event-container {
    padding: 50px 0 0 0;

    .jt-home-events-section {

      .jt-home-events {

        .jt-home-event {

          .jt-home-event-inner {

            .jt-home-event-img {
              height: 520px;
            }

            .jt-home-event-texting-strip {

              .jt-home-event-texting-content {

                h5 {
                }

                span {
                }

                .jt-home-event-bt-bg {

                  .jt-home-event-bt-wrapper {

                    .jt-home-event-bt-wrapper-inner {
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }


  @media (max-width: 399px) {
    .jt-events-heading-section .jt-events-heading-1 {
      font-size: 30.5pt;
    }
  }

  .jt-home-jobs-link-small {
    margin-top: 8px;
  }

  .jt-shrink-top {
    margin-top: -20px;
  }
}

@media all and (max-width: 450px) {
  .site-footer-menu {
    .footer-nav-item-icon-img {
      //max-width: 30%;
    }

    .foo-msg-mn {
      //max-width: 50% !important;
    }

    .foo-art-mn {
      //max-width: 40% !important;
    }

    .footer-nav-item-title, .footer-nav-item-title-lo {
      font-size: 1.0rem;
    }

    .footer-nav-item-badge {
      margin-left: 15px;
    }

    .footer-con {
      margin-left: 25px;
    }
  }

  .article-options {
    display: none;
  }

  .article-options-small {
    display: block;
  }
}

// 380PX - MAX WIDTH
@include betaSize {

  .jt-checkout-success-controls {

    button {
      margin: 10px auto;
      clear: both;
      display: block;
    }
  }

  //MAIN HOME PAGE TITLE
  .jt-page-section-container {

    .jt-page-section-title {

      h3 {
        width: 100%;
        padding: 0 0;
        float: left;
        text-align: center;
        @include font-size(26);
        white-space: normal;

        &:before, &:after {
          display: none;
        }
      }
    }

    .jt-page-section-title-sm {
      h3 {
        padding: 0 15px;
        @include font-size(20);

        &:before, &:after {
          width: 55px;
          height: 2px;
          margin-top: -1px;
        }

      }
    }
  }


  // WALKTHROUGH
  .jt-walk-list-image {
    img {
      margin-top: 10px;
      margin-left: 2px;
    }
  }

  // WELCOME PAGE
  .jt-welcome-container {
    .jt-welcome-nav {
      ul {
        li {
          a {
            @include font-size(28);
            line-height: 28px;
          }
          &:last-child {
          }
        }
      }
    }
    .jt-welcome-footer {
      p {
        @include font-size(22);
        line-height: 22px;
      }
      span {
        @include font-size(9);
        line-height: 9px;
      }
    }
  }

  .jt-brand-signup-box {
    .login-form-field-row {
      input[type="submit"], input[type="button"] {
        max-width: 100% !important;
      }
    }
  }
  // LOGIN PAGE
  .jt-login-box {
    @include abs-pos(41%, 0, auto, 0);
    .jt-login-box-inner {
      padding: 10px 15px;
      .login-form-label {
        p {
          @include font-size(26);
        }
      }
      .login-form-content {
        .login-form-field-row {
          input[type="email"],
          input[type="password"] {
          }
          input[type="submit"], input[type="button"] {
            max-width: 100%;
          }
          span {
            margin: 0;
            width: 100%;
            text-align: center;
            a {
            }
          }
        }
      }
    }
  }
  // SIGN UP
  .jt-signup-box {
    @include abs-pos(41%, 0, auto, 0);
    .jt-signup-box-inner {
      padding: 10px 15px;
      .signup-form-label {
        p {
          @include font-size(24);
          padding-left: 5px;
          padding-top: 4px;
        }
      }
      .signup-form-content {
        .signup-form-field-row {
          input[type="email"],
          input[type="password"] {
          }
          input[type="submit"], input[type="button"] {
            max-width: 100%;
          }
          span {
            margin: 0;
            width: 100%;
            text-align: center;
            a {
            }
          }
        }
      }
    }
  }
  // CONNECT
  .connect-list-request-name {

  }
  .connect-list-edge-btn {

  }

  // HOME
  .jt-home-container {
    .jt-home-menu {
      .jt-home-icon {
        img {
          width: 95px;
        }

      }
    }

  }

  //BRANDS
  .jt-brand-search-box {
    .jt-brand-search-results {
      .search-result-list {
        padding: 0 25px;
      }
    }
  }

  .jt-bottom-nav {
    .jt-notification {
      right: 63%;
    }
  }

  //EVENT LIST
  .jt-break-btn {
    height:52px;
  }

  .jt-web-welcome-container-section {
    .jt-web-welcome-container {
      .jt-welc-header {
        .jt-welc-hero-img {
          height: 435px;
        }
        .jt-header-comp-info {
          .jt-header-comp-info-texting {
            padding-bottom: 20px;
            h2 {
              @include font-size(20);
              padding-bottom: 0;
              line-height: 1.15;
            }
            p {
            }
          }
          .jt-header-comp-info-actions {
            span {
              display: none;
            }
            button {
              margin: 0 5px;
            }
          }
        }
        .jt-welc-header-event-info-strip {
          padding: 20px 20px 145px 20px;

          .jt-welc-header-event-info {

            .jt-welc-header-event-cal-icon {
              display: none;

              img {
              }
            }

            .jt-welc-header-event-info-general {

              h3 {
                @include font-size(20);
                padding-top: 0;
                max-height: 80px;
              }

              span {
                @include font-size(13);
              }
            }

            .jt-welc-header-event-info-button-bg {
              padding-top: 25px;
            }
          }
        }
      }

      .no-featured-event {
        .jt-welc-hero-img {
          height: 360px;
        }
        .jt-welc-header-container {
          padding: 50px 20px;
        }
        .jt-welc-header-event-info-strip {
        }
      }
    }
  }

  //HOME BENIFITS
  .jt-home-befits-section {
    .jt-home-befits {

      .jt-home-befit {
        padding-bottom: 17px;
        margin-bottom: 20px;
        border-bottom: 1px solid #dfebf4;

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 0;
        }

        .jt-home-befit-inner {

          .jt-home-befit-icon {
            width: 100%;
            text-align: center;
            float: left;
            padding: 0 15px 15px 15px;

            img {
              width: 65px;
            }
          }

          .jt-home-befit-texting {
            width: 100%;
            float: left;
            clear: both;
            text-align: center;
            padding: 0 0;

            h5 {
            }

            p {
            }
          }
        }
      }
    }
  }

  .jt-shrink-top {
    margin-top: -10px;
  }

  .site-footer-menu {
    .footer-nav-item-title {
      font-size: 0.8rem;
    }

    .footer-nav-item-badge {
      margin-left: 13px;
    }

    .footer-con {
      margin-left: 22px;
    }

    .footer-nav-item-badge {
      min-width: 15px;
      height: 15px;
      line-height: 15px;
      font-size: 10px;
    }
  }

  .article-edit-button{
    .jt-btn{
      padding:3px 8px !important;
    }
  }
}

// 340PX - MAX WIDTH
@include alphaSize {

  // HOME
  .jt-home-container {
    .jt-home-menu {
      .jt-home-icon {
        img {
          width: 74px;
        }

      }
    }

  }

  // LOGIN
  .jt-login-box {
    top: 36%;
  }


  .jt-web-welcome-container-section {
    .jt-web-welcome-container {
      .jt-welc-header {
        .jt-welc-hero-img {
          height: 438px;
        }
        .jt-header-comp-info {
          padding: 30px 20px;
          .jt-header-comp-info-texting {
            h2 {
              @include font-size(21);
              line-height: 1;
            }
            p {
            }
          }
          .jt-header-comp-info-actions {
            span {
            }
            button {
            }
          }
        }
        .jt-welc-header-event-info-strip {
          .jt-welc-header-event-info {

            .jt-welc-header-event-cal-icon {

              img {
              }
            }

            .jt-welc-header-event-info-general {

              h3 {
                @include font-size(20);
                padding: 0 0 5px 0;
                max-height: 60px;
              }

              span {
              }
            }

            .jt-welc-header-event-info-button-bg {
            }
          }
        }
      }

      .no-featured-event {
        .jt-welc-hero-img {
          height: 350px;
        }
        .jt-welc-header-container {
          padding: 50px 20px;
        }
        .jt-welc-header-event-info-strip {
        }
      }
    }
  }

  .jt-web-welcome-container-section {
      .jt-welc-header-container {
        .inner {
          .jt-header-comp-info {
            .jt-header-comp-info-actions {
              padding: 0 15px;
            }
            .jt-header-comp-info-actions {

              button {
                //padding: 15px 18px;
                //@include font-size(15);
                margin: 0 2px;
              }
            }
          }
        }
      }
    }

}

// 530PX - MAX HEIGHT
@media screen and (max-height: 530px) {
  // WELCOME
  .jt-welcome-container {
    .jt-welcome-logo {
      .jt-welcome-logo-inner {
        padding: 36px 30px;
        h3 {
          @include font-size(62);
        }
        h6 {
          @include font-size(18);
        }
      }
    }
  }
  // SIGN UP
  .jt-signup-box {
    @include abs-pos(32%, 0, auto, 0);
    .jt-signup-box-inner {
      .signup-form-label {
        p {
        }
      }
      .signup-form-content {
        .signup-form-field-row {
          input[type="email"],
          input[type="password"] {
          }
          input[type="submit"], input[type="button"] {
          }
          span {
            a {
            }
          }
        }
      }
    }
  }
}

// 480PX - MAX HEIGHT
@media screen and (max-height: 480px) {
  //WELCOME - LOGIN - SIGNUP
  .jt-hidden-footer {
    visibility: hidden;
  }
}

// 480PX - MIN HEIGHT
@media screen and (min-height: 480px) {
  //WELCOME - LOGIN - SIGNUP
  .jt-hidden-footer {
    visibility: visible;
  }
}

//For msg create button

@media screen and  (max-width: 480px) {
  
  // .jt-create-msg-btn  {
  //    right: 60px!important;
  // }
}

@media (min-width:1000px) and (max-width:1149px) {
  .jt-single-event-ticket-options-content .jt-single-event-ticket-options .jt-single-event-ticket-option {
    width: 43.3333%;  
  }
}

@media(max-width: 767px) {
  .jt-single-event-page-header .jt-selected-event-wrapper {
    padding: 0px 10px 40px 10px;
}
.slick-next {
  right: -40px;
}
.jt-agenda-section {
  table td[class*="col-"], table th[class*="col-"] {
    display: contents;
  }

  .jt-agenda-wrapper .jt-agenda-table-wrapper table .agenda-col-1 span {
    margin-bottom: -10px;
    margin-top: 20px;
  }
}
}


@media (min-width:769px) and (max-width:999px) {
  .jt-single-event-ticket-options-content .jt-single-event-ticket-options .jt-single-event-ticket-option {
    width: 75%;  
  }
}