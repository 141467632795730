/* Authored Dependencies */

@import "_utilities/_variables";
@import "_utilities/_typography";
@import "_utilities/_mixins";

/* Components */

@import "_components/_header";
@import "_components/_article";
@import "_components/_footer";
@import "_components/_buttons";
@import "_components/_forms";
@import "_components/_news-feed-stream-post-form";
@import "_components/_jt-modal.scss";
@import "_components/_comments.scss";
@import "_components/_validations.scss";
@import "_components/_time-line";
@import "_components/_alert.scss";
@import "_components/_grouping-list.scss";
@import "_components/_jt-friend-info.scss";
@import "_components/_web-sidebar";
@import "_components/_jto_header";

/* Templates */

@import "_templates/_welcome";
@import "_templates/_article-view";
@import "_templates/_article-list";
@import "_templates/_signup-options";
@import "_templates/_login";
@import "_templates/_signup";
@import "_templates/_structure";
@import "_templates/_event";
@import "_templates/_forgot-password";
@import "_templates/_event-list";
@import "_templates/_profile-edit";
@import "_templates/_album-view";
@import "_templates/_album-list";
@import "_templates/_connect";
@import "_templates/_write-post";
@import "_templates/_brand-profile";
@import "_templates/_brand-search";
@import "_templates/_search-templates";
@import "_templates/_delete-album";
@import "_templates/_about";
@import "_templates/_message-list";
@import "_templates/_conversation";
@import "_templates/_interests";
@import "_templates/_interest-view";
@import "_templates/_calendar";
@import "_templates/_notifications";
@import "_templates/_walkthrough";
@import "_templates/_empty-view";
@import "_templates/_invite";
@import "_templates/_web-theme";
@import "_templates/_single-event-page";
@import "_templates/_checkout";



/* Fonts */

@import "_utilities/_font-icons";
@import "_templates/home";
@import "_templates/profile";

html {
    font-size: $gt-html-base-font-size;
    font-family: $gt-font-QS;
}

body {
    background: $white;
    @include font-size(13);
    font-family: $gt-font-QS;
    font-weight: $gt-font-weight-regular;
}

.jt-hide-for-large {
    display: none;
}

.jt-hide-for-small {
    display: block;
}

*:focus {
    outline: none;
}

a,
a:hover,
a:focus {
    text-decoration: none;
    outline: none;
}

/* Framework Overides */
/* Framework Overides */

@import "_utilities/framework-overides";

@import '_utilities/_media-quries.scss';
@import '_utilities/_media-single-event-page.scss';

.sidebar-nav {
    position: relative;
    overflow-scrolling: touch;
}