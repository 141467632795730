//Fonts
@import url("https://use.typekit.net/jml4rgr.css");

// Font Variables
$h1-large: "anton",sans-serif;
$regular: "proxima-nova", sans-serif;
//Colour Variables
$bright-pink: #fb447c;
$medium-pink: #ff9fb3;
$light-pink:#ffdfe3;

.label-primary {
  background-color: $bright-pink;
}

.jt-friend-info {
  /*width: 100%;
  float: left;
  padding: 3px 0;*/

  width: 100%;
  float: left;
  padding: 3px 0;
  width: 100%;
  //height: 90px;
  min-height: 90px;
  position: relative;
  //border-bottom: 1px solid #d8d8d8;
  clear: both;
  display: inline-block;
  vertical-align: middle;

  .jt-friend-info-img {
    /*width: 70px;
    min-height: 70px;
    float: left;*/

    width: 70px;
    min-height: 70px;
    float: left;
    height: 70px;

    img {
      border: solid 3px $medium-pink;
      background-color: $white;
      box-shadow: 1px 1px rgba(222, 222, 222, 1);
      -moz-box-shadow: 1px 1px rgba(222, 222, 222, 1);
      -webkit-box-shadow: 1px 1px rgba(222, 222, 222, 1);
      -o-box-shadow: 1px 1px rgba(222, 222, 222, 1);
      border-radius: 100%;
    }
  }

  .jt-friend-info-desc {
    /*min-height: 70px;
    margin-left: 0;
    padding: 7px 7px 7px 17px;
    display: table;*/

    min-height: 70px;
    margin-left: 0;
    padding: 7px 7px 7px 17px;
    display: table-cell;
    vertical-align: middle;
    height: 70px;

    .jt-friend-info-desc-inner {
      display: table-cell;
      vertical-align: middle;
      text-align: left;

      p {
        margin: 0;
        padding: 0;
      }
    }
  }
}