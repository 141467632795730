.jt-chat {
  display: block;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background: $gray-lighter;
  padding-bottom: 120px;

  .jt-date-seperator {
    padding-top: 5px;
    padding-bottom: 3px;
    p {
      text-align: center;
      color: $gray;
    }
    hr {
      border-color: $gray-light;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  & .jt-message {
    margin: 20px 12px 50px 1px;
    min-height: 30px;
    height: auto;
    text-align: left;
    padding-left: 3px;
    padding-right: 9px;
    clear: both;
    padding-bottom: 43px;

    span {
      @include font-size(11);
    }

    a {
      text-decoration: underline;
      color: $white;

    }

    p {
      width: 90%;
      word-break: break-word;
    }

    &.me {
      & img {
        order: 2;
        margin: 0 0 0 10px;
        float: right;
      }

      & div {
        order: 1;
        padding: 0 8px 0 0;
        max-width: 57%;
        min-width: 40%;
        float: right;
        margin-bottom: 10px;

        & p {
          float: right;
          line-height: 1.8;
          background-color: #ffffff;
          color: $blue;
          word-break: break-word;

        }

        &:before {
          position: relative;
          float: right;
          content: '';
          margin: 7px -8px 0 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 8px 0 8px 8px;
          border-color: transparent transparent transparent #fff;
        }
      }

      a {
        text-decoration: underline;
        color: $blue;
      }
    }

    & img {
      display: block;
      order: 1;
      margin: 0 10px 0 0;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      box-sizing: border-box;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      border: 3px solid $blue-light;
      float: left;
    }

    & div {
      display: block;
      flex: 1;
      order: 2;
      max-width: 57%;
      min-width: 40%;
      float: left;
      margin-bottom: 10px;

      & p {
        display: inline-block;
        margin: 0;
       // width: auto;
        padding: 8px 10px 8px 10px;
        background: $blue;
        word-wrap: break-word;
        font-family: $gt-font-QS;
        border-radius: 3px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        color: $white;
        line-height: 1.8;
        width: 90%;
      }

      &:before {
        position: relative;
        float: left;
        content: '';
        margin: 7px 0 0 -8px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 8px 8px 0;
        border-color: transparent $blue transparent transparent;
      }
    }
  }
}

.jt-chat {
  border-radius: 5px;
  .jt-message {
    img {
      width: 70px;
      height: 70px;

    }
  }
}

.jt-conversation-feed {
  background-color: $gray-lighter !important;
  border-left: 1px solid $gray-light;
  border-right: 1px solid $gray-light;
  position: relative;
}

.jt-conversation-box {

}

.jt-write-message {
  float: left;
  clear: both;
  width: 100%;
  bottom: 20px;
  position: absolute;
  max-width: 640px;

  //left: 0;

  .jt-write-message-inner {
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    background: red;
    padding: 8px;
    background-color: $gray-light;
    display: table;
  }

  .jt-attach-photo {
    width: 10%;
    float: left;
    display: flex;
    justify-content: center;
    .jt-photo-btn {
      width: 40px;
      height: 40px;
      color: $gray;
      font-size: 2rem;
      margin-top: 35px;
    }
  }
  .jt-write-area {
    width: 84%;
    float: left;
    textarea {
      height: 60px;
      resize: none;
    }
  }

  .jt-send-msg {
    float: right;
    padding: 0px;
    margin: 0px;
    width: 16%;
    .jt-send-btn {
      width: 40px;
      height: 40px;
      border-radius: 150px;
      color: $white;
      background-color: $blue;
      font-size: 2rem;
      padding: 7px 9px;
      margin-top: 12px;
      margin-left: auto;
      margin-right: auto;
      -webkit-box-shadow: 0px 0px 17px -4px rgba(0, 0, 0, 0.31);
      -moz-box-shadow: 0px 0px 17px -4px rgba(0, 0, 0, 0.31);
      box-shadow: 0px 0px 17px -4px rgba(0, 0, 0, 0.31);

      .jt-send-msg-progress {
        color: white;
      }
    }
  }
  button {
    background-color: $gray-light;
  }
}

.jt-conversation-header {
  background-color: $gray-lighter;
  text-align: center;
  width: 100%;
  max-width: 640px;
  z-index: 200;
  margin-left: auto;
  margin-right: auto;
  border-left: 1px solid $gray-light;
  border-right: 1px solid $gray-light;
  padding-bottom: 1px;
  margin-top: -3px;
  padding-top: 9px;
  h6 {
    color: $gray;
    margin-bottom: 1px;
    margin-top: 3px;
  }
}
