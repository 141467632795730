//Fonts
@import url("https://use.typekit.net/jml4rgr.css");

// Font Variables
$h1-large: "anton",sans-serif;
$regular: "proxima-nova", sans-serif;
//Colour Variables
$bright-pink: #fb447c;
$medium-pink: #ff9fb3;
$light-pink:#ffdfe3;

.jt-event-banner {
  padding-top: 0px;
  word-wrap: break-word;
  position: relative;
  margin-bottom: 0;
  padding-bottom: 0;
  img {
    width: 100%;
    height: auto;
    max-height: 530px !important;
    border-radius: 2px 2px 0 0;
  }
  .jt-event-caption {
    position: absolute;
    bottom: 6px;
    h4 {
      color: $white;
      text-shadow: 1px 1px 1px $black;
      padding: 0 10px;
      font-size: 18px;
      z-index: 1;
      position: fixed;
      bottom: 0;
      position: relative;
      word-break: break-word;
    }
  }

}

.jt-event-clear {
  clear: both;
  padding: 1% 0;
}

.jt-event-attend-selector {
  margin-top: 0;
  padding-top: 0;
  .jt-event-attend-button {
    button {
      width: 50%;
      color: $white;
      float: left;
      background-color: $blue;
      //border-radius: 0;
      border-radius: 0 0 2px 2px;
    }
    .btn-text {
      color: $white;
      text-align: center;
      font-size: 110%;
      font-weight: 200;
    }
    .inactive {
      background-color: $blue-darker;
    }
  }
}

.jt-event-datetime {
  width: 100%;
  height: 40px;
  clear: both;
  a {
    text-decoration: none;
  }
  hr {
    border: 0;
    clear: both;
    display: block;
    width: 96%;
    background-color: $light-gray;
    height: 1px;
  }
  .jt-event-datetime-icon {
    width: 30px;
    float: left
  }
  .data {
    width: auto;
    float: left;
    line-height: 1.2;
    padding-left: 16px;
    background-color: transparent;
    h4 {
      font-size: 120%;
      text-align: left;
      padding-top: 3px;
      margin-bottom: 0;
      padding-bottom: 2px;
      font-weight: 600;
      color: $dark-gray;
      word-break: break-all;
    }
    p {
      margin-top: 0;
      padding-top: 0;
      color: $gray;
    }
  }
  .time {
    background: url(#{$gt-images}time-icon.png) no-repeat center;
    width: 20px;
    height: 20px;
    margin-top: 8px;
    margin-left: 12px;
  }
  .venue {
    background: url(#{$gt-images}venue-icon.png) no-repeat center;
    width: 20px;
    height: 20px;
    margin-top: 8px;
    margin-left: 12px;
  }
}

.jt-event-going {
  padding-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 18px;
  margin-bottom: -7px;
  h6 {
    padding-left: 10%;
    font-size: 100%;
    padding-bottom: 2%;
  }
  ul {
    width: 100%;
    padding: 0 5px;
    float: left;
    .more-button-10 {
      display: none;
    }
    .more-button-5 {
      display: inline;
    }
  }
  ul li {
    display: inline;
    width: 20%;
    padding: 0 6px;
    float: left;
  }
  ul li img {
    width: 100%;
    border: solid 2px $blue-light;
    background-color: $white;
    border-radius: 100px;
  }
}

.jt-event-description {
  margin-top: -9px;
  word-break: break-word;
  p {
    line-height: 1.3;
    font-size: 100%;
    color: $gray;
    padding: 0px 19px;
    padding-bottom: 16px;
  }
}

.gray {
  background-color: $light-gray;
}

.second {
  height: 48px;
  -webkit-box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.19);
  box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.19);
}

.hidden {
  display: none;
}

.jt-friends-modal {
  a {
    color: $dark-gray;
  }
  .scrollable-height {
    height: 75vh;
    min-height: 300px;
  }
  ul {
    list-style: none;
  }

}

.scrollable-view-height {
  height: auto;
}


.jt-event-datetime-section {
  width: 100%;
  padding: 10px 10px;
  line-height: 4px;
  margin-top: 45px;
  a {
    text-decoration: none;
  }
  .time-show {
    width: 100%;
    clear: both;
    height: 50px;
    .icon {
      float: left;
      font-size: 1.9rem;
      padding-top: 14px;
      color: $gray;
      width: 30px;
      margin-left: 4px;
    }
    .icon-secondary {
      float: left;
      font-size: 1.9rem;
      padding-top: 7px;
      color: $gray;
      width: 30px;
      margin-left: 4px;
    }
    .heading {
      float: left;
      clear: right;
      margin-left: 10px;
      width: 80%;
      padding-top: 6px;
      color: $black;
      word-break: break-word;
    }
    .heading-secondary {
      float: left;
      clear: right;
      margin-left: 10px;
      width: 90%;
      padding-top: 6px;
      color: $black;
    }
    .description {
      float: left;
      margin-left: 43px;
      clear: left;
      color: $gray;

    }
  }
}

.profiles-loader {
  width: 100%;
  clear: both;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  img {
    width: 100px;
    opacity: 0.3;
    height: 10px;
  }
}

hr.thin-line {
  //margin-top: 5px;
}

.jt-event-extended-menu {
  position: absolute;
  top: 8px;
  right: 6px;
  border-radius: 3px;

  ul {
    position: absolute;
    right: -1px;
    top: 39px;
    left: -139px;
  }

  .btn {
    background-color: $white;
    opacity: 0.6;
  }
}

.jt-clear {
  clear: both;
}

.jt-coupon-code-modal {
  background: #ccc;

  .modal-dialog {
    max-width: 380px !important;

    .jt-error-show {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .validation-coupon {
      color: #f50013;
      p {
        color: #f50013;
      }
    }
  }

  .jt-buttons-row {
    padding: 10px 0 0 0;
  }
}

.jt-table-brief {
  text-align: center;
}

.jt-table-brief-agenda {
  padding-bottom: 10px;
  text-align: center;
  font-weight: bold;
}

.jt-invite-table-attendees-section {
  font-size: 14px;
  font-weight: 400;
}

.jt-table-brief a {
  color: $bright-pink !important;
}

.jt-table-attendee-box {
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid;
  border-color: rgba(0,0,0,.07) rgba(0,0,0,.11) rgba(0,0,0,.18);

  h3 {
    color: $bright-pink !important;
  }

  .btn {
    min-height: 20px;
    padding: 5px 15px;
    white-space: normal;
  }

  .jt-table-attendee-edit-row {
    margin-bottom: 10px;

    textarea {
      border: 1px solid #ccc;
    }
  }

  .jt-attendee-action-btn-container {
    padding-left: 0;
    padding-right: 0;
  }

  .attendee-error-message {
    width: 100%;
    color: $red;
    padding-top: 5px;
    float: left;
  }
}

.jt-table-details-box {
  border: none !important;
}

.jt-table-ticket-input {
  padding: 5px 8px !important;
}

.jt-table-ticket-btn {

}

.jt-session-selection-table-big {
  display: block;
}

.jt-session-selection-table-small {
  display: none;
}

.jt-session-detail {
  margin-top: 10px;
}

.jt-description-background {
  padding: 15px;
  background-color: #e5e5e5;
  text-align: left;
}

.jt-event-title {
  font-size: 19px;
}

@media (max-width: 997px) {
  .jt-description-background{
    margin-bottom: 15px;
  }

  .jt-coupon-code-modal{
    margin-top: 195px;
  }
}

.jt-image-parent{
  width: 420px;
  height: 420px;
}

.jt-image-parent img {
  height: 100%;
  width: 100%;
}
