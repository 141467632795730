.jt-login-box {
  @include bg-rgba(0, 134, 237, 0.7, 0086 ED);
  @include abs-pos(43%, 0, auto, 0);

  .jt-login-box-inner {
    padding: 32px 20px;
    float: none;
    margin: 0 auto;
    max-width: 600px;
    display: table;
    position: relative;

    .jt-issue-box {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 100%;
      height: auto;
      display: inline-block;

      .jt-issue-box-inner {
        background: orange;
        float: left;
        width: 100%;

        div {
          width: 100%;
          float: left;
        }
      }

      ul {
        width: 100%;
        height: auto;
        float: left;
        display: block;

        li {
          width: 100%;
          float: left;
        }
      }
    }

    .login-form-label {
      width: 35%;
      float: right;
      padding-left: 5px;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      height: 100%;

      p {
        margin: 0;
        padding: 0 0 3px 5px;
        color: $white;
        @include font-size(32);
        font-weight: $gt-font-weight-bold;

      }

      .jt-profile-photo {

        padding-left: 10px;
        height: 96px;
        overflow: hidden;
        text-align: center;

        img {
          display: block;
          margin: 0 auto;
          max-height: 90px;
          max-width: 90px;
        }
      }

    }

    .login-form-content {
      float: left;
      width: 65%;

      .jt-login-user-row {
        float: inherit;
        margin: 0;
        width: 100%;
      }

      .login-form-field-row {
        float: left;
        margin: 0 0 8px 0;
        width: 100%;

        .jt-login-switch {

          h6 {
            float: right;
            color: $white;
            padding-top: 4px;
          }
          ui-switch {
            float: right;
            height: 25px;
            width: 55px;
            margin: 0 6px;

            .switch-handle {
              height: 25px;
              width: 25px;
            }
          }
        }

        &:last-child {
          margin: 0;
        }

        input[type="email"], input[type="password"] {
        }

        input {
          height: 37px;
          color: $black;
        }

        input[type="submit"], input[type="button"] {
          float: right;
          max-width: 125px;
          vertical-align: middle;
          background: $white;
          font-weight: $gt-font-weight-bold;
          padding-bottom: 29px;
          @include font-size(15);
          margin: 0;
          border: 1px solid $blue-light;
          color: $black;
          background-color: #f4f5f5;
          background-image: -webkit-gradient(linear, left top, left bottom, from(#f4f5f5), to(#dfdddd));
          background-image: -webkit-linear-gradient(top, #f4f5f5, #dfdddd);
          background-image: -moz-linear-gradient(top, #f4f5f5, #dfdddd);
          background-image: -ms-linear-gradient(top, #f4f5f5, #dfdddd);
          background-image: -o-linear-gradient(top, #f4f5f5, #dfdddd);
          background-image: linear-gradient(to bottom, #f4f5f5, #dfdddd);
          filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr='#f4f5f5', endColorstr='#dfdddd');
        }

        span {
          float: right;
          padding: 14px 0 0 0;
          margin-right: 10px;

          a {
            color: $white;
            padding: 6px 8px;
          }
        }
      }
    }
  }

  .jt-login-box-format {
    padding: 10px 15px;
  }
}

.jt-forgot-pass-model-container, .jt-login-model-container {
  .jt-web-form-btn {
    margin-top: 15px !important;
  }
}


