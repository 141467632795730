.jt-notification-enabled {
  position: relative;
  .jt-notification {
    position: fixed;
    width: 157px;
    height: 58px;
    right: 10px;
    bottom: 90px;
    z-index: 200;
    .jt-notify-popup {
      background-color: transparent;
      width: 156px;
      height: 57px;
      position: relative;
      img {
        width: 100%;
        height: auto;
      }
      color: $white;
      .jt-popup-count-1, .jt-popup-count-2, .jt-popup-count-3 {
        position: absolute;
        top: 20px;
      }
      .jt-popup-count-1 {
        right: 106px;
      }
      .jt-popup-count-2 {
        right: 54px;
      }
      .jt-popup-count-3 {
        right: 4px;
      }
    }
  }

}

.jt-notification-list {
  .jt-messenger-list-element {
    .jt-messenger-list-name {
      width: 47%;
      padding-top: 2px;
      p {
        white-space: initial;
      }
    }
  }
  ul {
    li:first-child {
      border-top: 1px solid $gray-lighter;
    }
  }
  .jt-notification-more-content {
    width: 100%;
    float: left;

    a {
      width: 100%;
      display: block;
    }

    .jt-messenger-list-element {
      padding: 9px;
      width: 100%;
      height: auto;
      float: left;

      .jt-messenger-list-image {
        float: left;
        width: 65px;
        display: inline;
        width: 30%;
        max-width: 65px;

        img {
          width: 100%;
          height: auto;
        }
      }

      .jt-notification-list-desc {
        width: calc(100% - 70px);
        float: left;
        display: inline;
        padding-left: 10px;
      }

      .jt-messenger-list-name {
        float: left;
        width: 100%;
        margin-top: 12px;
        text-align: left;
        padding-left: 0;
        clear: both;

        p {
          white-space: initial;
          margin-bottom: 2px;
        }
      }
      .jt-messenger-list-time {
        margin-top: 0;
        width: 100%;
        text-align: left;
        padding-left: 0;
        clear: both;
      }

    }
  }
}

.jt-notify-scrollable-content {
  padding: 0;

  .jt-notification-list {
    ul {
      li {
        &:first-child {
          border-top: none;
        }
      }
    }
  }
}

.jt-notification-list-element {
  width: 100%;
  height: auto;
  min-height: 90px;
  position: relative;
  display: table;
  border-bottom: 1px solid $gray-light;
  .jt-notification-list-image {
    position: absolute;
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    //top: 50%;
    //transform: translate(0%, -50%);
    //-ms-transform: translate(0%, -50%);
    float: left;
    margin-left: 12px;
    top: 12px;
    img {
      width: 65px;
      height: 65px;
      border-radius: 100%;
      border: 3px solid $blue-light;
    }
  }
  .jt-notification-list-element:last-child {
    border-bottom: none;
  }
  .jt-notification-list-desc {
    height: auto;
    //padding-top: 15px;
    //padding-bottom: 15px;
    width: calc(100% - 80px);
    //position: absolute;
    //top: 50%;
    //transform: translate(0%, -50%);
    //-ms-transform: translate(0%, -50%);
    display: table-cell;
    vertical-align: middle;
    margin-left: 80px;
    float: left;
    text-align: left;
    padding-right: 12px;
    .jt-notification-list-name {
      float: left;
      width: calc(100% - 90px);
      padding-left: 10px;
      word-break: break-word;
    }
    .jt-notification-list-time {
      p {
        float: right;
        font-weight: bold;
        width: 90px;
        text-align: right;
      }
    }
  }
}

.jt-notification-clear {
  display: table-cell;
  //height: 100%;
  height: 90px;
  vertical-align: middle;
}