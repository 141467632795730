.white {
  color: $white;
}

.forgot-password-field-row {
  padding-top: 10px;
}

.jt-issue-text {
  color: $white;
}

.thin-field-row {
  input {
    height: 35px;
  }
}

.jt-forgot-inner {
  padding: 11px 15px !important;
}

.jt-forgot {
  top: 32% !important;
}

.box-bottom-text {
  padding-top: 5px;
  clear: both;

}

.forgot-form-content {
  width: 90%;
  padding-bottom: 6px;
}

.reset-form-label {
  text-align: start !important;
  padding-top: 0;
  p {
    font-size: 2.2rem !important;
  }

}

.change-pwd-small-p {
  font-size: 2.2rem;
}

a.resend-code-format {
  font-weight: bold;
  color: white;
  text-decoration: none;
}

.extended-form-width {
  width: 90% !important;
}

.center-content {
  display: flex !important;
  justify-content: center !important;
}
