//Fonts
@import url("https://use.typekit.net/jml4rgr.css");

// Font Variables
$h1-large: "anton",sans-serif;
$regular: "proxima-nova", sans-serif;
//Colour Variables
$bright-pink: #fb447c;
$medium-pink: #ff9fb3;
$light-pink:#ffdfe3;

.jt-web-single-event-container-section {
  .jt-scrollable-none-more-pad {
    width: 100%;
    padding: 0 0 !important;
    margin: 0 0 !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }

  .jt-not-logged-footer {
    position: relative;
    bottom: auto;
    float: left;
  }
}

.jt-speaker-profiles {
  max-width: 1120px;
  margin: 0 auto;
  padding: 40px 25px;
  .jt-speaker-profile {
    padding: 20px 5px;
    text-align: center;    
    .jt-speaker-profile-wrapper {
      text-align: center;
      padding: 10px 10px;

      img {
        padding: 20px;
        background: -moz-linear-gradient(right, #ff9e51, #ff8cc5);
        background: -webkit-linear-gradient(right, #ff9e51, #ff8cc5);
        background: -ms-linear-gradient(right, #ff9e51, #ff8cc5);
        background: -o-linear-gradient(right, #ff9e51, #ff8cc5);
        background: linear-gradient(right, #ff9e51, #ff8cc5);
        width: 100%;
        //height: 325px;
      }

      p {
        background: $light-pink;
        margin:0px;
        padding: 0px 10px 10px;
        font-family: $regular;
        font-size: 16px;
      }

      .small-p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .large-p {
        padding: 0px 20px 20px;
        font-family: $regular;
        max-height: 350px;
        min-height: 350px;
        overflow: hidden;

      }
      h4 {
        background: $light-pink;
        margin:0px;
        padding-bottom: 10px;
        padding-top: 30px;
        font-family: $regular;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 30px 10px 10px 10px;
      }
    }
  }

}

.jt-webapp-primary-content {
  padding-top: 146px;
}

.jt-addon-stripe {

  h1 {
    margin-bottom: 0px;
    font-size: 44pt;
    font-family: $h1-large;
    letter-spacing: 5.575px;
    font-weight: normal;
    line-height: 1.3;
  }
}

.jt-pink-bg {
  background: $light-pink;
}

.jt-web-welcome-container .jt-featured-event {
  max-width: 800px;
  margin: 0px auto;
  text-align: left;
}
.jt-event-page-description {
  max-width: 1200px;
  margin: 0 auto;
  //position: relative;

  .jt-event-table-wrapper {
    padding: 50px 20px 20px;
    display: inline-block;
    width: 100%;
  }

  table {
    font-size: 12pt;
    font-family: $regular;
    tr {
      border-bottom: solid 1px;
      td {
        padding: 10px;
        //text-align: center;
        font-size: 16pt;
        margin-bottom: 30px;
        font-weight: normal;
        font-family: "proxima-nova", sans-serif;
        line-height: 19pt;
      }
    }
  }

  p {
    //font-size: 12pt;
    padding: 20px 20px 50px;
    //font-family: $regular;
    display: inline-block;
    text-align: center;
    font-size: 16pt;
    margin-bottom: 30px;
    font-weight: normal;
    font-family: "proxima-nova", sans-serif;
    line-height: 19pt;

  }
}

.jt-agenda-section {
  .jt-agenda-wrapper {
    background: $light-pink;
    display:inline-block;
    width: 100%;
    padding-bottom: 50px;
    h1{
      text-align: center;
      font-size: 44pt;
      background: -moz-linear-gradient(right, #ff9e51, #e84385);
      background: -webkit-linear-gradient(right, #ff9e51, #e84385);
      background: -ms-linear-gradient(right, #ff9e51, #e84385);
      background: -o-linear-gradient(right, #ff9e51, #e84385);
      background: linear-gradient(right, #ff9e51, #e84385);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: $h1-large;
      letter-spacing: 5.575px;
      padding: 40px 0 20px 0px;
      line-height: 1.3;
    }

    .jt-agenda-table-wrapper {

      table, th, td {
        border: 30px solid $light-pink;
      }

      table {
        max-width: 1250px;
        margin: 0 auto;
        color: white;
        font-weight: bold;
        font-size: 16pt;
        font-family: $regular;

        .agenda-col-1 {
          width: 20%;
          vertical-align: top;

          span {
            background: -moz-linear-gradient(right, #ff9e51, #ff8cc5);
            background: -webkit-linear-gradient(right, #ff9e51, #ff8cc5);
            background: -ms-linear-gradient(right, #ff9e51, #ff8cc5);
            background: -o-linear-gradient(right, #ff9e51, #ff8cc5);
            background: linear-gradient(right, #ff9e51, #ff8cc5);
            display: inline-block;
            width: 100%;
            //height: 100%;
            overflow: hidden;
            padding: 20px 5px;
            text-align: center;
          }
        }

        .agenda-col-2 {
          width: 80%;
          vertical-align: top;
          background: $bright-pink;
          padding: 3.5px;
          span {
            display:inline-block;
            padding:17px;
            width:100%;
            background: $bright-pink;

            p {
              font-size: inherit;
              //font-weight: inherit;
              font-weight: normal;

              a {
                color: $white;
                text-decoration: underline;
              }
            }
          }
        }


      }
    }

  }


}

.slider-section {

  .jt-web-welcome-container {
    max-width: 1000px;
    margin: 0 auto;
  }

     
  h1 {
    text-align: center;
    font-size: 44pt !important;
    margin-bottom: 40px;
    background: -moz-linear-gradient(right, #ff9e51, #e84385);
    background: -webkit-linear-gradient(right, #ff9e51, #e84385);
    background: -ms-linear-gradient(right, #ff9e51, #e84385);
    background: -o-linear-gradient(right, #ff9e51, #e84385);
    background: linear-gradient(right, #ff9e51, #e84385);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 5.575px;
    font-family: $h1-large !important;
    margin-top: 40px;
    padding: 0 5%;
    line-height: 1.3;
  }

  h4 {
    font-size: 16pt !important;
    font-family: $regular;
    text-align: center;
    margin-bottom:60px;
    font-weight: normal;
    padding: 0px 5%;
    line-height: 19pt;
  }
  .sponsor-slider {
    
    .jt-slide {
      padding: 5px;


      .jt-slide-img-wrapper {
        padding: 11px;
        //height:200px;

        img {
          //height: 100%;
          width: 100%;
        }
      }

      .pink-bg {
        background: $bright-pink;
      }

      .gradient-bg {
        background: -moz-linear-gradient(right, #ff9e51, #ff8cc5);
        background: -webkit-linear-gradient(right, #ff9e51, #ff8cc5);
        background: -ms-linear-gradient(right, #ff9e51, #ff8cc5);
        background: -o-linear-gradient(right, #ff9e51, #ff8cc5);
        background: linear-gradient(right, #ff9e51, #ff8cc5);
      }
    }
  
    .slick-prev:before, .slick-next:before {
      color: $light-pink;
      font-weight: 900;
      font-size: 44pt;
      font-family: $regular;

    }

    .slick-next:before {
      content: '>'
    }

    .slick-prev:before {
      content: '<'
    }

    .slick-prev {
      left: -40px;
    }

    .slick-prev, .slick-next {
      top: 45% !important ;
    }
  }
}

.jt-newsletter-form {
  max-width: 1000px;
  margin: 0 auto;

  .form-wrapper {
    max-width: 800px;
    margin: 0 4%;
    button {
      position: absolute;
      top: 275px;
      margin-left: 10px;
      background: #fb447c;
      border: none;
      border-radius: 0px;
      color: white;
      font-size: 14pt;
      letter-spacing: 2px;
      font-family: $regular;
      padding: 2px 5px;
    }

    button:disabled {
      cursor: not-allowed;
      opacity: 0.8;
    }
  }

  .form-small, .form-large {
    input {
      border: none;
      border-radius: 0;
      color: black;
      font-family: $regular;
    }
  }

  .form-small {
    //display: inline-block;
    display: inline-table;
    width: 24.25%;
    margin: 2.5px 0px;
    
  }

  .form-large {
    //display: inline-block;
    display: inline-table;
    width:49%;
    margin: 2.5px 0px;
  }
}

.jt-single-event-page-header {
  width: 100%;
  float: left;
  position: relative;

  .jt-forgotten-stripe {
    background: white !important;
  }

  .jt-speaker-stripe {
    width: 100%;
    position: relative;
    left: 0;
    bottom: 0;
    background: $light-pink;
    color: #FFFFFF;
    padding: 50px 50px;
    text-align: center;

    h1 {
      text-align: center;
      font-size: 44pt;
      background: -moz-linear-gradient(right, #ff9e51, #e84385);
      background: -webkit-linear-gradient(right, #ff9e51, #e84385);
      background: -ms-linear-gradient(right, #ff9e51, #e84385);
      background: -o-linear-gradient(right, #ff9e51, #e84385);
      background: linear-gradient(right, #ff9e51, #e84385);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: $h1-large;
      letter-spacing: 5.575px;
      padding: 20px 0px;
      line-height: 1.3;
    }

    h4 {
      font-size: 16pt;
      margin-top: 20px;
      padding: 20px 10%;
      font-family: $regular;
      font-weight: normal;
      line-height: 19pt;
    }
  }
  .jt-single-event-banner {
    width: 100%;
    height: 620px;
    max-height: 65vh;
  }

  .jt-single-event-banner-general-info {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 30px 50px;

    .jt-single-event-banner-general-info-inner {
      width: 100%;
      height: 100%;
      display: table;
      max-width: 1200px;
      margin: 0 auto;

      .jt-single-event-banner-general-info-inner-wrapper {
        width: 100%;
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        color: white;
        text-shadow: 2px 2px 5px #000;

        h3 {
          font-family: $h1-large;
          font-weight: bold;
          text-transform: uppercase;
          @include font-size(40);
          margin: 0 0 0 0;
          padding: 0 0;
          //max-height: 60px;
        }

        span {
          @include font-size(22);
          font-weight: 400;
          font-family: $regular;

        }
      }
    }
  }

  .jt-selected-event-wrapper {
    padding: 0px 0px 50px 0px;

    .jt-featured-event {

      h1 {
        margin-bottom: 20px;
        text-transform: uppercase;
        line-height: 1.3;
      }
      h3 {
        text-align: center;
        padding: 10px;
        margin-bottom: 20px;
        font-size: 19pt;
        font-family: $regular;
        font-weight: normal;
        line-height: 1.3;
      }
      h4 {
        text-align: center;
        font-size: 16pt;
        margin-bottom: 30px;
        font-weight: normal;
        font-family: $regular;
        line-height: 19pt;
      }
    }
    .jt-newsletter-form {

      h1 {
        text-align: center;
        font-size: 44pt;
        background: -moz-linear-gradient(right, #ff9e51, #e84385);
        background: -webkit-linear-gradient(right, #ff9e51, #e84385);
        background: -ms-linear-gradient(right, #ff9e51, #e84385);
        background: -o-linear-gradient(right, #ff9e51, #e84385);
        background: linear-gradient(right, #ff9e51, #e84385);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: $h1-large;
        letter-spacing: 5.575px;
        padding: 20px 0px;
        padding-top: 40px;
        line-height: 1.3;
      }
  
      h4{
        //color: black;
        font-size: 16pt;
        margin-top: 20px;
        padding: 20px 10%;
        text-align: center;
        font-family: $regular;
        font-weight: normal;
        line-height: 19pt;
      }
    }
  }

  

  .jt-single-event-banner-time-stripe {
    width: 100%;
    position: relative;
    left: 0;
    bottom: 0;
    background: -moz-linear-gradient(right, #ff9e51, #ff8cc5);
    background: -webkit-linear-gradient(right, #ff9e51, #ff8cc5);
    background: -ms-linear-gradient(right, #ff9e51, #ff8cc5);
    background: -o-linear-gradient(right, #ff9e51, #ff8cc5);
    background: linear-gradient(right, #ff9e51, #ff8cc5);
    color: #FFFFFF;
    padding: 40px 50px;
    text-align: center;
 
    .jt-single-event-banner-time-slots {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      position: relative;
      min-height: 78px;

      .jt-single-event-banner-time-slots-inner {
        width: auto;
        overflow: hidden;
        color: white;


        h3 {
          font-weight: 400;
          margin: 0 0;
          @include font-size(32);
          line-height: 32px;
          padding: 20px 0;
          font-family: $regular;
          font-weight: bold;
        }

        .jt-single-event-banner-time-slot {
          width: 100px;
          height: 78px;
          background: none;
          display: inline-block;
          border: 2px solid $white;
          border-radius: 2px;
          text-align: center;
          padding: 5px 5px;
          vertical-align: middle;
          color: white;
          margin: 0 8px;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }

          h2 {
            @include font-size(42);
            text-transform: uppercase;
            font-weight: 400;
            margin: 0 0 0 0;
            padding: 0 0 0 0;
            float: left;
            width: 100%;
            text-align: center;
            font-family: $regular;
            font-weight: bold;
          }

          p {
            @include font-size(18);
            text-transform: uppercase;
            font-weight: 400;
            margin: 0 0 0 0;
            padding: 0 0 0 0;
            float: left;
            width: 100%;
            text-align: center;
            font-family: $regular;
            font-weight: bold;
          }
        }
      }
    }

    .jt-single-event-banner-time-button-bg {
      position: absolute;
      width: 158px;
      height: 100%;
      top: 0;
      right: 0;

      .jt-single-event-banner-time-button {
        width: 100%;
        height: 100%;
        display: table;
      }

      .jt-single-event-banner-time-button-inner {
        width: 100%;
        display: table-cell;
        vertical-align: middle;
        text-align: center;

        button {
          margin: 0 auto;
          float: none;
        }
      }
    }
  }
}

.jt-button-see-who-attending {
  text-align: center;
}

.jt-single-event-about-desc {
  p {
    @include font-size(20);
    color: $black;
    margin: 0 0;
    padding: 0 0;
    line-height: 1.2;
    letter-spacing: -1px;
    font-family: $regular;

  }
}

.jt-single-welcome-sevent-agenda-container {
  width: 100%;
  float: left;

  .jt-single-welcome-sevent-agenda-row {
    width: 100%;
    float: left;
    margin: 0 0 10px 0;

    .jt-agenda-time {
      width: 262px;
      float: left;
      background: $dark-blue;
      border-radius: 4px;
      margin: 0 10px;
      min-height: 62px;
      

      span {
        float: left;
        width: 100%;
        display: block;
        margin: 0 0;
        padding: 17px 20px;
        @include text-truncate;
        @include font-size(20);
        display: table-cell;
        vertical-align: middle;
        letter-spacing: -1px;
      }
    }
    .jt-agenda-desc {
      width: auto;
      overflow: hidden;
      background: $white;
      border-radius: 4px;
      padding: 20px 20px;
      vertical-align: middle;
      @include font-size(20);
      color: $black;
      line-height: 1.1;
      letter-spacing: -1px;
      min-height: 62px;

      p {
        float: left;
        width: 100%;
        display: block;
        margin: 0 0 !important;
        padding: 0 0;
        vertical-align: middle;
        @include font-size(20);
        color: $black;
        line-height: 1.1;
        letter-spacing: -1px;
      }
    }
  }
}

.jt-single-event-speakers-content {
  width: 100%;
  float: left;
  padding: 0 0px;

  .jt-single-event-speakers {
    width: auto;
    float: none;
    margin: -15px -15px 0 -15px;
    padding: 0 0;
    list-style-type: none;
    text-align: center;

    .jt-single-event-speaker {
      width: 25%;
      display: inline-block;
      list-style-type: none;
      margin: 0 0;
      padding: 15px 15px;

      .jt-single-event-speaker-inner {
        width: 100%;
        float: left;
        display: block;
        position: relative;
        box-shadow: 0px 0px 12px #666;

        .jt-single-event-speaker-img {
          width: 100%;
          height: 313px;
          float: left;
          position: relative;
        }

        .jt-single-event-speaker-desc {
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0;
          background: rgba(0,0,0, 0.77%);
          padding: 10px 10px;
          text-align: left;

          h4 {
            @include font-size(20);
            @include text-truncate;
            font-weight: bold;
            color: $white;
            margin: 0 0 0 0;
            padding: 0 0 5px 0;
            float: left;
            width: 100%;
            letter-spacing: -1px;
          }

          label {
            @include font-size(15);
            @include text-truncate;
            color: $white;
            font-weight: 400;
            margin: 0 0 0 0;
            padding: 0 0 0 0;
            line-height: 1;
            float: left;
            width: 100%;
            letter-spacing: -1px;
          }

          span {
            @include font-size(16);
            @include text-truncate;
            color: $white;
            font-weight: 400;
            margin: 0 0 0 0;
            padding: 0 0 0 0;
            line-height: 1;
            float: left;
            width: 100%;
            letter-spacing: -1px;
          }
        }
      }
    }
  }
}

.jt-center-aligned {
  text-align: center !important;  
}

.tickets-text {
  font-size: 16pt;
  margin-top: 20px;
  padding: 20px 10%;
  font-family: "proxima-nova", sans-serif;
  font-weight: normal;
  text-align: center;
  line-height: 19pt;
}

.jt-coloured-button {
  width: 40%;
  background: -moz-linear-gradient(right, #ff9e51, #ff8cc5);
  background: -webkit-linear-gradient(right, #ff9e51, #ff8cc5);
  background: -ms-linear-gradient(right, #ff9e51, #ff8cc5);
  background: -o-linear-gradient(right, #ff9e51, #ff8cc5);
  background: linear-gradient(right, #ff9e51, #ff8cc5);
  border: none;
  border-radius: 0px;
  color: white;
  font-size: 20pt;
  letter-spacing: 5.575px;
  font-family: $regular;
  padding: 15px 10px;
  margin-top: 40px;
}

.jt-single-event-ticket-options-content {
  width: 100%;
  float: left;
  padding: 0 0px;



  .jt-single-event-ticket-options {
    width: auto;
    float: none;
    margin: -15px -15px 0 -15px;
    padding: 0 0;
    list-style-type: none;
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
    .jt-single-event-ticket-option {
      width: 33.3333%;
      display: inline-block;
      //float: left;
      list-style-type: none;
      background: $light-pink;
      margin: 10px 30px;
      //-webkit-box-shadow: -1px 5px 5px 2px rgba(251,68,123,0.8);
      //-moz-box-shadow: -1px 5px 5px 2px rgba(251,68,123,0.8);
      //box-shadow: -1px 5px 5px 2px rgba(251,68,123,0.8);
      .jt-single-event-ticket-option-inner {
        width: 100%;
        float: left;
        display: block;
        position: relative;
        height: inherit;

        .jt-ticket-option-header {
          width: 100%;
          float: left;

          span {
            @include font-size(30);
            //@include text-truncate;
            font-weight: 400;
            color: #464747;
            margin: 0 0 0 0;
            padding: 30px 20px 0px 20px;
            float: left;
            width: 100%;
            text-align: center;
            font-size: 36pt !important;
            font-family: $h1-large;
            color: $bright-pink;
            letter-spacing: 3px !important;
            word-break: break-word;
          }
        }

        .jt-ticket-option-price {
          width: 100%;
          float: left;

          span {
            @include font-size(42);
            @include text-truncate;
            font-weight: 400;
            color: $white;
            margin: 0 0 0 0;
            padding: 0px 20px 15px 20px;
            float: left;
            width: 100%;
            letter-spacing: -1px;
            text-align: center;
            font-size: 51pt !important;
            font-family: $h1-large;
            color: $bright-pink;

            &:after {
              content: '+ GST';
              @include font-size(10);
              position: relative;
              top: -8px;
              padding: 0 0 0 5px;
              font-size: 20pt;
              font-family: $regular;

            }
          }
        }

        .jt-ticket-option-desc {
          width: 100%;
          float: left;
          padding: 10px 15px 92px 15px;
          position: absolute;
          bottom: 0;

          ul {
            width: 100%;
            float: left;
            display: block;
            clear: both;
            margin: 0 0;
            padding: 0 0;
            list-style-type: none;

            li {
              width: 100%;
              float: left;
              padding: 10px 15px 10px 15px;
              border-bottom: 1px dashed $blue;
              @include font-size(18);
              letter-spacing: -1px;
              color: $black;
              font-weight: 400;
              position: relative;
              line-height: 1.2;
              text-align: center;
              height: 96px;
              overflow-y: hidden;

              &:last-child {
                border-bottom: none;
              }

              &:before {
                content: '';
                width: 12px;
                height: 16px;
                background: url('../../img/list-type-arrow-icon.png');
                background-size: 12px 16px;
                background-repeat: no-repeat;
                position: absolute;
                top: 12px;
                left: 0;
                display: none;
              }
            }
          }


          .jt-ticket-option-button-wrapper {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            text-align: center;
            background: $bright-pink;
            border: none;
            color: white;
            .jt-btn {
              width: 100%;
              border-radius: 0;
              box-shadow: none;
              letter-spacing: -1px;
              @include text-truncate;
              background: $bright-pink !important;
              border: none !important;
              font-size: 16pt;
              font-family: $regular;
              letter-spacing: 5.575px;
              font-weight: normal;
              padding: 36px 10px;
            }

            .jt-btn:hover {
              background: $medium-pink !important;
            }
          }
        }
      }
    }
  }
}

.newsletter-form-errors {
  color: red;
  font-size: 1.5rem;
  padding: 10px 0;
  text-align: left;

  li {
    float: left;
    padding-right: 30px;
  }
}

@media all and (max-width: 850px) {
  .jt-web-welcome-container .jt-featured-event {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    padding: 0px 20px;
}

.jt-slide-img-wrapper {
  padding: 5px !important;
  //height:150px !important;
}

.jt-coloured-button {
  width: 75%;
}

}


@media all and (max-width: 991px) {
  .jt-speaker-profile-wrapper {
    //width: 70%;
    margin: 0 auto;
  }

  .jt-newsletter-form .form-wrapper button {
    position: unset;
    margin-top: 20px;

  }

  .jt-newsletter-form .form-wrapper {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    width: auto;
    padding: 0px 20px;
    }

    .jt-newsletter-form .form-small, .jt-newsletter-form .form-large {
      width: 100%;
    }
}

@media all and (max-width: 450px) {
  .jt-speaker-profile-wrapper {
    width: 90%;

  }
  .jt-slide-img-wrapper {
    padding: 11px;
    //height:100px !important;
  }

  .jt-speaker-profiles .jt-speaker-profile {
    padding: 25px 0px;
  }

  .jt-newsletter-form .form-small, .jt-newsletter-form .form-large {
    width: 100%;
  }

  .jt-newsletter-form .form-wrapper {
    padding: 5px 5%;
    }

    .jt-web-welcome-container .jt-featured-event h1 {
      font-size: 32pt !important;
    }

    .jt-featured-event h1, .jt-speaker-stripe h1, .jt-agenda-wrapper h1, .slider-section h1, .jt-newsletter-form h1, .jt-addon-stripe h1 {
      font-size: 26pt !important;
    }

    h3, .jt-web-welcome-container .jt-featured-event h3 {
      font-size: 14pt !important;
    }
}


.jt-single-event-sponsors-content {
  width: 100%;
  float: left;

  .jt-single-event-main-sponsor-row {
    width: 100%;
    float: left;
    text-align: center;
  }

  .jt-single-event-sub-sponsors-row {
    width: 100%;
    float: left;
    text-align: center;
  }

  .jt-single-event-sponsor {
    //width: 300px;
    position: relative;
    //margin: 0 10px;

    .jt-single-event-sponsor-img-bg {
      width: 100%;
      height: 100%;
      //position: absolute;
      top: 0;
      left: 0;

      .jt-single-event-sponsor-img-wrapper {
        width: 100%;
        height: 100%;
        display: table;
        //background: #f3f0f0;
        //border: 1px solid #333;

        .jt-single-event-sponsor-img {
          width: 100%;
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          padding: 0 12px;

          .jt-single-event-sponsor-img-inner {
            width: 100%;
            display: block;
            vertical-align: middle;
            text-align: center;

            img {
              width: auto;
              max-width: 100%;
              //max-height: 100%;
            }
          }
        }
      }
    }

    .jt-single-event-sponsor-label {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      padding: 0 10px;
      display: none;


      label {
        background: rgba(0,0,0,.77);
        width: 100%;
        display: block;
        margin: 0 0;
        padding: 3px 10px;
        text-align: center;
        color: $white;
        @include font-size(20);
        @include text-truncate;
        font-weight: 400;
        letter-spacing: -1px;
      }
    }
  }

  .jt-single-event-main-sponsor-row {
    //border-bottom: 1px dashed #ccc;
    //padding-bottom: 40px;
    //margin-bottom: 28px;

    .jt-single-event-sponsor {
      margin: 0 auto;
      //height: 258px;

      .jt-single-event-sponsor-img-bg {
        padding: 0 0 0px 0;

        .jt-single-event-sponsor-img-wrapper {

          .jt-single-event-sponsor-img {
            .jt-single-event-sponsor-img-inner {
              img {
                max-height: 200px;
                height: 200px;
                //max-width: 264px;
              }
            }
          }
        }
      }

      .jt-single-event-sponsor-label {
        padding: 0 0;
      }
    }
  }

  .jt-single-event-sub-sponsors-row {
    .jt-single-event-sponsor {
      //width: 19%;
      //height: 160px;
      //padding: 10px;
      margin: 0 0;
      display: inline-block;

      .jt-single-event-sponsor-img-bg {
        padding: 0px 0;

        .jt-single-event-sponsor-img-wrapper {

          .jt-single-event-sponsor-img {

            .jt-single-event-sponsor-img-inner {
              img {
                height: 200px;
                max-height: 200px;
                //height: 100%;
              }
            }
          }
        }
      }
      .jt-single-event-sponsor-label {
        label {
          @include font-size(18);
        }
      }
    }
  }
}

 .white-container {
   .jt-single-event-ticket-options-content {
     .jt-single-event-ticket-options {
       .jt-single-event-ticket-option {
         .jt-single-event-ticket-option-inner {
           .jt-ticket-option-desc {
             background: #f9f9f9;
             border-left: 1px solid #ccc;
             border-right: 1px solid #ccc;
             border-bottom: 1px solid #ccc;

             ul {
               li {
                 border-bottom: 1px dashed #ccc;

                 &:last-child {
                   border-bottom: none;
                 }
               }
             }
           }
         }
       }
     }
   }
 }

 .jt-buy-tickets-popup {
   .modal-dialog {
     max-width: 600px;

     .select-bg {
       position: relative;

       &:after {
         content: '\f107';
         width: 0;
         height: 0;
         position: absolute;
         top: 12px;
         right: 30px;
         font-family: FontAwesome;
         @include font-size(16);
         z-index: 5;
       }
     }

     select {
      padding-right: 20px;
       position: relative;
       background: none;
       z-index: 6;
     }

     select::-ms-expand {
       display: none;
     }
   }

   .modal-body {
     .for-large {
     }
     .for-small {
       display: none;
     }
   }

   .terms-text {
     width: 100%;
     padding: 0px 10px 10px 10px;
     float: left;
     @include font-size(12);
     color: #5F5F5F;
     text-align: center;
     font-family: $regular;
   }
 }

 .checkout-pop-tb {
   width: 100%;
   overflow-x: auto;

   table {
     width: 100%;
     //min-width: 400px;
     border: 1px solid #e8e8e8;
     margin-bottom: 15px;
     @include font-size(16);


     tr {
       border-bottom: 1px solid #f8f8f8;

       &:last-child {
         border-bottom: none;
       }
       th, td {
         padding: 4px 10px;
         font-family: $regular;

       }

       th {
         padding: 8px 10px;
         background: #e8e8e8;
         font-family: $regular;

       }

       td {
         @include font-size(15);
         font-family: $regular;
       }

       .jt-order-tb-type {
         max-width: 150px;
         @include text-truncate;
         font-family: $regular;
       }

       &:last-child {
         border-top: 1px solid #ccc;
       }
     }
   }
 }

.jt-order-tb-type-ticket-description{
  white-space: initial !important;
  max-width: 150px;
  @include text-truncate;
  font-family: $regular;
  text-align: left;
}

 .jt-pill-checkout{
   //background: rgb(235, 255, 215);
   border-radius: 3px;
   color: dimgrey;
   //padding: 2px 5px;
   @include font-size(10);
   font-family: $regular;
 }

.jt-pill-checkout-remaining{
  //background: rgb(235, 255, 215);
  border-radius: 3px;
  color: dimgrey;
  //padding: 2px 5px;
  @include font-size(10);
  font-family: $regular;
}

.jt-ticket-description{
  font-size: 1.3rem;
  font-family: $regular;
  word-wrap: break-word;
}

.jt-web-checkout-container-section .jt-not-logged-footer {
  position: relative;
  bottom: auto;
  float: left;
}

.about-page-section-container .jt-not-logged-footer {
  position: relative;
  bottom: auto;
  float: left;
}

.jt-sold-out{
  background: #f94543 !important;
  border: 1px solid;
  border-color: #f94543 !important;
}

.jt-single-event-viewport-container {
  width: 100%;
  float: left;
  min-height: calc(100vh - 609px);
  background-color: $pure-white;

  .white-bg-section {
    background-color: $pure-white !important;
  }
}

.jt-single-event-viewport-expired-container {
  width: 100%;
  float: left;
  min-height: calc(100vh - 129px);
  background-color: $pure-white;

  .white-bg-section {
    background-color: $pure-white !important;
  }
}

.jt-claim-ticket-events-table {
  border: 1px solid #000;
  margin: 10px auto;

  th, td {
    padding: 10px;
    border: 1px solid #000;
    text-align: left;
  }
}

.jt-featured-event-title {
  font-size: 14pt;
  font-weight: bold;
}

.jt-speaker-clear-lg {
  display: block;
}

.jt-speaker-clear-sm {
  display: none;
}

@media(min-width:1200px){
  .jt-web-welcome-container .jt-featured-event {
    max-width: 900px;
  }
}

@media screen and (max-width: 998px) {
  .jt-speaker-profiles {
    .jt-speaker-profile {
      .jt-speaker-profile-wrapper {
        img {
          height: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .jt-speaker-clear-lg {
    display: none;
  }

  .jt-speaker-clear-sm {
    display: block;
  }

  .jt-speaker-profiles {
    .jt-speaker-profile {
      .jt-speaker-profile-wrapper {
        padding: 10px 0;
      }
    }
  }

  .jt-speaker-profiles {
    padding: 40px 10px;
  }

  .jt-speaker-profiles .jt-speaker-profile .jt-speaker-profile-wrapper .large-p {
    min-height: 285px;
  }
}

@media screen and (max-width: 768px) {
  .jt-webapp-primary-content {
    padding-top: 100px;
  }

  .jt-speaker-profiles .jt-speaker-profile .jt-speaker-profile-wrapper .large-p {
    min-height: 235px;
  }

  .jt-speaker-profiles .jt-speaker-profile .jt-speaker-profile-wrapper p {
    font-size: unset !important;
  }

  .jt-event-page-description {
    table tr td, p {
      font-size: 16px !important;
      line-height: 18px !important;
    }
  }

  .jt-single-event-page-header {
    .jt-selected-event-wrapper {
      .jt-featured-event, .jt-newsletter-form {
        h3, h4 {
          font-size: 16px !important;
          line-height: 18px !important;
        }
      }
    }

    .jt-speaker-stripe h4 {
      font-size: 16px !important;
      line-height: 18px !important;
    }
  }

  .jt-agenda-section .jt-agenda-wrapper .jt-agenda-table-wrapper table {
    .agenda-col-1 span, .agenda-col-2 span p {
      font-size: 16px !important;
      line-height: 18px !important;
    }
  }

  .tickets-text {
    font-size: 16px !important;
    line-height: 18px !important;
  }

  .slider-section h4 {
    font-size: 16px !important;
    line-height: 18px !important;
  }

  .jt-single-event-ticket-options-content .jt-single-event-ticket-options .jt-single-event-ticket-option {
    .jt-single-event-ticket-option-inner .jt-ticket-option-desc .jt-ticket-option-button-wrapper .jt-btn {
      font-size: 16px !important;
      padding: 25px 10px;
      line-height: 18px !important;
    }
  }
}

@media screen and (max-width: 620px) {
  .jt-speaker-profiles .jt-speaker-profile .jt-speaker-profile-wrapper .large-p {
    padding: 0 5px;
    min-height: 275px;
  }
}

@media screen and (max-width: 480px) {
  .jt-web-welcome-container {
    .jt-featured-event {
      padding: 0;
      max-width: 100% !important;
    }
  }

  .jt-single-event-page-header {
    .jt-speaker-stripe {
      padding: 50px 0;

      h4 {
        padding: 20px 0;
      }
    }
  }

  .jt-tickets-section {
    padding: 50px 0 !important;
  }

  .jt-home-events-section {
    .jt-home-events {
      .jt-home-event {
        padding: 20px 5px !important;
      }
    }
  }

  .jt-single-event-ticket-options-content .jt-single-event-ticket-options .jt-single-event-ticket-option .jt-single-event-ticket-option-inner .jt-ticket-option-header span {
    font-size: 22px !important;
  }

  .jt-single-event-ticket-options-content .jt-single-event-ticket-options .jt-single-event-ticket-option .jt-single-event-ticket-option-inner .jt-ticket-option-price span {
    font-size: 30px !important;
  }

  .jt-coloured-button {
    font-size: 12pt;
  }

  .jt-single-event-page-header .jt-selected-event-wrapper .jt-newsletter-form h4 {
    padding: 0 !important;
  }

  .jt-speaker-profiles .jt-speaker-profile .jt-speaker-profile-wrapper .large-p {
    min-height: 325px;
  }
}

@media screen and (max-width: 450px) {
  .jt-speaker-profiles .jt-speaker-profile .jt-speaker-profile-wrapper .large-p {
    min-height: 375px;
  }
}

@media screen and (max-width: 400px) {
  .jt-speaker-profiles .jt-speaker-profile .jt-speaker-profile-wrapper .large-p {
    min-height: 425px;
  }
}

@media screen and (max-width: 350px) {
  .jt-speaker-profiles .jt-speaker-profile .jt-speaker-profile-wrapper .large-p {
    min-height: 520px;
  }
}